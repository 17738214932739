import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BlastMsg, BlastMsgStatus } from 'src/app/interfaces/blast';
import { BlastMsgService } from './blast-msg.service';
import { BlastDeliveredType } from 'src/app/types/blast';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BlastStatusService implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private blastMsgService: BlastMsgService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getBlastMsgStatusTextByUsageId(usageId: string): string {
    const deliveredStatus = this.getBlastMsgStatusByUsageId(usageId);

    let result = '';

    Object.keys(deliveredStatus)?.forEach((key: string) => {
      if (deliveredStatus?.[key]) {
        if (result) {
          result = result + ', ';
        }
        result = result + this.translate.instant('BLAST.status.' + key + '_count', { count: deliveredStatus[key] });
      }
    });
    // if (deliveredStatus?.delivered) {
    //   if (result) {
    //     result = result + ', ';
    //   }
    //   result = result + this.translate.instant('BLAST.status.delivered_count', { count: deliveredStatus.delivered });
    // }
    // if (deliveredStatus?.failed) {
    //   if (result) {
    //     result = result + ', ';
    //   }
    //   result = result + this.translate.instant('BLAST.status.failed_count', { count: deliveredStatus.failed });
    // }
    // if (deliveredStatus?.processing) {
    //   if (result) {
    //     result = result + ', ';
    //   }
    //   result = result + this.translate.instant('BLAST.status.processing_count', { count: deliveredStatus.processing });
    // }
    if (result) {
      result = result + '.'
    }
    return result;
  }

  getBlastMsgStatusByUsageId(usageId: string) {
    const result = {
      processing: 0,
      sent: 0,
      delivered: 0,
      read: 0,
      failed: 0,
    };
    const blastMsgList = this.blastMsgService.getBlastMsgListByUsageId(usageId);
    blastMsgList?.forEach((blastMsg: BlastMsg) => {
      const deliveredStatus = this.checkDeliveredStatus(blastMsg);
      result[deliveredStatus] += 1;
    });
    return result;
  }

  checkDeliveredStatus(blastMsg: BlastMsg): BlastDeliveredType {
    let result: BlastDeliveredType = 'processing';
    // const provider = blastMsg?.provider;
    const blastMsgStatus: BlastMsgStatus = blastMsg?.status;

    if (blastMsgStatus) {
      if (blastMsgStatus?.read?.status) {
        result = 'read';
      } else if (blastMsgStatus?.delivered?.status) {
        result = 'delivered';
      } else if (blastMsgStatus?.failed?.status) {
        result = 'failed';
      } else if (blastMsgStatus?.sent?.status) {
        result = 'sent';
      }
    }
    if (result === 'processing' && blastMsg?.sent?.status === 'sent') {
      result = 'sent';
    }
    // const deliveredStatus = blastMsg?.delivered?.status;
    // let result: BlastDeliveredType = 'processing';
    // if (deliveredStatus && provider) {
    //   // if (BlastDeliveredStatus?.[provider]?.length && BlastDeliveredStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   return 'delivered'
    //   // } else if (BlastFailedStatus?.[provider]?.length && BlastFailedStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   return 'failed'
    //   // }
    //   // if (BlastDeliveredStatus?.[provider]?.length && BlastDeliveredStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   result = 'delivered';
    //   // } else if (BlastReadStatus?.[provider]?.length && BlastReadStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   result = 'read';
    //   // } else if (BlastSentStatus?.[provider]?.length && BlastSentStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   result = 'sent';
    //   // } else if (BlastFailedStatus?.[provider]?.length && BlastFailedStatus[provider].indexOf(deliveredStatus) !== -1) {
    //   //   result = 'failed';
    //   // } else {
    //   //   // result = 'processing';
    //   // }
    // } 
    return result;
  }

  checkUsageIdSentStatus(usageId?: string, blastMsgList?: BlastMsg[]) {
    let sent = true;
    if (!blastMsgList?.length && usageId) {
      blastMsgList = [ ...this.blastMsgService.blastMsgList ];
    }

    blastMsgList = blastMsgList.filter((blastMsg: BlastMsg) => {
      if (blastMsg.usageId === usageId) {
        if (sent && blastMsg?.sent?.status !== 'sent') {
          sent = false;
        }
        return true;
      }
      return false;
    });
    if (blastMsgList?.length && sent) {
      return true;
    } else {
      return false;
    }
  }

  checkGuestDeliveredStatusByUsageId(usageId: string, guestId: string, groupId: string, blastDeliveredType: BlastDeliveredType) {
    if (usageId && (guestId || groupId)) {
      const blastMsgList: BlastMsg[] = [ ...this.blastMsgService.blastMsgList ].filter((blastMsg: BlastMsg) => {
        if (blastMsg.usageId === usageId && ((guestId && guestId === blastMsg?.guestId) || (groupId && groupId === blastMsg?.groupId))) {
          if (blastDeliveredType) {
            if (blastDeliveredType !== 'all') {
              if (blastDeliveredType === 'sent') {
                if (!blastMsg?.delivered?.status || blastMsg?.delivered?.status === 'sent') {
                  return true;
                }
              } else {
                const deliveredType = this.checkDeliveredStatus(blastMsg);
                if (deliveredType === blastDeliveredType) {
                  return true;
                }
              }
            } else {
              return true;
            }
          }
        }
        return false;
      });
      return blastMsgList?.length ? true : false;
    }
    return false;
  }
}
