import { WebsiteStdContentList, WebsiteThemeBackground, WebsiteThemeColor } from '../interfaces/website';

export const StdWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdEventWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdWebsiteContentsList: WebsiteStdContentList = {
    // 'info': {
    //     contentId: 'info',
    //     order: 1,
    //     fixed: true,
    // },
    'host_msg': {
        contentId: 'host_msg',
        order: 1,
        fixed: false,
    },
    'save_the_date': {
        contentId: 'save_the_date',
        order: 2,
        fixed: false,
    },
    'venue': {
        contentId: 'venue',
        order: 3,
        fixed: false,
    },
    'itinerary': {
        contentId: 'itinerary',
        order: 4,
        fixed: false,
    },
    'gallery': {
        contentId: 'gallery',
        order: 5,
        fixed: false,
    },
    'contact': {
        contentId: 'contact',
        order: 6,
        fixed: false,
    }
};

export const StdWebsiteContactType: string[] = [
    'whatsapp',
    'phone',
    'email',
    'line',
    'messenger',
    'wechat',
    'telegram',
    'viber',
    'kaokao',
    'signal',
    'snapchat',
    'threads',
    'instagram',
    'facebook',
    'skype',
    'discord',
    'slack',
    'website'
];
