import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlastMsg } from 'src/app/interfaces/blast';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { BlastTemplate, BlastUsage } from 'src/app/interfaces/blast';
import { AccountInfoService } from '../account/account-info.service';
import { ErrorService } from '../general/error.service';
import { PopupService } from '../general/popup.service';
import { GroupService } from '../group/group.service';
import { GuestService } from '../guest/guest.service';
import { UpdateByService } from '../user/update-by.service';
import { BlastPricingService } from './blast-pricing.service';
import { BlastType } from 'src/app/types/blast';

@Injectable({
  providedIn: 'root'
})
export class BlastSendService implements OnInit, OnDestroy {

  constructor(
    private fns: AngularFireFunctions,
    private router: Router,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private blastPricingService: BlastPricingService,
    private guestService: GuestService,
    private groupService: GroupService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setupBlastGuestList(blastType: BlastType, guestIdList: string[], groupIdList: string[], blastTemplate?: BlastTemplate) {
    const oriGroupList = [ ...this.groupService.getGroupListById(groupIdList) ];
    const groupList: Group[] = oriGroupList.filter((group: Group) => {
      if (group?.mobile?.no && group.mobile.code) {
        if (blastTemplate?.url || blastTemplate?.templateType?.value === 'qrcode') {
          if (blastType === 'sms' && (group.mobile.code === 60 || group.mobile?.country === 'MY')) {
            return false;
          }
        }
        const blastCredit = this.blastPricingService.getBlastCredit(blastType, group.mobile?.country, group.mobile.code);
        if (blastCredit <= 0) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    });
    const oriGuestList = [ ...this.guestService.getGuestListById(guestIdList) ];
    const guestList: Guest[] = oriGuestList.filter((guest: Guest) => {
      if (guest?.mobile?.no && guest?.mobile?.code) {
        if (blastTemplate?.url || blastTemplate?.templateType?.value === 'qrcode') {
          if (blastType === 'sms' && (guest.mobile.code === 60 || guest.mobile?.country === 'MY')) {
            return false;
          }
        }
        const blastCredit = this.blastPricingService.getBlastCredit(blastType, guest.mobile?.country, guest.mobile.code);
        if (blastCredit <= 0) {
          return false;
        }
        if (guest?.groupId) {
          const index = groupList.findIndex((group: Group) => {
            return group.groupId === guest.groupId;
          });
          if (index !== -1) {
            if (groupList[index].mobile?.no === guest.mobile.no && groupList[index].mobile?.code === guest.mobile.code) {
              return false;
            }
          }
        }
      } else {
        return false;
      }
      return true;
    })?.reduce((array: Guest[], guest: Guest) => {
      const removedDuplicate = array.filter((x: Guest) => {
        if (guest?.groupId && x.groupId === guest.groupId) {
          if (x.mobile.no === guest.mobile.no && x.mobile.code === guest.mobile.code) {
            return false;
          }
        }
        return true;
      });
      return [...removedDuplicate, guest];
    }, []);
    return { groupList, guestList };
  }

  async initialBlastMsgCall(blastType: BlastType, blastTemplate: BlastTemplate, blastMsgList: BlastMsg[], guestList: Guest[], groupList: Group[]) {
    const createBy = this.updateByService.generateUpdateBy();
    const accountId = this.accountInfoService.accountId;
    // const guestIdList = guestList?.map((guest: Guest) => {
    //   return guest?.guestId;
    // });
    // const groupIdList = groupList?.map((group: Group) => {
    //   return group.groupId;
    // });

    if (blastTemplate?.createBy) {
      delete blastTemplate.createBy;
    }
    if (blastTemplate?.updateBy) {
      delete blastTemplate.updateBy;
    }

    const param: any = {
      blastType,
      accountId,
      blastTemplate,
      blastMsgList,
      guestList,
      groupList,
      createBy,
    };
    // await this.popupService.presentLoading();
    await this.fns.httpsCallable('initialBlastMsgCall')(param)
    .toPromise().then(async (response: any) => {
      if (response?.error) {
        this.promptError(response.error);
      } else if (response?.blastUsage && response?.blastMsgList) {
        this.goBlastResultPage(blastType, response.blastUsage, response.blastMsgList);
      } else {
        this.promptError();
      }     
    }).catch((err: any) => {
      this.promptError();
      this.errorService.logError(err);
    });
    // await this.popupService.dismissLoading();
  }

  async promptError(err?: string) {
    if (!err || this.translate.instant('BLAST.error.' + err) === 'BLAST.error.' + err) {
      err = 'error';
    }
    
    const alert = await this.popupService.presentAlert(this.translate.instant('BLAST.error.' + err));
    // alert.onDidDismiss().then(() => {
      
    // });
  }

  goBlastResultPage(blastType: BlastType, blastUsage: BlastUsage, blastMsgList: BlastMsg[]) {
    const navigationExtras: NavigationExtras = {
      replaceUrl: true,
      state: {
        blastType,
        blastUsage,
        blastMsgList,
      }
    };
    this.router.navigate(['/blast/result'], navigationExtras);
    this.popupService.dismissAllModal();
  }
}
