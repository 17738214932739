import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CurrencyService } from 'src/app/services/general/currency.service';
import { DiscountDetail, Item, Transaction, PaymentRef, StripeData, RefundTransaction } from 'src/app/interfaces/transaction';
import { Price } from 'src/app/interfaces/product';

import { AccountReceiptItem, BlastReceiptItem, LineItem } from 'src/app/commons/pdf/receipt/item';
import { ReceiptFooter } from 'src/app/commons/pdf/receipt/footer';
import { ReceiptHeader } from 'src/app/commons/pdf/receipt/header';
import { ReceiptImg } from 'src/app/commons/pdf/receipt/image';
import { ReceiptPdf } from 'src/app/commons/pdf/receipt/pdf';
import { ReceiptStyle } from 'src/app/commons/pdf/receipt/style';
import { CountryList } from 'src/app/commons/country';
import { Country, SettingField } from 'src/app/interfaces/database';

import { BlastPackageService } from '../blast/blast-package.service';

import { ModalController } from '@ionic/angular';
import { UserReceiptComponent } from 'src/app/components/user/user-receipt/user-receipt.component';
import { DateTimeService } from '../general/date-time.service';
import { TransactionService } from '../payment/transaction.service';
import { RefundService } from '../payment/refund.service';
import { RefundTitle } from 'src/app/commons/pdf/receipt/refund';
import { RefundTrigger } from 'src/app/types/transaction';
import { LanguageService } from '../general/language.service';


@Injectable({
  providedIn: 'root'
})
export class ReceiptService implements OnInit, OnDestroy {

  private translation: any;
  /**
   * Constructor
   * @param translate translatae service
   * @param currencyService currency service
   */
  constructor(
    private modalController: ModalController,
    private currencyService: CurrencyService,
    private dateTimeService: DateTimeService,
    private languageService: LanguageService,
    private blastPackageService: BlastPackageService,
    private transactionService: TransactionService,
    private refundService: RefundService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async presentUserReceiptModal(transaction: Transaction) {
    if (transaction && transaction?.status === 'succeeded') {
      const modal = await this.modalController.create({
        component: UserReceiptComponent,
        componentProps: {
          transaction
        }
      });
      modal.present();
    }
  }

  setupPdfDD(transaction: Transaction) {
    try {
      const res = this.languageService.getTranslation('en');
      if (res?.en) {
        this.translation = res.en;
      } else {
        this.translation = res;
      }
      if (transaction?.type === 'account') {
        return this.setupAccountPdfDD(transaction);
      } else if (transaction?.type === 'blast' || transaction?.type === 'sms') {
        return this.setupBlastPdfDD(transaction);
      }
    } catch(err: any) {
      console.error(err);
    }
    
    return null;
  }

  /**
   * Setup PDF
   * @param transaction transaction
   * @returns pdf doc defination
   */
  setupAccountPdfDD(transaction: Transaction) {
    try {
      const dd: any = ReceiptPdf;
      const iap: boolean = transaction.paymentRef.type === 'ios-appstore' || transaction.paymentRef.type === 'android-playstore' ? true : false;
      dd.info = {
        title: this.translation?.RECEIPT.pdf.title + transaction.transactionId,
        author: 'thebigdays',
        subject: this.translation?.RECEIPT.pdf.subject,
        keywords: 'thebigdays App',
        creator: 'thebiday.my LLP',
        producer: 'thebiday.my LLP',
      };
  
      const time = this.dateTimeService.format(transaction.createBy.time.seconds * 1000, 'do MMM yyyy h:mm a', 'en');
  
      dd.header = this.getReceiptHeader(transaction.transactionId, time);
      dd.footer = this.getReceiptFooter(transaction);
      dd.defaultStyle = ReceiptStyle.defaultStyle;
      dd.styles = ReceiptStyle.styles;
      dd.images = ReceiptImg.images;
      dd.pageMargins = [40, 200, 40, 120],
  
      dd.content = [];
  
      let total = 0;
      let subtotal = 0;
      let systemDiscount = 0;
      let bulkDiscount = 0;
      let voucherDiscount = 0;
      let referralDiscount = 0;
  
      const currency = transaction.payment.currency;
  
      if (transaction?.itemList?.length) {
        transaction.itemList?.forEach((item: Item) => {
          if (item.qty) {
            const freeBlastCredit = transaction?.freeBlastCredit ? transaction.freeBlastCredit : transaction?.freeSmsCredit ? transaction.freeSmsCredit : 0;
            const receiptItem = this.getAccountReceiptItem(item.qty, item.productId, item.oriPrice, transaction?.upgrade, freeBlastCredit);
            dd.content.push(receiptItem);
  
            subtotal += (item.oriPrice.amount * item.qty);
          }
        });
      }

      if (subtotal !== transaction.payment.amount) {
        dd.content.push({ text: this.translation?.LBL.subtotal + ': '
        + this.currencyService.formatCurrency(currency, subtotal), style: ['right'], fontSize: 16 });
  
        transaction?.itemList?.forEach((item: Item) => {
          let itemPrice = item.oriPrice.amount;
          if (item.oriPrice.amount !== item.price.amount) {
            const systemDiscountAmount = this.rounding(currency, itemPrice - item.price.amount, iap);
            itemPrice -= systemDiscountAmount;
            systemDiscount += (systemDiscountAmount * item.qty);
          }
          if (transaction?.discount?.bulk) {
            const bulkDiscountAmount = this.rounding(currency, itemPrice * transaction.discount.bulk.percent, iap);
            itemPrice -= bulkDiscountAmount;
            bulkDiscount += (bulkDiscountAmount * item.qty);
          }
          if (transaction?.discount?.voucher) {
            const voucherDiscountAmount = this.rounding(currency, itemPrice * transaction.discount.voucher.percent, iap);
            itemPrice -= voucherDiscountAmount;
            voucherDiscount += (voucherDiscountAmount * item.qty);
          }
          if (transaction?.discount?.referral) {
            const referralDiscountAmount = this.rounding(currency, itemPrice * transaction.discount.referral.percent, iap);
            itemPrice -= referralDiscountAmount;
            referralDiscount += (referralDiscountAmount * item.qty);
          }
          total += (itemPrice * item.qty);
        });
  
        if (systemDiscount) {
          dd.content.push(this.getReceiptDiscount('system', null, currency, systemDiscount));
        }
  
        if (transaction?.discount?.bulk && bulkDiscount) {
          dd.content.push(this.getReceiptDiscount('bulk', transaction.discount.bulk, currency, bulkDiscount));
        }
  
        if (transaction?.discount?.voucher && voucherDiscount) {
          dd.content.push(this.getReceiptDiscount('voucher', transaction.discount.voucher, currency, voucherDiscount));
        }
  
        if (transaction?.discount?.referral && referralDiscount) {
          dd.content.push(this.getReceiptDiscount('referral', transaction.discount.referral, currency, referralDiscount));
        }
      } else {
        total = subtotal;
      }
  
      if (transaction?.paymentRef?.type === 'free' && transaction?.paymentRef?.method === 'gift' && transaction?.payment?.amount === 0) {
        dd.content.push(this.getReceiptDiscount('migrate', null, currency, total));
        total = 0;
      }
  
      let refund = 0;
      const refundItemList = [];
  
      const refundList = this.getRefundListByTransactionId(transaction?.transactionId);
      if (refundList?.length) {
        refundList?.forEach((refundTransaction: RefundTransaction, index: number) => {
          if (refundTransaction?.refund?.amount) {
            refundItemList.push(this.getRefundDescription(refundTransaction));
            refundItemList.push(this.getRefundId(refundTransaction.refundId));
            if (refundTransaction?.reason?.value) {
              refundItemList.push(this.getRefundReason(refundTransaction.reason));
            }
            refund += refundTransaction.refund.amount;
          }
        });
      }
  
      if (refund) {
        dd.content.push(this.getRefundAmount(currency, refund));
      }

      const paymentAmount = this.currencyService.formatCurrency(currency, transaction.payment.amount - refund);
  
      dd.content.push({ text: this.translation?.LBL.total_amount + ': '
      + paymentAmount, style: ['bold', 'right'], fontSize: 20 });
      if (transaction.taxRate) {
        dd.content.push({ text: this.translation?.RECEIPT.pdf.lbl.tax + ': ' + transaction.taxRate, style: ['right'], fontSize: 14 });
      }
      dd.content.push(this.getReceiptPaymentMethod(transaction?.paymentRef));
  
      total = this.rounding(currency, total, iap);
      
      if (refund && refundItemList?.length) {
        dd.content.push(this.getRefundTitle());
        refundItemList?.forEach((x: any) => {
          dd.content.push(x);
        });
      }

      if (total === transaction.payment.amount || iap) {
        return dd;
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
      return null;
    }
  }

  setupBlastPdfDD(transaction: Transaction) {
    const dd: any = ReceiptPdf;

    dd.info = {
      title: this.translation?.RECEIPT.pdf.title + transaction.transactionId,
      author: 'thebigdays',
      subject: this.translation?.RECEIPT.pdf.subject,
      keywords: 'thebigdays Wedding Planning App',
      creator: 'thebiday.my LLP',
      producer: 'thebiday.my LLP',
    };

    const time = this.dateTimeService.format(transaction.createBy.time.seconds * 1000, 'do MMM yyyy h:mm a', 'en');

    dd.header = this.getReceiptHeader(transaction.transactionId, time);
    dd.footer = this.getReceiptFooter(transaction);
    dd.defaultStyle = ReceiptStyle.defaultStyle;
    dd.styles = ReceiptStyle.styles;
    dd.images = ReceiptImg.images;
    dd.pageMargins = [40, 200, 40, 120],

    dd.content = [];

    let total = 0;
    let subtotal = 0;
    let voucherDiscount = 0;

    const currency = transaction.payment.currency;
    if (transaction?.itemList?.length) {
      transaction.itemList?.forEach((item: Item) => {
        if (item.qty) {
          const receiptItem = this.getBlastReceiptItem(item.qty, item.productId, item.productName, item.oriPrice);
          dd.content.push(receiptItem);
          subtotal += (item.oriPrice.amount * item.qty);
        }
      });
    }

    if (subtotal !== transaction.payment.amount) {
      dd.content.push({ text: this.translation?.LBL.subtotal + ': '
      + this.currencyService.formatCurrency(currency, subtotal), style: ['right'], fontSize: 16 });

      transaction?.itemList?.forEach((item: Item) => {
        let itemPrice = item.oriPrice.amount;
        if (transaction?.discount?.voucher) {
          const voucherDiscountAmount = this.rounding(currency, itemPrice * transaction.discount.voucher.percent);
          itemPrice -= voucherDiscountAmount;
          voucherDiscount += (voucherDiscountAmount * item.qty);
        }
        total += (itemPrice * item.qty);
      });

      if (transaction?.discount?.voucher && voucherDiscount) {
        dd.content.push(this.getReceiptDiscount('voucher', transaction.discount.voucher, currency, voucherDiscount));
      }
    } else {
      total = subtotal;
    }

    let refund = 0;
    const refundItemList = [];

    const refundList = this.getRefundListByTransactionId(transaction?.transactionId);
    if (refundList?.length) {
      refundList?.forEach((refundTransaction: RefundTransaction) => {
        if (refundTransaction?.refund?.amount) {
          refundItemList.push(this.getRefundDescription(refundTransaction));
          refundItemList.push(this.getRefundId(refundTransaction.refundId));
          if (refundTransaction?.reason?.value) {
            refundItemList.push(this.getRefundReason(refundTransaction.reason));
          }
          refund += refundTransaction.refund.amount;
        }
      });
    }
    if (refund) {
      dd.content.push(this.getRefundAmount(currency, refund));
    }

    dd.content.push({ text: this.translation?.LBL.total_amount + ': '
    + this.currencyService.formatCurrency(currency, transaction.payment.amount - refund), style: ['bold', 'right'], fontSize: 20 });
    if (transaction.taxRate) {
      dd.content.push({ text: this.translation?.RECEIPT.pdf.lbl.tax + ': ' + transaction.taxRate, style: ['right'], fontSize: 14 });
    }
    dd.content.push(this.getReceiptPaymentMethod(transaction?.paymentRef));

    if (refund && refundItemList?.length) {
      dd.content.push(this.getRefundTitle());
      refundItemList?.forEach((x: any) => {
        dd.content.push(x);
      });
    }
    total = this.rounding(currency, total);
    if (total === transaction.payment.amount) {
      return dd;
    } else {
      return null;
    }
  }

  /**
   * Get receipt header
   * @param tranId Transaction ID
   * @param time trasaction time
   * @returns receipt header
   */
  getReceiptHeader(tranId: string, time: string) {
    let header: any = ReceiptHeader.header;
    if (header) {
      header = JSON.stringify(header);
      header = header.replace(/{{COMPANY}}/g, this.translation?.COMPANY.hq.name);
      header = header.replace(/{{COMPANY_NO}}/g, '(' + this.translation?.COMPANY.hq.code + ')');
      header = header.replace(/{{TITLE}}/g, this.translation?.RECEIPT.pdf.lbl.official);
      header = header.replace(/{{SUMMARY}}/g, this.translation?.RECEIPT.pdf.lbl.summary);
      header = header.replace(/{{TRANID}}/g, tranId ? this.translation?.RECEIPT.title + ' #' + tranId : '');
      header = header.replace(/{{TIME}}/g, time);
      header = JSON.parse(header);
    }
    return header;
  }

  /**
   * Get receipt footer
   * @returns receipt footer
   */
  getReceiptFooter(transaction: Transaction) {
    let footer: any = ReceiptFooter.footer;
    if (footer) {
      footer = JSON.stringify(footer);
      if (transaction?.payment?.currency === 'SGD'
      || transaction?.paymentRef?.type === 'android-playstore'
      || transaction?.paymentRef?.type === 'ios-appstore') {
        footer = footer.replace(/{{COMPANY}}/g, this.translation?.COMPANY.hq.name);
        footer = footer.replace(/{{COMPANY_NO}}/g, this.translation?.COMPANY.hq.code);
      } else {
        footer = footer.replace(/{{COMPANY}}/g, this.translation?.COMPANY.my.name);
        footer = footer.replace(/{{COMPANY_NO}}/g, this.translation?.COMPANY.my.code);
      }

      footer = footer.replace(/{{THANK_YOU}}/g, this.translation?.RECEIPT.pdf.msg.thank_you);
      footer = footer.replace(/{{SIGNATURE}}/g, this.translation?.RECEIPT.pdf.msg.signature);
      footer = JSON.parse(footer);
    }
    return footer;
  }

  /**
   * Get receipt item
   * @param qty Qty
   * @param name Product name
   * @param price product price
   * @returns Receipt item
   */
  getAccountReceiptItem(qty: number, name: string, price: Price, upgrade?: boolean, blastCredit?: number) {
    let item: any = AccountReceiptItem;
    if (item) {
      item = JSON.stringify(item);
      item = item.replace(/{{QTY}}/g, qty.toString());
      if (upgrade) {
        item = item.replace(/{{ITEM_NAME}}/g, this.translation?.BTN.upgrade + ' '
        + this.translation?.PRODUCT.account?.[name].name);
      } else {
        item = item.replace(/{{ITEM_NAME}}/g, this.translation?.PRODUCT.account?.[name].name);
      }
      if (blastCredit) {
        item = item.replace(/{{DESCRIPTION}}/g, blastCredit + ' ' + this.translation?.BLAST.credit.title + ' + ' + this.translation?.PRODUCT.account?.[name].desc);
      } else {
        item = item.replace(/{{DESCRIPTION}}/g, this.translation?.PRODUCT.account?.[name].desc);
      }
      
      item = item.replace(/{{UNIT_PRICE}}/g, this.currencyService.formatCurrency(price.currency, price.amount));
      item = JSON.parse(item);
    }
    return item;
  }

  getBlastReceiptItem(qty: number, productId: string, productName: string, price: Price) {
    let item: any = BlastReceiptItem;
    if (item) {
      const name = this.blastPackageService.getBlastPackageNameById(productId, 'en');
      item = JSON.stringify(item);
      item = item.replace(/{{QTY}}/g, qty.toString());
      item = item.replace(/{{ITEM_NAME}}/g, name ? name : productName);
      item = item.replace(/{{DESCRIPTION}}/g, "");
      item = item.replace(/{{UNIT_PRICE}}/g, this.currencyService.formatCurrency(price.currency, price.amount));
      item = JSON.parse(item);
    }
    return item;
  }

  /**
   * Get receipt discount
   * @param type discount type
   * @param discount discount detail
   * @param currency discount currency
   * @param amount dicount amount
   * @returns receipt discount
   */
  getReceiptDiscount(type: string, discount: DiscountDetail, currency: string, amount: number, iapMode?: boolean) {
    let text = '';
    if (type === 'voucher') {
      text = this.translation?.RECEIPT.pdf.lbl.voucher;
    } else if (type === 'referral') {
      text = this.translation?.RECEIPT.pdf.lbl.referral;
    } else if (type === 'bulk') {
      text = this.translation?.RECEIPT.pdf.lbl.bulk;
    } else if (type === 'system') {
      text = this.translation?.RECEIPT.pdf.lbl.system;
    } else if (type === 'migrate') {
      text = this.translation?.RECEIPT.pdf.lbl.migrate;
    }
    if (discount?.id) {
      if (type === 'bulk' && discount?.percent) {
        text = text + ' (' + discount.percent * 100 + '%)';
      } else {
        text = text + ' (' + discount.id + ')';
      }
    }

    text += ': ';
    
    // text = text + this.currencyService.formatCurrency(currency, amount) + ' -';

    if (iapMode) {
      text = text + (currency + ' ' + amount);
    } else {
      text = text + this.currencyService.formatCurrency(currency, amount);
    }

    if (type !== 'roundDown') {
      text = text + ' -';
    }
    return {
      text,
      style: ['right', 'red'],
      fontSize: 16
    };
  }

  /**
   * Get receipt tax
   * @param taxRate tax rate
   * @param total total
   * @returns receipt tax
   */
  getReceiptTax(taxRate: number, total: Price) {
    let text = this.translation?.RECEIPT.pdf.lbl.tax;
    text = text + ' (' + taxRate * 100 + '%)';
    text = text + ': ' + this.currencyService.formatCurrency(total.currency, total.amount);
    return { text, style: ['right'], fontSize: 14 };
  }

  /**
   * Get receipt payment method
   * @param paymentRef payment reference
   * @returns Receipt payment method
   */
  getReceiptPaymentMethod(paymentRef: PaymentRef) {
    const text = this.translation?.RECEIPT.pdf.lbl.method + ': ' + this.transactionService.getPaymentMethod(paymentRef);
    return { text, style: ['right'], fontSize: 14 };
  }

  getCountryNameByPhone(phone: number): string {
    if (phone) {
      const countryList = CountryList
      .filter((x: Country) => x?.phone === phone)
      .map((x: Country) => {
        return x.name;
      });
      return countryList.join(' / ');
    } else {
      return null;
    }
  }

  getRefundAmount(currency: string, amount: number) {
    return {
      text: this.translation?.REFUND.lbl.refunded + ': ' + this.currencyService.formatCurrency(currency, amount) + ' -',
      style: ['right', 'red'],
      fontSize: 16
    };
  }

  getRefundTitle() {
    let item: any = RefundTitle;
    item = JSON.stringify(item);
    item = item.replace(/{{REFUND_TITLE}}/g, this.translation?.RECEIPT.pdf.lbl.notes);
    item = JSON.parse(item);
    return item
  }

  getRefundDescription(refundTransaction: RefundTransaction) {
    return { fontSize: 8, text: '* ' + this.translation?.REFUND.lbl.full_title2.replace('{{ date }}', this.dateTimeService.format(refundTransaction?.createBy?.time?.seconds * 1000, 'do MMM yyyy h:mm a', 'en')).replace('{{ amount }}', this.currencyService.formatCurrency(refundTransaction?.refund.currency, refundTransaction?.refund.amount)) };
  }

  getRefundReason(reason: SettingField) {
    const text = this.translation?.REFUND.lbl.refund_reason + ': ' + (reason?.custom ? reason.value : this.translation?.REFUND.reason?.[reason.value]);
    return { margin: [5, 0, 0, 0], fontSize: 8, text };
  }

  getRefundId(refundId: string) {
    const text = this.translation?.REFUND.lbl.refund_id + ': ' + refundId;
    return { margin: [5, 0, 0, 0], fontSize: 8, text };
  }

  getRefundTrigger(triggerBy: RefundTrigger) {
    return { margin: [5, 0, 0, 0], fontSize: 8, text: this.translation?.REFUND.trigger?.[triggerBy]  };
  }

  getRefundListByTransactionId(transactionId: string) {
    return this.refundService.getRefundListByTransactionId(transactionId);
  }
  
  rounding(currency: string, amount: number, iap?: boolean) {
    // if (iap) {
    //   return amount;
    // }
    return this.currencyService.rounding(currency, amount);
  }

  // getTranlsation(key: string) {
  //   return this.translation?.[key] ? this.translation[key] : '';
  // }

}
