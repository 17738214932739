import { Language } from '../interfaces/database';

/**
 * System supported language list
 */
export const LanguageList: Language[] = [
    { code: 'en', name: 'English' },
    { code: 'ms', name: 'Malay' },
    { code: 'zh-TW', name: '繁體中文' },
    { code: 'zh', name: '简体中文' }
];
