<ion-header class="ion-no-border">
  <ion-toolbar color="white">
    <ion-buttons slot="start" *ngIf="showBackBtn()">
      <ion-button (click)="back()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <!-- <ion-icon src="./assets/wedding/icon/general/flag.svg"></ion-icon> &nbsp; -->
      <ng-container *ngIf="migrate">
        {{ 'ACCOUNTS_MIGRATE.title' | translate }}
      </ng-container>
      <ng-container *ngIf="!migrate">
        <ng-container *ngIf="!premium">{{ 'ACCOUNTS.lbl.trial_setup' | translate }}</ng-container>
        <ng-container *ngIf="premium">{{ 'ACCOUNTS.lbl.premium_setup' | translate }}</ng-container>
      </ng-container>
    </ion-title>
    <!-- <ion-buttons slot="end" *ngIf="premium && !type">
      <ion-button (click)="skipSetup()">
        <ion-icon src="./assets/wedding/icon/general/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons> -->
    <ion-progress-bar [value]="progress" color="primary"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
<ion-content class="full-width ion-padding" color="white" [style.height]="pageMode ? 'calc(100vh - 44px - 88px)' : '100%'" #content>
  <div class="content-div">
    <div *ngIf="step === 'couple'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.couple.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then imgBlock"></ng-container>
        </div>
      </div>
        
      <form [formGroup]="coupleForm" (ngSubmit)="coupleFormSubmit()" *ngIf="coupleForm">
        <ion-list class="background-transparent background-transparent ion-padding-vertical" lines="none">
          <ng-container *ngIf="!eventMode">
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ 'FIELD.name' | translate: { field: ('LIST.wedding_couple_type.' + getCoupleType(1) | translate) } }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-input formControlName="couple1" type="text" inputmode="text" autocapitalize="words" autocomplete="name" class="prompt-input" (ionBlur)="setupAccountTitle()" tabindex="1"></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.couple1">
                <div class="error-message" *ngIf="coupleForm.get('couple1').hasError(validation.type) && (coupleForm.get('couple1').dirty || coupleForm.get('couple1').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
    
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ 'FIELD.name' | translate: { field: ('LIST.wedding_couple_type.' + getCoupleType(2) | translate) } }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-input formControlName="couple2" type="text" inputmode="text" autocapitalize="words" autocomplete="name" class="prompt-input" (ionBlur)="setupAccountTitle()" tabindex="2"></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.couple2">
                <div class="error-message" *ngIf="coupleForm.get('couple2').hasError(validation.type) && (coupleForm.get('couple2').dirty || coupleForm.get('couple2').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </ng-container>
  
          <ng-container *ngIf="eventMode">
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ replaceEventType('FOR_EVENT.lbl.organizer' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-input formControlName="organizer" type="text" inputmode="text" autocapitalize="words" autocomplete="on" class="prompt-input" tabindex="1"></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.organizer">
                <div class="error-message" *ngIf="coupleForm.get('organizer').hasError(validation.type) && (coupleForm.get('organizer').dirty || coupleForm.get('organizer').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ng-container>
  
          <ng-container *ngIf="(coupleForm.value.couple1 && coupleForm.value.couple2) || coupleForm.value.title || eventMode">
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ replaceEventType('ACCOUNT.lbl.wedding_title' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-input formControlName="title" type="text" inputmode="text" autocapitalize="words" autocomplete="on" class="prompt-input" (ionChange)="setupCustomAccountTitle()" (ionBlur)="setupAccountTitle()" tabindex="3"></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.title">
                <div class="error-message" *ngIf="coupleForm.get('title').hasError(validation.type) && (coupleForm.get('title').dirty || coupleForm.get('title').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ion-list>
      </form>
  
      <app-account-couple-type class="account-couple-type full-width-height" [showEventMode]="true" [eventMode]="eventMode" (outputCoupleType)="setupCoupleType($event)" (outputEventMode)="setupEventMode($event)"></app-account-couple-type>
    </div>
  
    <div class="ion-padding" *ngIf="step === 'role'">
      <div>
        <div class="ion-text-center center ion-margin-bottom">
          <div class="ion-margin">
            <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.role.title' | translate) }}</ion-text>
          </div>
          <div class="ion-margin">
            <ng-container *ngIf="true then changeInfoBlock"></ng-container>
          </div>
          <div class="ion-margin">
            <ng-container *ngIf="true then imgBlock"></ng-container>
          </div>
        </div>
        <div class="ion-padding-vertical">
          <ion-grid class="ion-no-padding" fixed>
            <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center" *ngIf="!eventMode && accountCoupleList?.length">
              <ion-col class="ion-no-padding" size-xs="0" size-sm="1" size-md="2" size-lg="3"></ion-col>
              <ng-container *ngFor="let accountCouple of accountCoupleList; let i = index;">
                <ion-col class="ion-text-center center ion-align-self-center" *ngIf="accountCouple?.coupleId && (i === 0 || !checkSameCoupleType())">
                  <ion-card class="ion-padding ion-no-margin ion-text-center vertical-center couple-card" [color]="coupleId === accountCouple.coupleId ? 'tertiary': 'light'" (click)="selectCouple(accountCouple.coupleId)" button>
                    <ion-item class="ion-item-no-padding background-transparent" lines="none">
                      <ion-img class="icon-couple" slot="start" src="./assets/wedding/img/account/bride.svg" *ngIf="accountCouple.coupleType === 'bride'"></ion-img>
                      <ion-text class="font-14 font-med">{{ 'ACCOUNTS.btn.' + accountCouple.coupleType | translate }}</ion-text>
                      <ion-img class="icon-couple" slot="end" style="margin-right: 0;" src="./assets/wedding/img/account/groom.svg" *ngIf="accountCouple.coupleType === 'groom'"></ion-img>
                    </ion-item>
                  </ion-card>
                </ion-col>
              </ng-container>
              <ion-col class="ion-no-padding" size-xs="0" size-sm="1" size-md="2" size-lg="3"></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center">
              <ion-col class="ion-text-center center ion-align-self-center">
                <ng-container  *ngIf="!eventMode">
                  <ion-button class="" expand="block" [fill]="coupleId === 0 ? 'solid' : 'outline'" (click)="setOtherRole()">{{ 'ACCOUNTS.btn.other_role' | translate }}</ion-button>
                </ng-container>
                <div *ngIf="coupleId === 0">
                  <!-- <div>
                    <ion-text class="font-12 font-med">{{ 'ACCOUNTS.btn.other_role' | translate }}</ion-text>
                  </div> -->
                  <ion-item class="ion-margin-vertical ion-text-center prompt-input-item" detail lines="none" (click)="presentRoleModal(role)">
                    <ion-text *ngIf="role?.type">
                      <ng-container *ngIf="role.custom">{{ role.type }}</ng-container>
                      <ng-container *ngIf="!role.custom">{{ 'LIST.role.' + role.type | translate }}</ng-container>
                    </ion-text>
                    <ion-text *ngIf="!role?.type">
                      {{ 'MSG.please_select' | translate }}
                    </ion-text>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          
        </div>
      </div>
    </div>
  
    <div class="ion-padding" *ngIf="step === 'photo'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.photo.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then imgBlock"></ng-container>
        </div>
      </div>
      <div>
        <ng-container *ngIf="getAccountGalleryList() as accountGalleryList">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!accountGalleryList?.length" (click)="selectPhoto(false)" button>
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.background.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="accountGalleryList?.length" (click)="selectPhoto(true)">
            <ion-label class="ion-no-margin custom-background-label">
              <ion-img class="custom-background" [src]="accountGalleryList[0]"></ion-img>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </ng-container>
        
      </div>
    </div>
  
    <div *ngIf="step === 'account'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.account.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/account.svg"></ion-img>
        </div>
      </div>
      
      <form [formGroup]="accountForm" (ngSubmit)="accountFormSubmit()" *ngIf="accountForm">
        <ion-list class="background-transparent" lines="none">
          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked">
              {{ countryLbl }}
            </ion-label>
            <ion-item class="prompt-input-item" lines="none" (click)="openCountryModal()" button detail>
              {{ accountForm?.value?.country?.name ? accountForm.value.country.name : '' }}
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.country">
                <div class="error-message" *ngIf="accountForm.get('country').hasError(validation.type) && (accountForm.get('country').dirty || accountForm.get('country').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>    
          </ion-item>
    
          <ion-item class="background-transparent ion-item-no-padding" *ngIf="accountForm.value.country && timezoneList?.length" (click)="openTimezone()">
            <ion-label position="stacked" color="dark">{{ replaceEventType('DATE.lbl.wedding_timezone' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
            <ion-item class="prompt-input-item timezone-item background-transparent">
              <ion-label class="ion-no-margin" *ngIf="timezone?.label">{{ timezone.label }}</ion-label>
              <ion-note slot="end" class="ion-no-margin">
                <ion-icon class="font-18" color="medium" src="./assets/wedding/icon/general/chevron-down.svg"></ion-icon>
              </ion-note>
            </ion-item>
            <div class="error-div">
              <div class="error-message" *ngIf="!timezone || !timezone.name">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType('VALIDATION.required' | translate: { field: 'DATE.lbl.wedding_timezone' | translate }) }}
              </div>
            </div>  
          </ion-item>
  
          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ replaceEventType('DATE.lbl.wedding_date' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
            <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('date', 'start', accountForm?.value?.time)">
              <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.date">{{ format(formatNewDate(accountForm.value.date), 'do MMM yyyy') }}</ion-label>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.date">
                <div class="error-message" *ngIf="accountForm.get('date').hasError(validation.type) && (accountForm.get('date').dirty || accountForm.get('date').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ion-item>
  
          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.startTime' | translate }} <ion-text color="danger">*</ion-text></ion-label>
            <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('time', 'start', accountForm?.value?.time)">
              <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.time">{{  format(formatNewDate(accountForm.value.time), 'h:mm a') }}</ion-label>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.time">
                <div class="error-message" *ngIf="accountForm.get('time').hasError(validation.type) && (accountForm.get('time').dirty || accountForm.get('time').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ion-item>
  
          <!-- <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.endTime' | translate }}</ion-label>
            <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('time', 'end', accountForm?.value?.endTime)">
              <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.endTime">{{  format(formatNewDate(accountForm.value.endTime), 'h:mm a') }}</ion-label>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.endTime">
                <div class="error-message" *ngIf="accountForm.get('endTime').hasError(validation.type) && (accountForm.get('endTime').dirty || accountForm.get('endTime').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ion-item> -->
        </ion-list>
      </form>
    </div>
  
    <div *ngIf="step === 'venue'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.venue.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/venue.svg"></ion-img>
        </div>
      </div>
      <ion-item class="background-transparent ion-item-no-padding" lines="none" (click)="presentLocationModal()">
        <ion-label position="stacked" color="dark">{{ replaceEventType('LOCATION.lbl.wedding_venue' | translate) }}</ion-label>
        <ion-item class="prompt-input-item timezone-item background-transparent" lines="none">
          <ion-label class="ion-no-margin">
            <div class="venue-name" *ngIf="pageLocation?.name else locationBlock">
              <div class="ion-text-wrap">{{ pageLocation.name }}</div>
              <div class="ion-text-wrap line-1" *ngIf="pageLocation?.address">
                <ion-text class="font-10" color="medium">{{ pageLocation.address }}</ion-text>
              </div>
            </div>
            <ng-template #locationBlock>
              -
            </ng-template>
          </ion-label>
          <ion-note slot="end" class="ion-no-margin">
            <ion-icon class="font-18" color="medium" src="./assets/wedding/icon/general/chevron-down.svg"></ion-icon>
          </ion-note>
        </ion-item>
        <div class="error-div"></div>
      </ion-item>
    </div>
  
    <div *ngIf="step === 'category'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.category.title') | translate }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/setting_field.svg"></ion-img>
        </div>
      </div>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ invitedByTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('invited_by')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let invitedBy of stdInvitedByList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('invited_by', invitedBy) ? 'tertiary' : 'light'" (click)="clickSettingField('invited_by', invitedBy)" button>
                {{ getValue('invited_by', invitedBy) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('invited_by')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ categoryTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('category')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let category of stdCategoryList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('category', category) ? 'tertiary' : 'light'" (click)="clickSettingField('category', category)" button>
                {{ getValue('category', category) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('category')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  
    <div *ngIf="step === 'dietary'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.dietary.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/setting_field.svg"></ion-img>
        </div>
      </div>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ dietaryReqTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('dietary_req')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let dietaryReq of stdDietaryReqList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('dietary_req', dietaryReq) ? 'tertiary' : 'light'" (click)="clickSettingField('dietary_req', dietaryReq)" button>
                {{ getValue('dietary_req', dietaryReq) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('dietary_req')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ specialReqTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('special_req')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let specialReq of stdSpecialReqList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('special_req', specialReq) ? 'tertiary' : 'light'" (click)="clickSettingField('special_req', specialReq)" button>
                {{ getValue('special_req', specialReq) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('special_req')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  
    <div class="ion-margin" *ngIf="step === 'seating'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.seating.title' | translate) }}</ion-text>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/seating.svg"></ion-img>
        </div>
        <app-setting-seating [newMode]="true" [countryCode]="pageLocation?.locality?.country" [guestPerSeating]="guestPerSeating" [seatingType]="seatingType" [seatingTypeOther]="seatingTypeOther" [setInitial]="true" (setGuestPerSeating)="setGuestPerSeating($event)" (setSeatingType)="setSeatingType($event)" (setSeatingTypeOther)="setSeatingTypeOther($event)"></app-setting-seating>
      
        <div>
          <ion-card class="seating-count-card ion-no-margin" color="light" button>
            <ion-item class="seating-count-item background-transparent" lines="none" (click)="presentSeatingNewModal()" button detail>
              <ion-icon class="seating-count-icon" color="medium" src="./assets/wedding/icon/guest/seating.svg" slot="start"></ion-icon>
              <ion-label class="ion-no-margin">
                <ion-text class="font-14 ion-text-wrap" [innerHtml]="'SEATING.lbl.count' | translate: { seating: seatingTypeName }" color="dark"></ion-text>
                <!-- <ion-label class="font-10 ion-text-wrap" [innerHtml]="'SETTING.seating.list.msg' | translate" color="medium"></ion-label> -->
              </ion-label>
              <ion-note class="" color="dark" slot="end">{{ seatingList?.length ? seatingList.length : 0 }}</ion-note>
            </ion-item>
          </ion-card>
        </div>
      </div>
    </div>
  
    <div class="full-width-height" *ngIf="step === 'completed'">
      <div class="full-width-height ion-text-center center vertical-center ion-margin-bottom">
        <div class="ion-margin-horizontal">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.completed.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div> -->
        <div class="ion-margin-horizontal">
          <ion-img class="completed-img center" src="./assets/wedding/gif/account_setup/completed.gif"></ion-img>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-padding ion-text-center">
    <div class="content-div">
      <ion-button class="ion-margin-horizontal" expand="block" color="primary" fill="solid" type="button" (click)="submit()" [disabled]="checkDisabledSubmit()">
        {{ 'BTN.continue' | translate }}
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>

<ng-template #changeInfoBlock>
  <!-- <div class="line-100">
    <ion-text class="font-10" [innerHTML]="'ACCOUNTS.msg.change_info_later' | translate">
    </ion-text>
  </div> -->
</ng-template>


<ng-template #imgBlock>
  <ng-container *ngIf="eventMode">
    <ion-img class="img center" src="./assets/wedding/img/accounts/love/event.svg"></ion-img>
  </ng-container>
  <ng-container *ngIf="!eventMode">
    <ng-container *ngIf="accountCoupleList?.length === 2">
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'bride' && accountCoupleList?.[1]?.coupleType === 'bride'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/bride_bride.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'groom' && accountCoupleList?.[1]?.coupleType === 'groom'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/groom_groom.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'bride' && accountCoupleList?.[1]?.coupleType === 'groom'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/bride_groom.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'groom' && accountCoupleList?.[1]?.coupleType === 'bride'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/groom_bride.svg"></ion-img>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>