<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'ACCOUNT.lbl.user' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  
  <div class="full-width-height account-user background-transparent" lines="none">
    <ion-item [color]="selectedUid === 'system' ? 'tertiary' : 'white'" lines="none" (click)="selectUser('system')" *ngIf="showSystem">
      <ion-avatar slot="start" class="small-avatar">
        <ion-img src="./assets/wedding/img/logo/thebigday-logo-round-primary.svg"></ion-img>
      </ion-avatar>
      <ion-label class="line-120">
        <ion-label class="font-14 font-med" color="dark">{{ 'LBL.system' | translate }}</ion-label>
      </ion-label>
      <ion-text slot="end" *ngIf="selectedUid === 'system'">
        <ion-icon src="./assets/wedding/icon/general/checkmark.svg" class="icon-selected"></ion-icon>
      </ion-text>
    </ion-item>
    <div class="full-width-height viewport-div" *ngIf="accountUserList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="80">
        <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
          <span *cdkVirtualFor="let user of accountUserList; trackBy: trackByFn; let i = index;">
            <ion-item-sliding *ngIf="user?.uid && excludedUid?.indexOf(user.uid) === -1">
              <ion-item class="item" [color]="selectedUid === user.uid ? 'tertiary' : 'white'" lines="none" (click)="selectUser(user.uid)" *ngIf="user?.uid">
                <ion-avatar slot="start" class="small-avatar">
                  <ng-container *ngIf="user?.avatar?.url">
                    <ion-img [src]="user.avatar.url" *ngIf="user.avatar?.type === 'local'"></ion-img>
                    <app-cached-image [src]="user.avatar.url" [spinner]="true" *ngIf="user?.avatar?.type !== 'local'"></app-cached-image>
                  </ng-container>
                  <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!user?.avatar?.url"></ion-icon>
                </ion-avatar>
                <ion-label class="line-120">
                  <ion-label class="font-14 font-med" color="dark" *ngIf="user?.name">{{ user.name }}</ion-label>
                  <ion-label class="font-12" color="medium" *ngIf="user?.role?.type">
                    <ng-container *ngIf="coupleTypeList?.[user.role.coupleId] as coupleType else userRoleBlock">
                      {{ 'LIST.wedding_couple_type.' + coupleType | translate }}
                    </ng-container>
                    <ng-template #userRoleBlock>
                      <ng-container *ngIf="user?.role?.type">
                        <ng-container *ngIf="user.role.custom">{{ user.role.type }}</ng-container>
                        <ng-container *ngIf="!user.role.custom">{{ 'LIST.role.' + user.role.type | translate }}</ng-container>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="user?.owner">
                      ({{ 'LIST.role.owner' | translate }})
                    </ng-container>
                  </ion-label>
                </ion-label>
                <ion-text slot="end" *ngIf="selectedUid === user?.uid">
                  <ion-icon src="./assets/wedding/icon/general/checkmark.svg" class="icon-selected"></ion-icon>
                </ion-text>
              </ion-item>
            </ion-item-sliding>
          </span>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    
    <div class="full-width-height center vertical-center" *ngIf="!accountUserList?.length">
      <ion-label class="ion-text-center ion-margin" color="medium">
        <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-12"><ion-text color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
      </ion-label>
    </div>
  </div>
</ion-content>
