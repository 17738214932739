import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StdWebsiteContentsList } from 'src/app/commons/website';
import { WebsiteContent, WebsiteContents, WebsiteStdContent } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class WebsiteContentsService implements OnInit, OnDestroy {

  accountId: string;

  websiteContents: WebsiteContents;

  observable: any;
  
  constructor(
    private afs: AngularFirestore,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteContents>(this.websiteContents);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setup(websiteContents: WebsiteContents) {
    if (!this.functionService.isEqual(websiteContents, this.websiteContents)) {
      this.websiteContents = websiteContents;
      this.observable.next(this.websiteContents);
    }
  }

  getWebsiteContents() {
    const websiteContents: WebsiteContents = this.websiteContents ? this.websiteContents : {};
    Object.values(StdWebsiteContentsList).forEach((websiteStdContent: WebsiteStdContent) => {
      if (websiteStdContent?.contentId && !websiteContents?.[websiteStdContent.contentId]) {
        websiteContents[websiteStdContent.contentId] = {
          contentId: websiteStdContent.contentId,
          title: '',
          msg: '',
          enable: true,
          order: websiteStdContent.order,
          fixed: websiteStdContent.fixed,
        };
      }
      if (websiteStdContent?.contentId && websiteContents?.[websiteStdContent.contentId]?.enable) {
        if (websiteStdContent.contentId === 'host_msg') {
          
        } else if (websiteStdContent.contentId === 'save_the_date') {
          
        } else if (websiteStdContent.contentId === 'venue') {
          
        } else if (websiteStdContent.contentId === 'gallery') {
          
        } else if (websiteStdContent.contentId === 'contact') {
          
        } else if (websiteStdContent.contentId === 'itinerary') {
          
        }
      }
    });

    return this.fixOrderNumbers(websiteContents);
  }

  getStdWebsiteContents() {
    let websiteContents: WebsiteContents = {};
    Object.values(StdWebsiteContentsList).forEach((websiteStdContent: WebsiteStdContent) => {
      if (websiteStdContent?.contentId) {
        websiteContents[websiteStdContent.contentId] = {
          contentId: websiteStdContent.contentId,
          title: '',
          msg: '',
          enable: true,
          order: websiteStdContent.order,
          fixed: websiteStdContent.fixed,
        };
      }
    });

    websiteContents = this.fixOrderNumbers(websiteContents);

    return websiteContents;
  }

  fixOrderNumbers(objects: WebsiteContents ) {
    // Sort the objects by order number
    const sortedObjects = Object.values(objects).sort((a, b) => a.order - b.order);

    // Map to track the next available order number
    let nextOrderNumber = Math.max(...sortedObjects.map(obj => obj.order)) + 1;

    // Iterate through the sorted objects
    for (const obj of sortedObjects) {
        if (!obj.fixed) {
            if (sortedObjects.some(o => o !== obj && o.order === obj.order)) {
                obj.order = nextOrderNumber++;
            }
        }
    }

    // Reconstruct the objects map with updated order numbers
    const result: WebsiteContents = {};
    for (const obj of sortedObjects) {
        result[obj.contentId] = obj;
    }

    return result;
  }

  async save(contents: WebsiteContents) {
    if (this.accountId && !this.functionService.isEqual(contents, this.websiteContents)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contents }, { merge: true });
    }
  }

  async saveWebsiteContent(content: WebsiteContent) {
    if (this.accountId && content?.contentId && !this.functionService.isEqual(content, this.websiteContents?.[content.contentId])) {
      const contents = {};
      contents[content.contentId] = content;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contents }, { merge: true });
    }
  }
}
