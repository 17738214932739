import { MobileComponent } from 'src/app/components/general/mobile/mobile.component';
import { FunctionService } from 'src/app/services/general/function.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController, ActionSheetController, IonItemSliding } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestProfileComponent } from 'src/app/components/guest/guest-profile/guest-profile.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { CheckinProfileComponent } from 'src/app/components/checkin/checkin-profile/checkin-profile.component';
import { GiftGuestProfileComponent } from 'src/app/components/gift/gift-guest-profile/gift-guest-profile.component';
import { GuestQrcodeComponent } from 'src/app/components/qrcode/guest-qrcode/guest-qrcode.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { GuestListMode, GuestSlidingType } from 'src/app/types/guest';
import { Guest, GuestItemField } from 'src/app/interfaces/guest';
import { Gift } from 'src/app/interfaces/gift';
import { SettingField } from 'src/app/interfaces/database';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { GiftType } from 'src/app/types/gift';
import { BlastPricingService } from 'src/app/services/blast/blast-pricing.service';
import { BlastMsgService } from 'src/app/services/blast/blast-msg.service';
import { BlastMsg, BlastTemplate } from 'src/app/interfaces/blast';
import { BlastDeliveredType } from 'src/app/types/blast';

/**
 * Guest item component for displaying guest item.
 */
@Component({
  selector: 'app-guest-item',
  templateUrl: './guest-item.component.html',
  styleUrls: ['./guest-item.component.scss'],
})
export class GuestItemComponent implements OnInit, OnDestroy {

  @Input() blastUsageId: string;
  /**
   * Select mode
   */
  @Input() selectMode: boolean;
  /**
   * Edit mode
   */
  @Input() editMode: boolean;
  /**
   * Gust item mode
   */
  @Input() mode: GuestListMode;
  /**
   * Guest
   */
  @Input() set setGuest(guest: Guest) {
    if (!this.functionService.isEqual(guest, this.guest)) {
      this.guest = guest;
      this.setupSettingField();
    }
  }
  /**
   * Selected guest list
   */
  @Input() selectedGuestList: string[];
  /**
   * Index of current guest item in the list.
   * Coloring based on even / odd to differentiate record
   */
  @Input() index: number;
  /**
   * Selected flag. Highlight the color of selected records.
   */
  @Input() selected: boolean;
  /**
   * Guest group modal. Dismiss current modal instead of present new guest group modal
   */
  @Input() groupModal: boolean;
  /**
   * Disable guest item click
   */
  @Input() disableClick: boolean;
  /**
   * Disable QR code sliding item.
   */
  @Input() disableQrcode: boolean;
  /**
   * Disable seating sliding item.
   */
  @Input() disableSeating: boolean;
  /**
   * Disable edit sliding item.
   */
  @Input() disableEdit: boolean;
  /**
   * Disable delete sliding item.
   */
  @Input() disableDelete: boolean;
  /**
   * Disable restore
   */
  @Input() disableRestore: boolean;
  /**
   * Disable checkin
   */
  @Input() disableCheckin: boolean;
  /**
   * Disable uncheck
   */
  @Input() disableUncheck: boolean;
  /**
   * Disable ion-item lines
   */
  @Input() disableLines = true;
  /**
   * Disable slide
   */
  @Input() disableSlide: boolean;
  /**
   * Show guest seating
   */
  @Input() showGuestSeating: boolean;
  /**
   * Watch guest list for group member
   */
  @Input() watchGuest: boolean;

  @Input() blastTemplate: BlastTemplate;
  /**
   * Setup guest by ID
   */
  @Input() set setupGuest(guestId: string) {
    if (guestId) {
      this.seatingTypeName = this.getSeatingTypeName();
      if (this.watchGuest) {
        this.watchGuestList(guestId);
      } else {
        this.getGuest(guestId);
      }

    }
  }
  /**
   * Set hide field
   */
  @Input() set setHideField(hideField: GuestItemField) {
    if (hideField) {
      Object.keys(hideField)?.forEach((key: string) => {
        if (key && this.showField[key]) {
          this.showField[key] = false;
        }
      });
    }
  }
  
  @Input() seatingTypeName: string;

  guest: Guest;

  // @Input() set setModule(module: ModuleType) {
  //   if (this.module !== module) {
  //     this.module = module;
  //     this.setupModule();
  //     if (this.module === 'checkin' || this.module === 'gift') {
  //       this.watchCheckinSetting();
  //     } else {
  //       this.unwatchCheckinSetting();
  //     }
  //     if (this.module === 'gift' && this.guest?.guestId) {
  //       this.getGuest(this.guest.guestId);
  //     }
  //   }
  //   this.checkSlidingItem();
  // }

  /**
   * Show field by default.
   */
  showField: GuestItemField = {
    name: true,
    nickname: true,
    invitedBy: true,
    category: true,
    dietaryReq: true,
    specialReq: true,
    remark: true,
    group: true,
    attendingStatus: true,
    qrcodeStatus: true,
    seatingStatus: true
  };
  /**
   * AccountModule
   */
  module: ModuleType;
  
  giftCount: {
    'cash': number,
    'other': number,
  };
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  blastMsg: BlastMsg;
  deliveredStatus: BlastDeliveredType;
  invitedBy: string;
  category: string;
  dietaryReq: string;
  specialReq: string;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * AccountModule subscription
   */
  private moduleSubscription: Subscription;
  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;

  private blastMsgSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param actionSheetController action sheet controller
   * @param translate translate service
   * @param moduleService module service
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param guestDeleteService guest delete service
   * @param groupService group service
   * @param groupManageService group manage service
   * @param checkinService check-in service
   * @param checkinSettingService check-in setting service
   * @param giftService gift service
   * @param giftCheckinService gift check-in service
   * @param userService user service
   * @param popupService popup service
   */
  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private seatingSettingService: SeatingSettingService,
    private checkinService: CheckinService,
    private checkinSettingService: CheckinSettingService,
    private blastMsgService: BlastMsgService,
    private blastPricingService: BlastPricingService,
    private giftService: GiftService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  /**
   * Ng init
   */
  ngOnInit() {
    this.watchModule();
  }

  ngOnDestroy(): void {
    this.unwatchModule();
    this.unwatchGuestList();
    this.unwatchCheckinSetting();
    this.unwatchBlastMsg();
  }

  /**
   * Watch guest list
   */
  async watchGuestList(guestId?: string) {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        if (guestId) {
          this.getGuest(guestId);
        }
      });
    }
    
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch module
   */
  async watchModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        if (this.module !== module) {
          this.module = module;
          this.setupModule();
          if (this.module === 'checkin' || this.module === 'gift') {
            this.watchCheckinSetting();
          } else {
            this.unwatchCheckinSetting();
          }
          if (this.module === 'blast') {
            this.watchBlastMsg();
          } else {
            this.unwatchBlastMsg();
          }
          if (this.module === 'gift' && this.guest?.guestId) {
            this.getGuest(this.guest.guestId);
          }
        }
        this.checkSlidingItem();
      });
    }
    
  }

  /**
   * Unwatch module
   */
  async unwatchModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  async watchBlastMsg() {
    if (!this.blastMsgSubscription) {
      this.blastMsgSubscription = this.blastMsgService.observableBlastMsgList.subscribe(() => {
        this.setupBlastMsg();
      });
    }
  }

  async unwatchBlastMsg() {
    if (this.blastMsgSubscription) {
      this.blastMsgSubscription.unsubscribe();
      this.blastMsgSubscription = null;
    }
  }

  /**
   * Setup module
   */
  setupModule() {
    if (this.module === 'checkin') {
      this.disableQrcode = true;
      this.disableDelete = true;
    } else if (this.module === 'trash') {
      this.disableEdit = true;
      this.disableQrcode = true;
      this.disableSeating = true;
    } else if (this.module === 'gift') {
      this.disableDelete = true;
      this.disableQrcode = true;
      this.disableSeating = true;
    }
    if (this.mode === 'qrcode') {
      this.disableQrcode = true;
    }
    if (this.module !== 'trash') {
      this.disableRestore = true;
    }

    if (this.module === 'trash' && this.mode === 'gift') {
      this.disableClick = true;
      this.disableDelete = true;
      this.disableRestore = true;
    }

    if (this.mode === 'preview') {
      this.disableSlide = true;
    }
  }

  setupBlastMsg() {
    this.blastMsg = this.getBlastMsg();
    this.deliveredStatus = this.getBlastMsgDeliveredStatus(this.blastMsg);
  }

  setupGiftCount() {
    this.giftCount = {
      cash: this.getGiftCount('cash'),
      other: this.getGiftCount('other'),
    };
  }

  setupSettingField() {
    this.invitedBy = this.getSettingField('invited_by', this.guest?.invitedBy);
    this.category = this.getSettingField('category', this.guest?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.guest?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.guest?.specialReq);
  }

  /**
   * Get guest
   * @param guestId Guest id
   */
  getGuest(guestId: string) {
    this.guest = this.guestService.getGuest(guestId);
    this.setupSettingField();

    this.setupBlastMsg();
    this.setupGiftCount();
    // this.setupGiftCount();
  }

  getSeatingTypeName(): string {
    return this.seatingTypeName ? this.seatingTypeName : this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Setup gift count
   */
  // setupGiftCount() {
  //   if (this.module === 'gift') {
  //     let cash = 0;
  //     let other = 0;
  //     if (this.guest?.giftList?.length) {
  //       const giftList = this.giftService.getGiftListById(this.guest.giftList);
  //       if (giftList?.length) {
  //         giftList?.forEach((gift: Gift) => {
  //           if (gift.giftType === 'cash' && !this.cash) {
  //             cash++;
  //           }
  //           if (gift.giftType === 'other' && !this.other) {
  //             other++;
  //           }
  //         });
  //       }
  //     }
  //     this.cash = cash;
  //     this.other = other;
  //   }
  // }

  getGiftCount(giftType: GiftType) {
    let count = 0;
    if (this.module === 'gift' && giftType) {
      if (this.guest?.giftList?.length) {
        const giftList = this.giftService.getGiftListById(this.guest.giftList);
        if (giftList?.length) {
          giftList?.forEach((gift: Gift) => {
            if (gift.giftType === giftType) {
              count++;
            }
          });
        }
      }
    }
    return count;
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList, true);
  }

  getBlastMsg(): BlastMsg {
    if (this.module === 'blast' && this.blastUsageId && (this.mode === 'sms-result' || this.mode === 'whatsapp-result')) {
      const blastMsgList: BlastMsg[] = this.blastMsgService.getBlastMsgListByGuestId(this.guest?.guestId, this.blastUsageId);
      return blastMsgList?.[0];
    }
    return null;
  }
  
  getBlastMsgDeliveredStatus(blastMsg: BlastMsg): BlastDeliveredType {
    if (blastMsg?.status) {
      if (blastMsg?.status?.read) {
        return 'read';
      } else if (blastMsg?.status?.delivered) {
        return 'delivered';
      } else if (blastMsg?.status?.failed) {
        return 'failed';
      } else if (blastMsg?.status?.sent) {
        return  'sent';
      }
    }
    return 'processing';
  }
  
  /**
   * Guest click
   * @param guest Guest
   */
  guestClick(guest: Guest) {
    if (this.module === 'blast' && (this.mode === 'sms' || this.mode === 'whatsapp') && !this.guest?.mobile?.no) {
      this.presentMobileInputModal(guest);
    } else if (this.module === 'blast' && this.mode === 'sms' && this.guest?.mobile?.no && this.guest?.mobile?.code && this.blastPricingService.getSmsCredit(this.guest.mobile?.country, this.guest.mobile.code) === -1) {
      // present mobile not supported
      this.presentMobileNotSupportModal(guest);
    } else if (this.module === 'blast' && this.mode === 'whatsapp' && this.guest?.mobile?.no && this.guest?.mobile?.code && this.blastPricingService.getWhatsAppCredit(this.guest.mobile?.country, this.guest.mobile.code) === -1) {
      // present mobile not supported
      this.presentMobileNotSupportModal(guest);
    } else if (this.mode === 'sms' && this.blastTemplate?.url && this.guest?.mobile?.code === 60) {
      this.presentMobileNotSupportModal(this.guest);
    } else {
      if (!this.disableClick && !this.selectMode && !this.editMode) {
        if (this.module === 'gift') {
          this.presentGiftGuestProfileModal(guest);
        } else if (this.module === 'trash') {
          this.presentGuestProfileModal(guest);
        } else if (this.module === 'checkin') {
          this.presentCheckinProfileModal(guest);
        } else if (this.mode === 'qrcode') {
          this.presentGuestQrcodeModal(guest);
        } else {
          this.presentGuestProfileModal(guest);
        }
      }
    }
  }

  /**
   * Present guest profile modal
   * @param guest Guest
   */
  async presentGuestProfileModal(guest: Guest) {
    const modal = await this.modalController.create({
      component: GuestProfileComponent,
      cssClass: '',
      componentProps: {
        guest,
        groupModal: this.groupModal
      }
    });
    modal.present();
  }

  /**
   * Present guest profile modal
   * @param guest Guest
   */
  async presentCheckinProfileModal(guest: Guest) {
    const modal = await this.modalController.create({
      component: CheckinProfileComponent,
      cssClass: '',
      componentProps: {
        guest,
        groupModal: this.groupModal
      }
    });
    modal.present();
  }

  /**
   * Present gift guest profile modal
   * @param guest guest
   */
  async presentGiftGuestProfileModal(guest: Guest) {
    if (guest) {
      const modal = await this.modalController.create({
        component: GiftGuestProfileComponent,
        cssClass: '',
        componentProps: {
          guest,
          groupModal: this.groupModal
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present Guest QR modal
   * @param guest guest
   * @param slidingItem sliding item
   */
  async presentGuestQrcodeModal(guest: Guest, slidingItem?: any) {
    if (guest) {
      const modal = await this.modalController.create({
        component: GuestQrcodeComponent,
        cssClass: '',
        componentProps: {
          guest,
          module: this.module
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present guest edit modal
   * @param guest guest
   * @param slidingItem sliding item to be close
   */
  async presentGuestEditModal(guest: Guest, slidingItem?: any) {
    if (guest) {
      if (this.checkPrivilege(this.module, 'edit')) {
        const modal = await this.modalController.create({
          component: GuestEditComponent,
          cssClass: '',
          componentProps: {
            guest
          }
        });
        modal.present();
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  async presentMobileNotSupportModal(guest: Guest, slidingItem?: any) {
    if (guest?.guestId) {
      if (this.module === 'blast' && this.checkPrivilege(this.module, 'send')) {
        const confirm = await this.popupService.presentConfirm(
          this.translate.instant('BLAST.msg.country_sms_not_support'),
          '',
          this.translate.instant('BLAST.msg.mobile_not_support'),
          '',
          this.translate.instant('BTN.ok'),
          this.translate.instant('CRUD.edit'),
          false, '', [], false, [], true
        );
        confirm.onDidDismiss().then((result: any) => {
          if (!result?.data?.confirm && !result?.data?.cancel) {
            this.presentMobileInputModal(guest);
          }
        });
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  async presentMobileInputModal(guest: Guest, slidingItem?: any) {
    if (guest?.guestId) {
      if (this.module === 'blast' && this.checkPrivilege(this.module, 'send')) {
        const modal = await this.modalController.create({
          component: MobileComponent,
          cssClass: 'modal-transparent',
          componentProps: {
            modalMode: true,
            required: true,
            mobile: guest?.mobile,
            lbl: this.translate.instant('MOBILE.lbl.no')
          }
        });
        modal.present();
        modal.onDidDismiss().then(async (result: any) => {
          if (result?.data?.mobile?.no) {
            if (guest?.mobile?.no !== result.data.mobile.no) {
              await this.popupService.presentLoading();
              await this.guestManageService.saveGuest({ mobile: result.data.mobile }, [ guest.guestId ]);
              this.popupService.dismissLoading();
              this.popupService.saveSuccessToast();
            }
          }
        });
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present seating list modal
   * @param guest Guest
   * @param assignMode assign mode
   * @param swapMode swap mode
   */
  async presentSeatingListModal(guest: Guest, assignMode: boolean, swapMode: boolean) {
    if (guest) {
      const modal = await this.modalController.create({
        component: SeatingListComponent,
        cssClass: '',
        componentProps: {
          assignMode,
          swapMode,
          newGuestList: [ guest ]
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete(guest?: Guest) {
    if (guest) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.translate.instant('GUEST.lbl.guest') })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGuest(guest);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt to confirm delete guest
   * @param guest guest
   */
  async promptRemove(guest?: Guest) {
    if (guest) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: this.translate.instant('GUEST.lbl.guest') })
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.removeGuest(guest);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt restore guest
   * @param guest guest
   * @param slidingItem sliding item
   */
  async promptRestore(guest?: Guest, slidingItem?: any) {
    if (guest?.guestId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field', {
          field: this.translate.instant('GUEST.lbl.guest')
        })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.saveRestoreGuest(guest);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Prompt to unassign guest
   * @param guest guest
   */
  async promptUnassign(guest?: Guest) {
    if (guest) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('SEATING.msg.confirm_unassign_seating', { seating: this.getSeatingTypeName() })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.saveUnassignSeating();
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptCheckin(guest: Guest, slidingItem: any) {
    if (guest?.guestId) {
      await this.checkinService.checkin('', [ guest.guestId ], [ guest ], null, null, false, false, this.groupModal ? true : false);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptUncheck(guest: Guest, slidingItem: any) {
    if (guest?.guestId) {
      await this.checkinService.uncheck([ guest.guestId ], [ guest ]);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present seating action sheet
   * @param guest guest
   */
  async presentSeatingActionSheet(guest: Guest) {
    if (guest?.seating) {
      const actionSheet = await this.actionSheetController.create({
        header: this.getSeatingTypeName(),
        buttons: [{
          text: this.translate.instant('SEATING.btn.move', { seating: this.getSeatingTypeName() }),
          handler: () => {
            this.presentSeatingListModal(guest, true, false);
          }
        }, {
          text: this.translate.instant('SEATING.btn.unassign', { seating: this.getSeatingTypeName() }),
          handler: () => {
            this.promptUnassign(guest);
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present group delete action sheet
   * @param guest Guest
   */
  async presentGroupDeleteActionSheet(guest: Guest) {
    if (guest?.guestId) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('GROUP.delete.guest_action_sheet.title'),
        buttons: [{
          text: this.translate.instant('GROUP.delete.guest_action_sheet.remove_group'),
          handler: () => {
            this.removeGuestFromGroup(guest);
          }
        }, {
          text: this.translate.instant('GROUP.delete.guest_action_sheet.delete_guest'),
          handler: () => {
            this.removeGuest(guest);
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Seating action. Assign seating if guest seating not assign yet, else present action sheet for more options.
   * @param guest guest
   * @param slidingItem sliding item
   */
  seating(guest?: Guest, slidingItem?: any) {
    if (guest && guest.seating) {
      this.presentSeatingActionSheet(guest);
    } else {
      this.presentSeatingListModal(guest, true, false);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Delete action. Prompt to delete guest for individual guest, else present action sheet for more options.
   * @param guest guest
   * @param slidingItem sliding item
   */
  deleteFunc(guest?: Guest, slidingItem?: any) {
    if (this.module === 'trash') {
      this.promptDelete(guest);
    } else {
      if (guest?.groupId) {
        this.presentGroupDeleteActionSheet(guest);
      } else {
        this.promptRemove(guest);
      }
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Remove guest from group
   * @param guest guest
   * @param skipLoading skip loading
   */
  async removeGuestFromGroup(guest: Guest, skipLoading?: boolean) {
    if (guest?.guestId && guest?.groupId) {
      if (!skipLoading) {
        await this.popupService.presentLoading();
      }
      await this.guestManageService.saveGuest({ groupId: '' }, [ guest.guestId ], '', [], true);
      if (!skipLoading) {
        this.popupService.dismissLoading();
        this.popupService.saveSuccessToast();
      }
    }
  }

  /**
   * Check sliding item
   */
  checkSlidingItem() {
    // if (type === 'edit') {
    //   if (this.module === 'blast') {
    //     if (!this.disableEdit && this.checkPrivilege(this.module, 'send')) {
    //       return true;
    //     }
    //   } else if (!this.disableEdit && this.checkPrivilege(this.module, 'edit')) {
    //     return true;
    //   }
    // } else if (type === 'delete') {
    //   if (!this.disableDelete && this.checkPrivilege(this.module, 'delete')) {
    //     return true;
    //   }
    // } else if (type === 'restore') {
    //   if (!this.disableRestore && this.checkPrivilege(this.module, 'restore')) {
    //     return true;
    //   }
    // } else if (type === 'qrcode') {
    //   if (!this.disableQrcode && this.checkPrivilege(this.module, 'qrcode')) {
    //     return true;
    //   }
    // } else if (type === 'seating') {
    //   if (!this.disableSeating && this.checkPrivilege(this.module, 'seating')) {
    //     return true;
    //   }
    // } else if (type === 'checkin') {
    //   if (!this.disableCheckin && this.checkPrivilege(this.module, 'checkin')) {
    //     return true;
    //   }
    // } else if (type === 'uncheck') {
    //   if (!this.disableUncheck && this.checkPrivilege(this.module, 'uncheck')) {
    //     return true;
    //   }
    // } 
    // return false;
    if (this.module === 'guest') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'edit')) {
        this.disableEdit = true;
      }
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
      if (!this.disableSeating && !this.checkPrivilege(this.module, 'seating')) {
        this.disableSeating = true;
      }
      if (!this.disableQrcode && !this.checkPrivilege(this.module, 'qrcode')) {
        this.disableQrcode = true;
      }
    } else if (this.module === 'checkin') {
      if (!this.disableCheckin && !this.checkPrivilege(this.module, 'checkin')) {
        this.disableCheckin = true;
      }
      if (!this.disableUncheck && !this.checkPrivilege(this.module, 'uncheck')) {
        this.disableUncheck = true;
      }
    } else if (this.module === 'gift') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'edit')) {
        this.disableEdit = true;
      }
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
    } else if (this.module === 'trash') {
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
      if (!this.disableRestore && !this.checkPrivilege(this.module, 'restore')) {
        this.disableRestore = true;
      }
    } else if (this.module === 'blast') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'send')) {
        this.disableEdit = true;
      }
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Remove guest from db
   */
  async deleteGuest(guest?: Guest) {
    if (guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.deleteGuestList([ guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save restore guest
   * @param guest guest
   */
  async saveRestoreGuest(guest?: Guest) {
    if (guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.restoreGuestList([ guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save delete guest
   * @param guest guest
   */
  async removeGuest(guest?: Guest) {
    if (guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.removeGuestList([ guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save unassign seating
   */
  async saveUnassignSeating() {
    if (this.guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestManageService.saveGuest({ seating: ''}, [ this.guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

}
