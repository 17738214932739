import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';
import { LocaleToSubset } from 'src/app/commons/locale';
import { WebsiteFontList } from 'src/app/commons/websiteFonts';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteFontService {

  websiteFontList = WebsiteFontList;

  constructor(
    private functionService: FunctionService,
  ) { }

  getSubsetFromLocale(locale: string) {
    return LocaleToSubset?.[locale] || 'latin'; // Default to 'latin' if locale is not found
  }

  async retrieveWebFonts(language?: string) {
    try {
      let url = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg&sort=popularity';
      if (language) {
        url = url + '&subset=' + this.getSubsetFromLocale(language);
      }
      const options: HttpOptions = {
        url,
      };
      const response: HttpResponse = await CapacitorHttp.get(options);
      console.log(response);
      if (response?.status === 200 && response?.data?.items?.length) {
        return response.data.items;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getWebFontList(locale?: string, keyword?: string) {
    let websiteFontList = this.functionService.cloneDeep(this.websiteFontList);
    if (locale) {
      const subset = this.getSubsetFromLocale(locale);
      return websiteFontList.filter((font: any) => font.subsets.includes(subset));
    }
    return websiteFontList;
  }

  getDefaultFont(locale?: string) {
    return this.getWebFontList(locale)?.[0]?.family;
  }
}
