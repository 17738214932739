import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftLogService } from 'src/app/services/gift/gift-log.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { Gift } from 'src/app/interfaces/gift';
import { Log } from 'src/app/interfaces/log';
import { Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DateTimeService } from 'src/app/services/general/date-time.service';

@Component({
  selector: 'app-gift-log',
  templateUrl: './gift-log.component.html',
  styleUrls: ['./gift-log.component.scss'],
})
export class GiftLogComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Guest log type
   */
  type: string;
  /**
   * Log list
   */
  logList: Log[];
  /**
   * gift ID
   */
  giftId: string;

  giftLogSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private accountUserService: AccountUserService,
    private giftLogService: GiftLogService,
    private giftService: GiftService,
    private dateTimeService: DateTimeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatchGiftLog();
  }

  /**
   * After view enter
   */
  ionViewWillEnter() {
    this.watchGiftLog();
  }

  ionViewWillLeave() {
    this.unwatchGiftLog();
  }

  /**
   * Get gift log
   */
  async watchGiftLog() {
    await this.popupService.presentLoading();
    if (this.giftId) {
      this.giftLogSubscription = this.giftLogService.getGiftLog(this.giftId, this.type).subscribe((logList: Log[]) => {
        this.logList = logList.sort((a: Log, b: Log) => {
          return this.functionService.compare(a?.by?.time?.seconds, b?.by?.time?.seconds, true);
        });
        this.setupViewport();
        this.dismissLoading();
      });
    } else {
      this.dismissLoading();
    }
  }

  async unwatchGiftLog() {
    if (this.giftLogSubscription) {
      this.giftLogSubscription.unsubscribe();
      this.giftLogSubscription = null;
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Dismiss loading
   */
  dismissLoading() {
    setTimeout(() => {
      this.popupService.dismissLoading();
    }, 500);
  }

  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    }
    return '';
  }

  /**
   * Get gift by gift id
   * @param giftId gift ID
   * @returns gift obj
   */
  getGiftById(giftId: string): Gift {
    return this.giftService.getGiftById(giftId);
  }

  format(timestamp: number, dateTimeFormat: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Track item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Log) {
    if (item?.logId) {
      return item.logId;
    }
    return index;
  }

}
