import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IonicSafeString, LoadingController, ModalController, ToastController } from '@ionic/angular';

import { AlertComponent } from 'src/app/components/general/prompt/alert/alert.component';
import { InputComponent } from 'src/app/components/general/prompt/input/input.component';
import { ConfirmComponent } from 'src/app/components/general/prompt/confirm/confirm.component';
import { Guest } from 'src/app/interfaces/guest';
import { Gift } from 'src/app/interfaces/gift';

/**
 * Popup service.
 * For toast msg, loading modal, confirm modal, alert modal and input modal
 */
@Injectable({
  providedIn: 'root'
})
export class PopupService implements OnInit, OnDestroy {

  /**
   * Toast variable
   */
  private toast: HTMLIonToastElement;
  /**
   * Loading variable
   */
  private loading: HTMLIonLoadingElement;

  /**
   * Constructor
   * @param loadingController Loading Controller
   * @param modalController Modal Controller
   * @param toastController Toast Controller
   */
  constructor(
    private translate: TranslateService,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private toastController: ToastController
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Present Loading
   * @param message Message
   * @param spinner Spinner
   * @param showBackdrop Show back drop
   * @param backdropDismiss Backdrop dismiss
   * @param translucent Translucent
   */
  async presentLoading(message?: string | IonicSafeString, spinner?: any, hideBackdrop?: boolean, backdropDismiss?: boolean, translucent?: boolean, cssClass?: string) {
    let showBackdrop = true;
    if (!message) { message = ''; }
    if (!spinner) {
      spinner = '';
      message = '<ion-img class="spinner-custom center" src="./assets/wedding/icon/animated/ellipsis.svg"></ion-img><div class="center" *ngIf="message">' + message + '</div>';
    }
    if (hideBackdrop) { showBackdrop = false; }
    if (!backdropDismiss) { backdropDismiss = false; }
    if (!translucent) { translucent = false; }
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        spinner,
        duration: 0,
        message,
        showBackdrop,
        backdropDismiss,
        translucent,
        cssClass,
      });
      this.loading.present();
    }
  }

  /**
   * Dismiss Loading
   */
  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  /**
   * Save success toast
   */
  async saveSuccessToast() {
    this.presentToast(this.translate.instant('MSG.save_success'), 'success');
  }

  /**
   * Save fail toast
   */
  async saveFailToast() {
    this.presentToast(this.translate.instant('MSG.save_fail'), 'danger');
  }

  /**
   * Present action error
   */
  async presentActionError() {
    await this.dismissLoading();
    this.presentAlert(this.translate.instant('MSG.action_error'));
  }

  /**
   * Present alert modal
   * @param title Title
   * @param msg Message
   * @param subtitle Subtitle
   * @param btn Btn
   * @returns alert modal
   */
  async presentAlert(msg: string, title?: string, subtitle?: string, btn?: any, previewGuestList?: Guest[], showGuestSeating?: boolean, hideWarning?: boolean) {
    const modal = await this.modalController.create({
        component: AlertComponent,
        cssClass: 'modal-transparent',
        componentProps: {
          type: 'warning',
          msg,
          title,
          subtitle,
          btn,
          showGuestSeating,
          previewGuestList,
          hideWarning
        }
    });
    await modal.present();
    return modal;
  }

  /**
   * Present confirm modal
   * @param msg display msg
   * @param title display title
   * @param subtitle display subtitle
   * @param btn button name
   * @returns confirm modal
   */
  async presentConfirm(msg: string, type?: string, title?: string, subtitle?: string,
                       yesBtnText?: string, noBtnText?: string, cancelBtn?: boolean,
                       cancelBtnText?: string, previewGuestList?: Guest[], showGuestSeating?: boolean, previewGiftList?: Gift[], hideIcon?: boolean) {
    const modal = await this.modalController.create({
      component: ConfirmComponent,
      cssClass: 'modal-transparent',
      componentProps: {
        type,
        msg,
        title,
        subtitle,
        yesBtnText,
        noBtnText,
        cancelBtn,
        cancelBtnText,
        showGuestSeating,
        previewGuestList,
        previewGiftList,
        hideIcon
      }
    });
    await this.dismissLoading();
    await modal.present();
    return modal;
  }

  /**
   * Present input modal
   * @param title display title
   * @param value current value
   * @param optional optional
   * @param type type
   * @param name name
   * @returns input modal
   */
  async presentInput(
    title?: string,
    subtitle?: string,
    msg?: string,
    img?: string,
    value?: any,
    optional?: boolean,
    type?: string,
    name?: string,
    confirmBtnTxt?: string,
    cancelBtnTxt?: string,
    placeholder?: string,
  ) {
    if (!type) { type = 'text'; }
    if (!name) { name = 'name'; }
    const modal = await this.modalController.create({
      component: InputComponent,
      cssClass: 'modal-transparent',
      componentProps: {
        title,
        subtitle,
        msg,
        img,
        value,
        optional,
        type,
        name,
        confirmBtnTxt,
        cancelBtnTxt,
        placeholder
      }
    });
    await modal.present();
    return modal;
  }

  /**
   * Present toast msg
   * @param message Message
   * @param color color
   * @param close close
   * @param duration duration
   * @param position position
   * @returns toast controller
   */
  async presentToast(message?: string, color?: string, close?: boolean, duration?: number, position?: any, skipThis?: boolean) {
    await this.dismissToast();

    if (!duration && duration !== 0) { duration = 2000; }
    if (close === false) { close = false; }
    else { close = true; }
    if (!color) { color = 'primary'; }
    if (!position) { position = 'bottom'; }

    const buttons: any = [];
    if (close) {
      buttons.push({
        side: 'end',
        icon: 'close-outline',
        role: 'cancel',
        handler: () => {
          if (this.toast) { this.toast.dismiss(); }
        }
      });
    }
    duration = 0;
    const toast = await this.toastController.create({
      message,
      duration,
      color,
      position,
      buttons
    });
    if (!skipThis) {
      this.toast = toast;
    }
    await toast.present();
    return toast;
  }

  /**
   * Dismiss Toast
   */
  async dismissToast() {
    if (this.toast) {
      await this.toast.dismiss();
      this.toast = null;
    } else {
      let toast: any;
      do {
        if (toast) { await toast?.dimiss(); }
        toast = await this.toastController?.getTop();
      } while (toast);
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ dismiss }); }
    }
  }

  async dismissAllModal() {
    if (this.modalController) {
      let modal: any;
      do {
        if (modal) { await this.dismissModal(); }
        modal = await this.modalController.getTop();
      } while (modal);
    }
  }
}
