import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';
import { BlastMsg, BlastTemplate, BlastUsage, BlastUsageDetail } from 'src/app/interfaces/blast';
import { BlastPricingService } from './blast-pricing.service';
import { BlastTemplateService } from './blast-template.service';
import { UpdateByService } from '../user/update-by.service';
import { ErrorService } from '../general/error.service';
import { FunctionService } from '../general/function.service';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { BlastType } from 'src/app/types/blast';
import { AccountInfoService } from '../account/account-info.service';

@Injectable({
  providedIn: 'root'
})
export class BlastUsageService implements OnInit, OnDestroy {

  blastUsageList: BlastUsage[];
  observableBlastUsage: any;

  private accountId: string;
  private usageSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private accountInfoService: AccountInfoService,
    private blastPricingService: BlastPricingService,
    private blastTemplateService: BlastTemplateService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.blastUsageList = [];
    this.observableBlastUsage = new BehaviorSubject<BlastUsage[]>(this.blastUsageList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUsage();
  }

  async setupAccountId(accountId: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchUsage();
    } else {
      await this.unwatchUsage();
    }
  }

  async watchUsage() {
    if (this.accountId) {
      if (!this.usageSubscription) {
        this.usageSubscription = this.afs.collection(`accounts/${ this.accountId }/blast/msg/blastUsage/`,
        ref => ref.where('enable', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data = a.payload.doc.data();
          return data;
        }).sort((a: BlastUsage, b: BlastUsage) => {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
        }))).subscribe({
          next: (blastUsageList: BlastUsage[]) => {
            this.blastUsageList = blastUsageList;
            this.observableBlastUsage.next(this.blastUsageList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchUsage();
      }, 500);
    }
  }

  async unwatchUsage() {
    if (this.usageSubscription) {
      this.usageSubscription.unsubscribe();
      this.usageSubscription = null;
      // this.blastUsageList = [];
      // this.observableBlastUsage.next(this.blastUsageList);
    }
  }

  getBlastUsage(usageId: string): BlastUsage {
    if (usageId) {
      const index = this.blastUsageList.findIndex((blastUsage: BlastUsage) => {
        return blastUsage?.usageId === usageId;
      });
      return this.blastUsageList?.[index];
    }
    return null;
  }

  estimateDeliveryTime(blastQty: number) {
    const msgPerMin = 30;
    const jobInterval = 5;
    const maxBufferTimes = 10;
    const mins = Math.round(Math.ceil(blastQty > msgPerMin ? (blastQty / msgPerMin) : jobInterval) / jobInterval) * jobInterval;
    return {
      min: mins ? mins : jobInterval,
      max: mins ? (mins * maxBufferTimes) : (jobInterval * maxBufferTimes),
    }
  }

  calculateTotalBlast(blastUsageDetailList: BlastUsageDetail[]) {
    return blastUsageDetailList?.reduce((a: number, b: BlastUsageDetail) => {
      return Number((a + b.qty).toFixed(0));
    }, 0);
  }

  calculateBlastUsageDetailList(blastType: BlastType, guestList: Guest[], groupList: Group[], blastTemplate: BlastTemplate) {
    const blastUsageDetailList: BlastUsageDetail[] =  [];
    const blastMsgList: BlastMsg[] = [];
    const createBy = this.updateByService.updateBy;
    const langCode = !blastTemplate?.language?.custom && blastTemplate?.language?.value ? blastTemplate.language.value : '';

    if (blastTemplate?.templateTitle === '[WEDDING_TITLE]' && this.accountInfoService.accountTitle) {
      blastTemplate.templateTitle = this.accountInfoService.accountTitle;
    }
    guestList?.forEach((guest: Guest) => {
      if (guest?.mobile?.no && guest?.mobile?.code) {
        const { blastCount, content } = this.blastTemplateService.setupBlastContent(
          blastType,
          { ...blastTemplate },
          langCode,
          guest.mobile.code,
          guest,
          null,
        );
        const blastCredit = this.blastPricingService.getBlastCredit(blastType, guest.mobile?.country, guest.mobile.code);
        const index = blastUsageDetailList.findIndex((x: BlastUsageDetail) => (x.phone === guest.mobile.code || (x.country && x.country === guest.mobile.country)));
        if (index !== -1) {
          blastUsageDetailList[index].qty += blastCount;
        } else {
          const msgUsageDetail: BlastUsageDetail = {
            phone: guest.mobile.code,
            country: guest.mobile?.country ? guest.mobile.country : '',
            blastCredit,
            blastType,
            qty: blastCount
          };
          if (msgUsageDetail?.blastCredit > 0) {
            blastUsageDetailList.push(msgUsageDetail);
          }
        }
        const blastMsg = this.blastTemplateService.generateBlastMsg(
          blastType, blastTemplate?.templateId, blastTemplate.templateTitle, blastTemplate.templateType, content, guest.mobile, '', blastCount, blastCredit, createBy, guest.guestId, ''
        );
        blastMsgList.push(blastMsg);
      }
    });
    groupList?.forEach((group: Group) => {
      if (group?.mobile?.no && group?.mobile?.code) {
        const { blastCount, content } = this.blastTemplateService.setupBlastContent(
          blastType,
          { ...blastTemplate },
          langCode,
          group.mobile.code,
          null,
          group,
        );
        const blastCredit = this.blastPricingService.getBlastCredit(blastType, group.mobile?.country, group.mobile.code);
        const index = blastUsageDetailList.findIndex((x: BlastUsageDetail) => x.phone === group.mobile.code || (x.country && x.country === group.mobile.country));
        if (index !== -1) {
          blastUsageDetailList[index].qty += blastCount;
        } else {
          const msgUsageDetail: BlastUsageDetail = {
            phone: group.mobile.code,
            country: group.mobile?.country ? group.mobile.country : '',
            blastCredit: this.blastPricingService.getBlastCredit(blastType, group.mobile?.country, group.mobile.code),
            blastType,
            qty: blastCount
          };
          if (msgUsageDetail?.blastCredit > 0) {
            blastUsageDetailList.push(msgUsageDetail);
          }
        }
        const blastMsg = this.blastTemplateService.generateBlastMsg(
          blastType, blastTemplate?.templateId, blastTemplate.templateTitle, blastTemplate.templateType, content, group.mobile, '', blastCount, blastCredit, createBy, '', group.groupId
        );
        blastMsgList.push(blastMsg);
      }
    });
    
    return { blastUsageDetailList, blastMsgList };
  }

  getTotalUsedBlastCredit(): number {
    return this.blastUsageList?.reduce((a: number, b: BlastUsage) => {
      return a + (b?.blastCredit ? b.blastCredit : 0); 
    }, 0);
  }
}
