import { TrashGuard } from './guards/trash/trash.guard';
import { GiftGuard } from './guards/gift/gift.guard';
import { CheckinGuard } from './guards/checkin/checkin.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

import { UserGuard } from './guards/user/user.guard';
import { GuestGuard } from './guards/guest/guest.guard';
import { AccountGuard } from './guards/account/account.guard';

/**
 * Redirect unauthorize access to root
 */
const redirectUnauthorizedToRoot = () => redirectUnauthorizedTo(['main']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['main']);
/**
 * Defined URL path for each page with can activate guard
 */
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'inbox',
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/inbox/inbox-home/inbox-home.module').then( m => m.InboxHomePageModule)
    },
    {
      path: 'home/:inboxType',
      loadChildren: () => import('./pages/inbox/inbox-home/inbox-home.module').then( m => m.InboxHomePageModule)
    },
    {
      path: 'home/:inboxType/:inboxId',
      loadChildren: () => import('./pages/inbox/inbox-home/inbox-home.module').then( m => m.InboxHomePageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/inbox/inbox-home/inbox-home.module').then( m => m.InboxHomePageModule)
    }]
  },
  {
    path: 'user',
    children: [{
      path: 'logout',
      loadChildren: () => import('./pages/user/user-logout/user-logout.module').then( m => m.UserLogoutPageModule),
    },
    {
      path: 'profile',
      loadChildren: () => import('./pages/user/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    },
    {
      path: 'referral',
      loadChildren: () => import('./pages/user/user-referral/user-referral.module').then( m => m.UserReferralPageModule),
    },
    {
      path: 'delete',
      loadChildren: () => import('./pages/user/user-delete/user-delete.module').then( m => m.UserDeletePageModule),
    },
    {
      path: 'credit',
      loadChildren: () => import('./pages/user/user-credit/user-credit.module').then( m => m.UserCreditPageModule),
    },
    {
      path: 'transaction',
      loadChildren: () => import('./pages/user/user-transaction/user-transaction.module').then( m => m.UserTransactionPageModule),
    },
    {
      path: 'transaction/:transactionId',
      loadChildren: () => import('./pages/user/user-transaction/user-transaction.module').then( m => m.UserTransactionPageModule),
    },
    {
      path: '',
      redirectTo: 'profile',
      pathMatch: 'full'
    }]
  },
  {
    path: 'accounts',
    children: [{
      path: 'list',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-list/accounts-list.module').then( m => m.AccountsListPageModule)
    },
    {
      path: 'list/:searchMode',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-list/accounts-list.module').then( m => m.AccountsListPageModule)
    },
    {
      path: 'login',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-login/accounts-login.module').then( m => m.AccountsLoginPageModule)
    },
    {
      path: 'logout',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-logout/accounts-logout.module').then( m => m.AccountsLogoutPageModule)
    },
    {
      path: 'add',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-add/accounts-add.module').then( m => m.AccountsAddPageModule)
    },
    {
      path: 'add/:accountAddType',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-add/accounts-add.module').then( m => m.AccountsAddPageModule)
    },
    {
      path: 'scan',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-scan/accounts-scan.module').then( m => m.AccountsScanPageModule)
    },
    {
      path: 'setup',
      canActivate: [ AngularFireAuthGuard, UserGuard ],
      data: {
        authGuardPipe: redirectUnauthorizedToRoot,
      },
      loadChildren: () => import('./pages/accounts/accounts-setup/accounts-setup.module').then( m => m.AccountsSetupPageModule)
    },
    {
      path: 'login-preview',
      loadChildren: () =>
      import('./pages/link/accounts-login-preview/accounts-login-preview.module').then( m => m.AccountsLoginPreviewPageModule)
    },
    {
      path: 'preview-login',
      loadChildren: () =>
      import('./pages/link/accounts-login-preview/accounts-login-preview.module').then( m => m.AccountsLoginPreviewPageModule)
    },
    {
      path: 'migrate',
      loadChildren: () => import('./pages/link/accounts-migrate/accounts-migrate.module').then( m => m.AccountsMigratePageModule)
    },
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full'
    }]
  },
  {
    path: 'account',
    canActivate: [ AngularFireAuthGuard, UserGuard ],
    data: {
      authGuardPipe: redirectUnauthorizedToRoot,
    },
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/account/account-home/account-home.module').then( m => m.AccountHomePageModule)
    },
    {
      path: 'setting',
      loadChildren: () => import('./pages/account/account-setting/account-setting.module').then( m => m.AccountSettingPageModule)
    },
    {
      path: 'setting/:settingType',
      loadChildren: () => import('./pages/account/account-setting/account-setting.module').then( m => m.AccountSettingPageModule)
    },
    {
      path: 'accountTask',
      loadChildren: () => import('./pages/account/account-task/account-task.module').then( m => m.AccountTaskPageModule)
    },
    {
      path: 'detail',
      loadChildren: () => import('./pages/account/account-detail/account-detail.module').then( m => m.AccountDetailPageModule)
    },
    {
      path: 'detail/:detailType',
      loadChildren: () => import('./pages/account/account-detail/account-detail.module').then( m => m.AccountDetailPageModule)
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    }]
  },
  {
    path: 'guest',
    canActivate: [ AngularFireAuthGuard, UserGuard ],
    data: {
      authGuardPipe: redirectUnauthorizedToRoot,
    },
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/guest/guest-home/guest-home.module').then( m => m.GuestHomePageModule)
    },
    {
      path: 'list',
      loadChildren: () => import('./pages/guest/guest-list/guest-list.module').then( m => m.GuestListPageModule)
    },
    {
      path: 'new',
      loadChildren: () => import('./pages/guest/guest-new/guest-new.module').then( m => m.GuestNewPageModule)
    },
    {
      path: 'qrcode',
      loadChildren: () => import('./pages/guest/guest-qrcode/guest-qrcode.module').then( m => m.GuestQrcodePageModule)
    },
    {
      path: 'seating',
      loadChildren: () => import('./pages/guest/guest-seating/guest-seating.module').then( m => m.GuestSeatPageModule)
    }]
  },
  {
    path: 'checkin',
    canActivate: [ AngularFireAuthGuard, UserGuard ],
    data: {
      authGuardPipe: redirectUnauthorizedToRoot,
    },
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/checkin/checkin-home/checkin-home.module').then( m => m.CheckinHomePageModule)
    },
    {
      path: 'list',
      loadChildren: () => import('./pages/checkin/checkin-list/checkin-list.module').then( m => m.CheckinListPageModule)
    },
    {
      path: 'scan',
      loadChildren: () => import('./pages/checkin/checkin-scan/checkin-scan.module').then( m => m.CheckinScanPageModule)
    },
    {
      path: 'seating',
      loadChildren: () => import('./pages/checkin/checkin-seating/checkin-seating.module').then( m => m.CheckinSeatingPageModule)
    },
    {
      path: 'new',
      loadChildren: () => import('./pages/checkin/checkin-new/checkin-new.module').then( m => m.CheckinNewPageModule)
    }]
  },
  {
    path: 'gift',
    canActivate: [ AngularFireAuthGuard, UserGuard ],
    data: {
      authGuardPipe: redirectUnauthorizedToRoot,
    },
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/gift/gift-home/gift-home.module').then( m => m.GiftHomePageModule)
    },
    {
      path: 'list',
      loadChildren: () => import('./pages/gift/gift-list/gift-list.module').then( m => m.GiftListPageModule)
    },
    {
      path: 'guest',
      loadChildren: () => import('./pages/gift/gift-guest/gift-guest.module').then( m => m.GiftGuestPageModule)
    },
    {
      path: 'seating',
      loadChildren: () => import('./pages/gift/gift-seating/gift-seating.module').then( m => m.GiftSeatingPageModule)
    },
    {
      path: 'new',
      loadChildren: () => import('./pages/gift/gift-new/gift-new.module').then( m => m.GiftNewPageModule)
    }]
  },
  {
    path: 'subscription',
    children: [{
      path: 'account-comparison',
      loadChildren: () =>
      import('./pages/subscription/accounts-comparison/product-account-comparison.module').then( m => m.ProductAccountComparisonPageModule)
    },
    {
      path: 'trial',
      loadChildren: () => import('./pages/subscription/accounts-trial/accounts-trial.module').then( m => m.AccountsTrialPageModule)
    },
    {
      path: 'premium',
      loadChildren: () => import('./pages/subscription/accounts-premium/accounts-premium.module').then( m => m.AccountsPremiumPageModule)
    },
    {
      path: 'premium/:upgradeTrial',
      loadChildren: () => import('./pages/subscription/accounts-premium/accounts-premium.module').then( m => m.AccountsPremiumPageModule)
    },
    {
      path: 'premium/:upgradeTrial/:voucherId',
      loadChildren: () => import('./pages/subscription/accounts-premium/accounts-premium.module').then( m => m.AccountsPremiumPageModule)
    },
    {
      path: 'premium/:upgradeTrial/:voucherId/:referralCode',
      loadChildren: () => import('./pages/subscription/accounts-premium/accounts-premium.module').then( m => m.AccountsPremiumPageModule)
    },
    {
      path: 'account',
      loadChildren: () => import('./pages/subscription/accounts/product-account.module').then( m => m.ProductAccountPageModule)
    },
    {
      path: 'account/:productId',
      loadChildren: () => import('./pages/subscription/accounts/product-account.module').then( m => m.ProductAccountPageModule)
    },
    {
      path: 'account/:productId/:voucherId',
      loadChildren: () => import('./pages/subscription/accounts/product-account.module').then( m => m.ProductAccountPageModule)
    },
    {
      path: 'account/:productId/:voucherId/:referralCode',
      loadChildren: () => import('./pages/subscription/accounts/product-account.module').then( m => m.ProductAccountPageModule)
    },
    {
      path: 'account/:productId/:voucherId/:referralCode/:giftMode',
      loadChildren: () => import('./pages/subscription/accounts/product-account.module').then( m => m.ProductAccountPageModule)
    },
    {
      path: 'payment-result',
      loadChildren: () => import('./pages/subscription/payment-result/payment-result.module').then( m => m.PaymentResultPageModule)
    },
    {
      path: 'payment-result/:checkout/:result',
      loadChildren: () => import('./pages/subscription/payment-result/payment-result.module').then( m => m.PaymentResultPageModule)
    },
    {
      path: 'payment-result/:checkout/:result/:uid/:creditId/:platform/:upgrade/:trial',
      loadChildren: () => import('./pages/subscription/payment-result/payment-result.module').then( m => m.PaymentResultPageModule)
    },
    {
      path: 'payment-result/:checkout/:result/:uid/:creditId/:platform/:upgrade/:trial/:accountId',
      loadChildren: () => import('./pages/subscription/payment-result/payment-result.module').then( m => m.PaymentResultPageModule)
    }]
  }, {
    path: 'links',
    children: [{
      path: ':type/:data',
      loadChildren: () => import('./pages/link/links/links.module').then( m => m.LinksPageModule)
    },
    {
      path: ':type/:data/:data2',
      loadChildren: () => import('./pages/link/links/links.module').then( m => m.LinksPageModule)
    },
    {
      path: ':type/:data/:data2/:data3',
      loadChildren: () => import('./pages/link/links/links.module').then( m => m.LinksPageModule)
    },
    {
      path: ':type/:data/:data2/:data3/:data4',
      loadChildren: () => import('./pages/link/links/links.module').then( m => m.LinksPageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/link/links/links.module').then( m => m.LinksPageModule)
    }]
  },
  {
    path: 'trash',
    canActivate: [ AngularFireAuthGuard, UserGuard ],
    data: {
      authGuardPipe: redirectUnauthorizedToRoot,
    },
    children: [{
      path: 'guest',
      loadChildren: () => import('src/app/pages/guest/guest-list/guest-list.module').then( m => m.GuestListPageModule)
    },
    {
      path: 'gift',
      loadChildren: () => import('src/app/pages/gift/gift-list/gift-list.module').then( m => m.GiftListPageModule)
    },
    {
      path: '',
      loadChildren: () => import('src/app/pages/guest/guest-list/guest-list.module').then( m => m.GuestListPageModule)
    }]
  }, {
    path: 'about',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/about/about-home/about-home.module').then( m => m.AboutHomePageModule)
      },
      {
        path: 'company',
        loadChildren: () => import('./pages/about/company/company.module').then( m => m.CompanyPageModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./pages/about/help/help.module').then( m => m.HelpPageModule)
      },
      {
        path: 'privacy',
        loadChildren: () => import('./pages/about/privacy/privacy.module').then( m => m.PrivacyPageModule)
      },
      {
        path: 'data-protection',
        loadChildren: () => import('./pages/about/data-protection/data-protection.module').then( m => m.DataProtectionPageModule)
      },
      {
        path: 'term',
        loadChildren: () => import('./pages/about/term/term.module').then( m => m.TermPageModule)
      },
      {
        path: 'aup',
        loadChildren: () => import('./pages/about/aup/aup.module').then( m => m.AupPageModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/about/contact/contact.module').then( m => m.ContactPageModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/about/support/support.module').then( m => m.SupportPageModule)
      },
      {
        path: 'support/:ticketType',
        loadChildren: () => import('./pages/about/support/support.module').then( m => m.SupportPageModule)
      },
      {
        path: 'app-update',
        loadChildren: () => import('./pages/about/app-update/app-update.module').then( m => m.AppUpdatePageModule)
      },
      {
        path: 'app-download',
        loadChildren: () => import('./pages/about/app-download/app-download.module').then( m => m.AppDownloadPageModule)
      },
      {
        path: 'app-download/:showPage',
        loadChildren: () => import('./pages/about/app-download/app-download.module').then( m => m.AppDownloadPageModule)
      },
      {
        path: 'app-rating',
        loadChildren: () => import('./pages/about/app-rating/app-rating.module').then( m => m.AppRatingPageModule)
      },
      {
        path: 'app-rating/:showPage',
        loadChildren: () => import('./pages/about/app-rating/app-rating.module').then( m => m.AppRatingPageModule)
      },
      {
        path: 'app-rating/:showPage/:promptRating',
        loadChildren: () => import('./pages/about/app-rating/app-rating.module').then( m => m.AppRatingPageModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/about/about-home/about-home.module').then( m => m.AboutHomePageModule)
      }
    ]
  },
  {
    path: 'gift-card',
    loadChildren: () => import('./pages/link/gift-card/gift-card.module').then( m => m.GiftCardPageModule)
  },
  {
    path: 'vip',
    children: [{
      path: ':accountId/:guestId/:groupId',
      loadChildren: () => import('./pages/visitor/visitor-home/visitor-home.module').then( m => m.VisitorHomePageModule)
    },
    {
      path: ':accountId/:guestId',
      loadChildren: () => import('./pages/visitor/visitor-home/visitor-home.module').then( m => m.VisitorHomePageModule)
    },
    {
      path: ':accountId',
      loadChildren: () => import('./pages/visitor/visitor-home/visitor-home.module').then( m => m.VisitorHomePageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/visitor/visitor-home/visitor-home.module').then( m => m.VisitorHomePageModule)
    }]
  },
  {
    path: 'vips',
    children: [{
        path: ':accountId/:guestId/:groupId',
        loadChildren: () => import('./pages/vips/vips-home/vips-home.module').then( m => m.VipsHomePageModule)
    },
    {
      path: ':accountId/:guestId',
      loadChildren: () => import('./pages/vips/vips-home/vips-home.module').then( m => m.VipsHomePageModule)
    },
    {
      path: ':accountId',
      loadChildren: () => import('./pages/vips/vips-home/vips-home.module').then( m => m.VipsHomePageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/vips/vips-home/vips-home.module').then( m => m.VipsHomePageModule)
    }]
  },
  {
    path: 'blast',
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/blast/blast-home/blast-home.module').then( m => m.BlastHomePageModule)
    },
    {
      path: 'notice',
      loadChildren: () => import('./pages/blast/blast-notice/blast-notice.module').then( m => m.BlastMessagesHomeModule)
    },
    {
      path: 'package',
      loadChildren: () => import('./pages/blast/blast-package/blast-package.module').then( m => m.BlastPackagePageModule)
    },
    {
      path: 'launching',
      loadChildren: () => import('./pages/blast/blast-launching/blast-launching.module').then( m => m.BlastLaunchingPageModule)
    },
    {
      path: 'result',
      loadChildren: () => import('./pages/blast/blast-result/blast-result.module').then( m => m.BlastResultPageModule)
    }]
  },
  {
    path: 'chat',
    children: [
      {
        path: 'ticket-detail',
        loadChildren: () => import('./pages/chat/ticket-detail/ticket-detail.module').then( m => m.TicketDetailPageModule)
      },
      {
        path: 'ticket-detail/:ticketId',
        loadChildren: () => import('./pages/chat/ticket-detail/ticket-detail.module').then( m => m.TicketDetailPageModule)
      }]
  },
  {
    path: 'business',
    children: [{
      path: 'register',
      loadChildren: () => import('./pages/business/business-register/business-register.module').then( m => m.BusinessRegisterPageModule)
    }]
  },
  {
    path: 'event/add',
    loadChildren: () => import('./pages/accounts/accounts-add/accounts-add.module').then( m => m.AccountsAddPageModule)
  },
  {
    path: 'event/add/:accountAddType',
    loadChildren: () => import('./pages/accounts/accounts-add/accounts-add.module').then( m => m.AccountsAddPageModule)
  },
  {
    path: 'payment',
    children: [{
      path: 'stripe-checkout',
      loadChildren: () => import('./pages/payment/stripe-checkout/stripe-checkout.module').then( m => m.StripeCheckoutPageModule)
    },
    {
      path: 'stripe-status',
      loadChildren: () => import('./pages/payment/stripe-status/stripe-status.module').then( m => m.StripeStatusPageModule)
    },
    {
      path: 'stripe-status/:sessionId/:sessionUid/:currency/:debugMode/:platformType',
      loadChildren: () => import('./pages/payment/stripe-status/stripe-status.module').then( m => m.StripeStatusPageModule)
    },
    {
      path: 'stripe-status/:sessionId/:sessionUid/:currency/:debugMode/:platformType/:cancelCheckout',
      loadChildren: () => import('./pages/payment/stripe-status/stripe-status.module').then( m => m.StripeStatusPageModule)
    },
    {
      path: 'stripe-result',
      loadChildren: () => import('./pages/payment/stripe-result/stripe-result.module').then( m => m.StripeResultPageModule)
    },
    {
      path: 'stripe-result/:status',
      loadChildren: () => import('./pages/payment/stripe-result/stripe-result.module').then( m => m.StripeResultPageModule)
    },
    {
      path: 'stripe-result/:status/:sessionId/:currency/:debugMode/:platform',
      loadChildren: () => import('./pages/payment/stripe-result/stripe-result.module').then( m => m.StripeResultPageModule)
    }]
  },
  {
    path: 'website',
    children: [{
        path: ':accountId/:linkId/:guestId/:groupId',
        loadChildren: () => import('./pages/website/website.module').then( m => m.WebsitePageModule)
    },
    {
      path: ':accountId/:linkId/:guestId',
      loadChildren: () => import('./pages/website/website.module').then( m => m.WebsitePageModule)
    },
    {
      path: ':accountId/:linkId',
      loadChildren: () => import('./pages/website/website.module').then( m => m.WebsitePageModule)
    },
    {
      path: ':accountId',
      loadChildren: () => import('./pages/website/website.module').then( m => m.WebsitePageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/website/website.module').then( m => m.WebsitePageModule)
    }]
  },
  {
    path: 'website-setting',
    children: [{
      path: 'home',
      loadChildren: () => import('./pages/website-setting/website-setting-home/website-setting-home.module').then( m => m.WebsiteSettingHomePageModule)
    },
    {
      path: '',
      loadChildren: () => import('./pages/website-setting/website-setting-home/website-setting-home.module').then( m => m.WebsiteSettingHomePageModule)
    }]
  },
  {
    path: '**',
    redirectTo: '/main'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
