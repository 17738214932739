import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteGallery } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteGalleryService implements OnInit, OnDestroy {

  accountId: string;

  websiteGallery: WebsiteGallery;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteGallery>(this.websiteGallery);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteGallery: WebsiteGallery) {
    if (!this.functionService.isEqual(this.websiteGallery, websiteGallery)) {
      this.websiteGallery = websiteGallery;
      this.observable.next(this.websiteGallery);
    }
  }

  getWebsiteGallery(): WebsiteGallery {
    if (this.websiteGallery) {
      return this.websiteGallery;
    } else {
      return {
        title: this.getTitle(),
        msg: '',
        remark: '',
        enable: true,
        layout: {
          type: 'mansory',
          size: 2,
          gap: 2,
          borderRadius: 1,
          shadow: 2,
          thumbnail: true,
          thumbnailPosition: 'top',
          loop: false,
          autoPlay: true,
        },
        list: {}
      };
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.gallery.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getMsg() {
    return '';
  }

  async save(gallery: WebsiteGallery) {
    if (this.accountId && !this.functionService.isEqual(gallery, this.websiteGallery)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ gallery }, { merge: true });
    }
  }
}
