import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../user/auth.service';
import { Provider } from 'src/app/interfaces/user';
import { MobileService } from '../general/mobile.service';
import { Mobile } from 'src/app/interfaces/general';
import { OnlineService } from '../general/online.service';
import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../general/error.service';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { SettingField } from 'src/app/interfaces/database';
import { WebsiteForm, WebsiteFormQuestions } from 'src/app/interfaces/website';
import { BehaviorSubject } from 'rxjs';
import { FunctionService } from '../general/function.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class WebsiteFormService {

  accountId: string;

  websiteForm: WebsiteForm;

  websiteFormQuestions: WebsiteFormQuestions;

  observable: any;

  constructor(
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private authService: AuthService,
    private mobileService: MobileService,
    private functionService: FunctionService,
    private onlineService: OnlineService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.observable = new BehaviorSubject<WebsiteForm>(this.websiteForm);
  }

  setup(websiteForm: WebsiteForm) {
    if (!this.functionService.isEqual(websiteForm, this.websiteForm)) {
      this.websiteForm = websiteForm;
      this.websiteFormQuestions = this.websiteForm?.questions ? this.websiteForm.questions : {};
      this.setupQuestions();
      this.observable.next(this.websiteForm);
    }
  }

  setupQuestions() {
    this.websiteFormQuestions = this.getQuestions();
  }

  getQuestions() {
    const websiteFormQuestions: WebsiteFormQuestions = this.websiteFormQuestions;

    if (!this.functionService.isUndefined(websiteFormQuestions) && !this.functionService.isEmpty(websiteFormQuestions)) {
      if (this.functionService.isUndefined(websiteFormQuestions?.name)) {
        websiteFormQuestions.name = this.getQuestion('name');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.status)) {
        websiteFormQuestions.status = this.getQuestion('status');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.group)) {
        websiteFormQuestions.group = this.getQuestion('group');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.member)) {
        websiteFormQuestions.member = this.getQuestion('member');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.dietaryReq)) {
        websiteFormQuestions.dietaryReq = this.getQuestion('dietaryReq');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.specialReq)) {
        websiteFormQuestions.specialReq = this.getQuestion('specialReq');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.contact)) {
        websiteFormQuestions.contact = this.getQuestion('contact');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.summary)) {
        websiteFormQuestions.summary = this.getQuestion('summary');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.edit)) {
        websiteFormQuestions.edit = this.getQuestion('edit');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.success)) {
        websiteFormQuestions.success = this.getQuestion('success');
      }
      if (this.functionService.isUndefined(websiteFormQuestions?.fail)) {
        websiteFormQuestions.fail = this.getQuestion('fail');
      }
      return websiteFormQuestions;
    } else {
      return {
        name: this.getQuestion('name'),
        status: this.getQuestion('status'),
        group: this.getQuestion('group'),
        member: this.getQuestion('member'),
        dietaryReq: this.getQuestion('dietaryReq'),
        specialReq: this.getQuestion('specialReq'),
        contact: this.getQuestion('contact'),
        summary: this.getQuestion('summary'),
        edit: this.getQuestion('edit'),
        success: this.getQuestion('success'),
        fail: this.getQuestion('fail'),
      };      
    }
  }

  getWebsiteForm(): WebsiteForm {
    let websiteForm = this.websiteForm;
    if (!websiteForm) {
      websiteForm = {
        enable: true,
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        edit: true,
        media: { url: '', type: '', mimeType: '' },
        questions: {},
        cutOff: {
            enable: true,
            msg: this.getCutOffMsg(),
            time: -1440,
        },
        dietaryReq: {
            enable: true,
            others: false,
        },
        specialReq: {
            enable: true,
            others: false,
        },
        email: {
            type: 'optional',
        },
        mobile: {
            type: 'optional',
        },
      };
    }
    
    websiteForm.questions = this.getQuestions();
    return websiteForm;
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.form.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getMsg() {
    return '';
  }

  getButton() {
    return this.translate.instant('WEBSITE.form.btn');
  }

  getCutOffMsg() {
    return this.translate.instant('WEBSITE.form.cutOff.msg.msg');
  }

  getQuestion(type: string) {
    if (type) {
      const field = 'WEBSITE.form.question.' + type;
      const translate = this.translate.instant(field);
      if (translate && translate !== field) {
        return '<span class="ql-size-large">' + translate + '</span>';
      }
    }
    return '<span class="ql-size-large"> </span>';
  }

  async checkAuth(): Promise<Mobile> {
    let mobile: Mobile = {
      code: 0,
      no: '',
    };
    const currentUser = await this.authService.getCurrentUser();
    if (currentUser?.uid && currentUser?.providerData?.length) {
      currentUser.providerData.forEach((provider: Provider) => {
        if (provider?.providerId === 'phone' && provider?.phoneNumber) {
          mobile = this.mobileService.generate(provider.phoneNumber);
          if (mobile?.code && mobile?.no) {
                   
          }
        }
      });
    }
    return mobile;
  }

  async validateWebsiteLink(accountId: string, linkId: string) {
    if (this.onlineService.online) {
      if (accountId && linkId) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('validateWebsiteLinkCall')({ accountId, linkId })
        .toPromise().then(async (result: any) => {
          
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        this.popupService.dismissLoading();
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }

  async createWebsiteGuest(accountId: string, linkId: string, guestList: Guest[], group: Group, newDietaryReq?: SettingField[], newSpecialReq?: SettingField[]) {
    if (this.onlineService.online) {
      if (accountId && guestList?.length) {
        await this.popupService.presentLoading();
        return await this.fns.httpsCallable('createWebsiteGuestCall')({ accountId, linkId, guestList, group, newDietaryReq, newSpecialReq })
        .toPromise().then(async (result: any) => {
          await this.popupService.dismissLoading();
          return result;
        }).catch(async (err: any) => {
          console.log(err);
          this.errorService.logError(err);
          await this.popupService.dismissLoading();
          return null;
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
    return null;
  }

  async retrieveWebsiteGuest(accountId: string, linkId: string, guestId: string, groupId: string) {
    if (this.onlineService.online) {
      if (accountId && (linkId || guestId || groupId)) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('retrieveWebsiteGuestCall')({ accountId, linkId, guestId, groupId })
        .toPromise().then(async (result: any) => {
          
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        this.popupService.dismissLoading();
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }

  save(form: WebsiteForm) {
    if (this.accountId && !this.functionService.isEqual(form, this.websiteForm)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ form }, { merge: true });
    }
  }

  saveQuestions(questions: WebsiteFormQuestions) {
    if (this.accountId && questions && !this.functionService.isEqual(questions, this.websiteForm?.questions)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ form: { questions } }, { merge: true });
    }
  }
  
}
