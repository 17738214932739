import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupLogService } from 'src/app/services/group/group-log.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { Gift } from 'src/app/interfaces/gift';
import { Log } from 'src/app/interfaces/log';
import { LogType } from 'src/app/types/log';
import { Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DateTimeService } from 'src/app/services/general/date-time.service';

@Component({
  selector: 'app-group-log',
  templateUrl: './group-log.component.html',
  styleUrls: ['./group-log.component.scss'],
})
export class GroupLogComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Group log type
   */
  type: LogType;
  /**
   * group log list
   */
  logList: Log[];
  /**
   * group id
   */
  groupId: string;
  groupLogSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param accountUserService account user service
   * @param groupLogService group log service
   * @param groupService group service
   * @param guestService guest service
   * @param giftService gift service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private accountUserService: AccountUserService,
    private groupLogService: GroupLogService,
    private groupService: GroupService,
    private guestService: GuestService,
    private giftService: GiftService,
    private dateTimeService: DateTimeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatchGroupLog();
  }

  /**
   * View did enter
   */
  ionViewWillEnter() {
    this.watchGroupLog();
  }

  ionViewWillLeave() {
    this.unwatchGroupLog();
  }

  /**
   * Get group log
   */
  async watchGroupLog() {
    await this.popupService.presentLoading();
    await this.unwatchGroupLog();
    if (this.groupId) {
      this.groupLogSubscription = this.groupLogService.getGroupLog(this.groupId, this.type).subscribe((logList: Log[]) => {
        this.setupLog(logList);
        this.dismissLoading();
      });
    } else {
      this.dismissLoading();
    }
  }

  async unwatchGroupLog() {
    if (this.groupLogSubscription) {
      this.groupLogSubscription.unsubscribe();
      this.groupLogSubscription = null;
    }
  }

  /**
   * Setup  log list
   * @param logList log list
   */
  setupLog(logList: Log[]) {
    const newLogList: Log[] = [];
    if (this.type === 'checkin' || this.type === 'invite') {
      logList = logList.filter((log: Log) => {
        if (log?.type?.length && log?.type?.indexOf(this.type) !== -1) {
          return true;
        }
        return false;
      });
    }

    logList?.forEach((log: Log) => {
      if (log?.type?.length) {
        if (log?.type?.length > 1) {
          log.type?.forEach((logType: LogType) => {
            const newLog = { ...log };
            newLog.type = [ logType ];
            if (logType === 'checkin' && log?.action?.checkinBy?.name) {
              newLog.by = log.action.checkinBy;
            } else if (logType === 'invite' && log?.action?.shareBy?.name) {
              newLog.by = log.action.shareBy;
            } else if (logType === 'gift' && log?.action?.giftBy?.name) {
              newLog.by = log.action.giftBy;
            }
            newLogList.push(newLog);
          });
        } else {
          newLogList.push(log);
        }
      }
    });

    this.logList = newLogList.sort((a: Log, b: Log) => {
      return this.functionService.compare(a?.by?.time?.seconds, b?.by?.time?.seconds, true);
    });
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Get guest name by guest id
   * @param guestId guest id
   * @returns guest name
   */
  getGuestName(guestId: string): string {
    return this.guestService.getGuestName(guestId);
  }

  /**
   * Get group name by group id
   * @param groupId group id
   * @returns group name
   */
  getGroupName(groupId: string): string {
    return this.groupService.getGroupName(groupId);
  }

  format(timestamp: number, dateTimeFormat: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * Dismiss loading
   */
  dismissLoading() {
    setTimeout(() => {
      this.popupService.dismissLoading();
    }, 500);
  }

  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    }
    return '';
  }

  /**
   * Get gift by id
   * @param giftId gift id
   * @returns gift obj
   */
  getGiftById(giftId: string): Gift {
    return this.giftService.getGiftById(giftId);
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Track item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Log) {
    if (item?.logId) {
      return item.logId;
    }
    return index;
  }

}
