import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteContactDetail, WebsiteHostMsg } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { AccountInfoService } from '../account/account-info.service';
import { AccountCoupleService } from '../account/account-couple.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteHostService implements OnInit, OnDestroy {

  accountId: string;

  websiteHostMsg: WebsiteHostMsg;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private accountCoupleService: AccountCoupleService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteHostMsg>(this.websiteHostMsg);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getWebsiteHost(): WebsiteHostMsg {
    if (this.websiteHostMsg) {
      return this.websiteHostMsg;
    } else {
      const couple1 = this.accountInfoService.getCouple(1);
      const accountUser1 = this.accountCoupleService.getCoupleUser(1);

      const couple2 = this.accountInfoService.getCouple(2);
      const accountUser2 = this.accountCoupleService.getCoupleUser(2);

      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        enable: true,
        couple1: {
          name: couple1?.name ? couple1?.name : '',
          nickname: '',
          avatar: couple1?.avatar ? couple1.avatar : accountUser1?.avatar ? accountUser1.avatar : { url: '', type: ''},
          msg: '',
          coupleId: 1,
          enable: true,
        },
        couple2: {
          name: couple2?.name ? couple2.name : '',
          nickname: '',
          avatar: couple2?.avatar ? couple2?.avatar : accountUser2?.avatar ? accountUser2.avatar : { url: '', type: ''},
          msg: '',
          coupleId: 2,
          enable: true,
        },
        organizer: {
          name: this.accountInfoService.accountInfo?.organizer ? this.accountInfoService.accountInfo.organizer : '',
          nickname: '',
          avatar: { url: '', type: ''},
          msg: '',
          coupleId: 0,
          enable: true,
        },
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.content.host_msg.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getMsg() {
    return this.translate.instant('WEBSITE.content.host_msg.msg');
  }

  setup(websiteHostMsg: WebsiteHostMsg) {
    if (!this.functionService.isEqual(this.websiteHostMsg, websiteHostMsg)) {
      this.websiteHostMsg = websiteHostMsg;
      this.observable.next(this.websiteHostMsg);
    }
  }

  async save(contact: WebsiteHostMsg) {
    if (this.accountId && !this.functionService.isEqual(contact, this.websiteHostMsg)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contact }, { merge: true });
    }
  }

}
