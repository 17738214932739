<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'IMPORT.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ng-container *ngIf="step === 1">
    <div>
      {{ 'STEP.1' | translate }}
      <ion-card class="box-shadow ion-padding ion-no-margin ion-text-center" (click)="openExcel()" color="light" button>
        <ion-icon class="icon-large" src="./assets/wedding/icon/general/excel-green.svg"></ion-icon>
        <div>{{ 'IMPORT.msg.step1' | translate }}</div>
      </ion-card>
    </div>
    <div class="ion-margin-vertical">
      {{ 'STEP.2' | translate }}
      <ion-card class="box-shadow ion-padding ion-no-margin ion-text-center">
        <ion-icon class="icon-large" src="./assets/wedding/icon/general/keyboard.svg"></ion-icon>
        <div>{{ 'IMPORT.msg.step2' | translate }}</div>
      </ion-card>
    </div>
    <div class="ion-margin-vertical">
      {{ 'STEP.3' | translate }}
      
      <ion-card class="box-shadow ion-padding ion-no-margin ion-text-center" (click)="isHybrid ? promptOpenWeb() : file.click()" color="primary" button>
        <ion-icon class="icon-large" src="./assets/wedding/icon/general/excel-green.svg"></ion-icon>
        <div>{{ 'IMPORT.msg.step3' | translate }}</div>
        <label class="custom-file-upload">
          <input type="file" (change)="onFileChange($event)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" #file/>
        </label>
      </ion-card>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 2">
    <div class="ion-padding-bottom">
      <ion-img class="result-img center" src="./assets/wedding/img/import/error.svg" *ngIf="errorRowList?.length"></ion-img>
      <ion-img class="result-img center" src="./assets/wedding/img/import/success.svg" *ngIf="!errorRowList?.length"></ion-img>
    </div>
    <div class="ion-padding">
      <div class="ion-margin-vertical" *ngIf="guestList?.length && !errorRowList?.length">
        <ion-text class="font-18 font-bold">{{ 'IMPORT.msg.valid_list' | translate }}</ion-text>
      </div>
      <div class="ion-margin-vertical" *ngIf="guestList?.length">
        <ion-text class="font-14">{{ 'IMPORT.msg.total' | translate: { count: guestList.length } }}</ion-text>
      </div>
      <div class="error-div" *ngIf="errorRowList?.length">
        <div class="error-message font-14 ion-margin-vertical">
          {{ 'IMPORT.err.total' | translate: { count: errorRowList.length } }}
        </div>
        <ion-grid class="full-width" fixed>
          <ion-row *ngFor="let row of errorRowList">
            <ion-col class="border" size="1" size-md="2" size-sm="3" size-xs="3">
              <ion-text class="ion-text-wrap">
                {{ 'IMPORT.row' | translate }} {{ row + 1 }}
              </ion-text>
            </ion-col>
            <ion-col class="border">
              <div class="margin-5" *ngIf="getEmtpyRowErrDetail(row) as err">
                <ion-text class="ion-text-wrap" [innerHTML]="err">
                </ion-text>
              </div>
              <div class="margin-5" *ngIf="getInvalidRowErrDetail(row) as err">
                <ion-text class="ion-text-wrap" [innerHTML]="err">
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </ng-container>

  <div class="ion-margin ion-text-center" *ngIf-="step === 1">
    <ion-text class="font-10 font-underline" (click)="goHowImportLink()">
      {{ 'IMPORT.btn.how' | translate }}
    </ion-text>
  </div>
</ion-content>
<ion-footer *ngIf="step === 2">
  <ion-toolbar >
    <ion-button class="ion-margin" expand="block" (click)="save()" *ngIf="guestList?.length && !errorRowList?.length">{{ 'IMPORT.btn.import_now' | translate }}</ion-button>
    <ion-button class="ion-margin" expand="block" (click)="back()" *ngIf="errorRowList?.length">{{ 'BTN.back' | translate }}</ion-button>
    <div class="font-12 font-underline ion-margin ion-text-center" (click)="save()" *ngIf="guestList?.length && errorRowList?.length">
      {{ 'IMPORT.btn.ignore' | translate }}
    </div>
  </ion-toolbar>
</ion-footer>