<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="!newMode">
        <ng-container *ngIf="!hideBlastType">{{ 'MENU.blast' | translate }}</ng-container>
        <ng-container *ngIf="hideBlastType">
          <ng-container *ngIf="blastType === 'sms'">{{ 'BLAST.lbl.sms' | translate }}</ng-container>
          <ng-container *ngIf="blastType === 'whatsapp'">{{ 'BLAST.lbl.whatsapp' | translate }}</ng-container>
        </ng-container>
        <!-- <ng-container *ngIf="!templateType?.custom">{{ 'LIST.blast_type.' + templateType.value | translate }}</ng-container>
        <ng-container *ngIf="templateType?.custom">{{ templateType.value }}</ng-container> -->
      </ng-container>
      <ng-container *ngIf="newMode">
        {{ ('BLAST.template.new_' + (blastType ? blastType : 'sms')) | translate }}
      </ng-container>
    </ion-title>
    <ion-buttons slot="end" *ngIf="!editMode && blastType !== 'whatsapp' && templateType?.value && selectedTemplate?.custom">
      <ion-button (click)="editMode = true;">
        <ion-icon color="dark" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="segment-toolbar" *ngIf="!hideBlastType && !newMode && !editMode">
    <ion-segment class="blast-type-seg full-width" scrollable [(ngModel)]="blastType" (ionChange)="blastTypeChanged()">
      <ion-segment-button class="blast-type-seg-btn" [value]="'sms'">
        <ion-label>{{ 'BLAST.lbl.sms' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button class="blast-type-seg-btn" [value]="'whatsapp'" *ngIf="!newMode && !templateType?.custom">
        <ion-label>{{ 'BLAST.lbl.whatsapp' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <form [formGroup]="form" *ngIf="form && blastType">
    <ion-list class="background-transparent ion-padding-horizontal">
      <div>
        <ion-label position="stacked" color="dark">
          {{ 'BTN.language' | translate }} <ion-text color="danger">*</ion-text>
        </ion-label>
        <ion-item class="prompt-input-item" color="white" lines="none" (click)="presentBlastTemplateLanguageModal(langCode)" button detail>
          <ng-container *ngIf="langCode">{{ 'LIST.language.' + langCode | translate }}</ng-container>
          <ng-container *ngIf="!langCode">{{ 'MSG.please_select' | translate }}</ng-container>
        </ion-item>
      </div>

      <div class="ion-margin-vertical" *ngIf="langCode && !newMode">
        <ion-label position="stacked" color="dark">
          {{ 'BLAST.template.type' | translate }} <ion-text color="danger">*</ion-text>
        </ion-label>
        <ion-item class="prompt-input-item" color="white" lines="none" (click)="presentBlastTemplateTypeModal(templateType)" button detail>
          <ng-container *ngIf="templateType?.value">
            <ng-container *ngIf="!templateType?.custom">{{ 'LIST.blast_type.' + templateType.value | translate }}</ng-container>
            <ng-container *ngIf="templateType?.custom">{{ templateType.value }}</ng-container>
          </ng-container>
          <ng-container *ngIf="!templateType?.value">
            {{ 'MSG.please_select' | translate }}
          </ng-container>
        </ion-item>
        <!-- <div class="error-div"></div> -->
      </div>

      <div class="ion-margin-vertical" *ngIf="newMode">
        <ion-item class="ion-item-no-padding" color="light" lines="none">
          <ion-label position="stacked" color="dark">
            {{ 'BLAST.template.name' | translate }} <ion-text color="danger">*</ion-text>
          </ion-label>
          <ion-item class="background-transparent full-width ion-item-no-padding" lines="none">
            <ion-input class="prompt-input" formControlName="customTemplateNo" type="text" inputmode="text" tabindex="1" [maxlength]="templateNoMaxLength" (ionBlur)="checkDuplicateTemplateNo()" [readonly]="!editMode && !newMode"></ion-input>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.customTemplateNo">
              <div class="error-message" *ngIf="form.get('customTemplateNo').hasError(validation.type) && (form.get('customTemplateNo').dirty || form.get('customTemplateNo').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
      </div>

      <ng-container *ngIf="templateType?.value || (newMode && form?.value?.customTemplateNo)">
        <ng-container *ngIf="(blastType === 'sms' || adminMode) && (newMode || editMode)">
          <ion-card class="ion-no-margin ion-margin-vertical" *ngIf="(langCode === 'zh' || langCode === 'zh-TW')">
            <ion-item lines="none" (click)="presentBlastTipsModal()" detail>
              <ion-icon slot="start" name="information-circle-outline"></ion-icon>
              <ion-text class="font-14 font-med">
                {{ 'BLAST.tips.unicode.title' | translate }}
              </ion-text>
            </ion-item>
          </ion-card>
          
          <div>
            <ion-item class="label-item ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'LBL.title' | translate }} <ion-text color="danger">*</ion-text>
                &nbsp;
              </ion-label>
              <span class="reset-btn font-underline font-10 ion-float-right ion-no-margin" slot="end" (click)="resetTemplateTitle()" *ngIf="editMode">{{ 'BTN.reset' | translate }}</span>
            </ion-item>
            <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditField('msgTitle')">
              <div class="full-width text-area" *ngIf="editField.msgTitle">
                <ion-textarea class="background-white fixed-height" formControlName="msgTitle" rows="2" tabindex="2" (ionChange)="setupTemplateTitle()" [maxlength]="titleMaxLength" (ionBlur)="editField.msgTitle = false; checkTitle(form?.value?.msgTitle);" [readonly]="!editMode" autoGrow="true" #msgTitleInput></ion-textarea>
              </div>
              <ion-text class="full-width html-div wrap-newline" *ngIf="!editField.msgTitle" [innerHtml]="getHtml(form?.value?.msgTitle, true)"></ion-text>
            </ion-item>
            <div class="lbl-div full-width">
              <ion-item class="ion-item-no-padding full-width error-item" color="light" lines="none">
                <ion-text class="error-div ion-text-wrap">
                  <ng-container *ngFor="let validation of validationMsg?.msgTitle">
                    <div class="error-message" *ngIf="form.get('msgTitle').hasError(validation.type) && (form.get('msgTitle').dirty || form.get('msgTitle').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                    </div>
                  </ng-container>
                  <div class="error-message" *ngIf="titleMaxLength && titleWordCount() > titleMaxLength">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.max_length' | translate: { number: titleMaxLength } }}
                  </div>
                </ion-text>
                <ion-note class="font-10 ion-text-right ion-no-margin" slot="end">
                  <ion-text class="font-10">
                    {{ titleWordCount() }} / {{ titleMaxLength }}
                  </ion-text>
                </ion-note>
              </ion-item>
            </div>
          </div>
  
          <div class="ion-margin-vertical">
            <ion-item class="label-item ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'LBL.msg' | translate }} <ion-text color="danger">*</ion-text>
                &nbsp;
              </ion-label>
              <!-- <span class="reset-btn font-underline font-10 ion-float-right ion-no-margin" slot="end" (click)="resetTemplateMsg()" *ngIf="editMode">{{ 'BTN.reset' | translate }}</span> -->
            </ion-item>
            <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditField('msg')">
              <div class="full-width text-area" *ngIf="editField.msg">
                <ion-textarea class="background-white fixed-height" formControlName="msg" rows="4" tabindex="3" (ionBlur)="editField.msg = false; checkMsg(form?.value?.msg);" [readonly]="!editMode" autoGrow="true" #msgInput></ion-textarea>
              </div>
              <ion-text class="full-width html-div wrap-newline" *ngIf="!editField.msg" [innerHtml]="getHtml(form?.value?.msg)"></ion-text>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.msg">
                <div class="error-message" *ngIf="form.get('msg').hasError(validation.type) && (form.get('msg').dirty || form.get('msg').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </div>
    
          <div *ngIf="editMode">
            <ion-label position="stacked" color="dark">
              {{ 'QRCODE.lbl.dynamic_variable' | translate }}
            </ion-label>
            <ion-segment scrollable>
              <ng-container *ngFor="let variable of getTemplateVariableList()">
                <ion-segment-button class="dynamic-variable-seg-btn ion-no-padding" *ngIf="variable && variable !== 'guest_url'" (click)="copy(variable)">
                  <ion-text>{{ replaceEventType('TEMPLATE.variable.' + variable | translate) }}</ion-text>
                </ion-segment-button>
              </ng-container>
            </ion-segment>
          </div>
          
          <div class="ion-margin-vertical" *ngIf="editMode">
            <ion-label position="stacked" color="dark">
              {{ 'LBL.url' | translate }}
            </ion-label>
            <ion-item class="border-radius prompt-input-item" lines="none">
              <ion-toggle formControlName="url" color="success" [disabled]="(!templateType?.custom && templateType?.value === 'qrcode')">
                <ion-text class="ion-text-wrap ion-padding-vertical">
                  <div class="line-1">
                    <ion-text class="font-14" color="black">{{ 'BLAST.template.url.title' | translate }}</ion-text>
                  </div>
                  <div class="line-1">
                    <ion-text class="font-10" color="dark">{{ 'BLAST.template.url.msg' | translate }}</ion-text>
                  </div>
                </ion-text>
              </ion-toggle>
            </ion-item>
            <div class="error-div"></div>
          </div>
        </ng-container>  
        
      </ng-container>
    </ion-list>
  </form>

  <div class="ion-padding-horizontal">
    <ng-container>
      <div class="ion-padding-bottom" *ngIf="langCode && templateType?.value && !newMode && getTemplateNoList(templateType, langCode) as templateNoList">
        <ng-container *ngIf="templateNoList?.length">
          <ion-label position="stacked" color="dark">
            {{ 'BLAST.template.title' | translate }} <ion-text color="danger">*</ion-text>
            <span class="font-underline ion-float-right" style="margin-top: 5px;" (click)="newTemplate()" *ngIf="blastType === 'sms' || adminMode">
              {{ 'CRUD.new' | translate }}
            </span>
          </ion-label>
          <ion-segment class="template-no-seg" [(ngModel)]="templateNo" (ionChange)="setupTemplate()" scrollable>
            <ion-segment-button class="template-no-btn" [value]="no" *ngFor="let no of templateNoList">
              <ion-text class="ion-text-wrap">{{ no }}</ion-text>
            </ion-segment-button>
          </ion-segment>
        </ng-container>
      </div>
    </ng-container>
  
    <ng-container *ngIf="!editMode && (templateType?.value || (newMode && form?.value?.customTemplateNo)) && (blastType === 'sms' || templateNo)">
      <ion-card class="ion-no-margin ion-margin-vertical ion-padding" (click)="presentGuestListModal()" [color]="(selectedGuestIdList?.length || selectedGroupIdList?.length) ? 'secondary' : 'primary'" button>
        <ion-item class="background-transparent ion-item-no-padding" lines="none" detail>
          <ion-text class="font-14" color="medium">
            <ion-text class="font-14 font-bold" color="dark">
              <ng-container *ngIf="selectedGuestIdList?.length || selectedGroupIdList?.length">{{ 'BLAST.template.recipients' | translate }}</ng-container>
              <ng-container *ngIf="!selectedGuestIdList?.length && !selectedGroupIdList?.length">{{ 'BLAST.template.select_recipient' | translate }}</ng-container>
            </ion-text>
            <div *ngIf="selectedGuestIdList?.length">
              {{ 'BLAST.lbl.count_guest_selected' | translate: { count: selectedGuestIdList?.length ? selectedGuestIdList.length : 0 } }}
            </div>
            <div *ngIf="selectedGroupIdList?.length">
              {{ 'BLAST.lbl.count_group_selected' | translate: { count: selectedGroupIdList?.length ? selectedGroupIdList.length : 0 } }}
            </div>
          </ion-text>
        </ion-item>
      </ion-card>
    </ng-container>
  </div>

  <div class="ion-padding-horizontal ion-padding-top" *ngIf="blastType && langCode && (templateType?.value || (newMode && form?.value?.customTemplateNo)) && (blastType === 'sms' || adminMode || templateNo)">
    <app-blast-template-preview [langCode]="langCode ? langCode : !selectedTemplate?.language?.custom && selectedTemplate?.language?.value ? selectedTemplate.language.value : ''" [blastType]="blastType" [msgTitle]="form?.value?.msgTitle" [msg]="form?.value?.msg" [url]="form?.value?.url ? sampleUrl : ''" [phoneCode]="phoneCode" [pageMode]="true" (titleMaxLength)="setTitleMaxLength($event)" (totalBlastCount)="setTotalBlastCount($event)" (dynamicVariable)="setDynamicVariable($event)" *ngIf="form"></app-blast-template-preview>
  </div>
  
  <div class="ion-padding-vertical" *ngIf="editMode && selectedTemplate?.custom">
    <ion-list class="ion-margin-vertical">
      <ion-item-sliding [disabled]="!editMode" #deleteItem>
        <ion-item-options side="start" (ionSwipe)="promptDelete(deleteItem)" lines="none">
          <ion-item-option color="danger" (click)="promptDelete(deleteItem)" lines="none">
            <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
          </ion-item-option>
        </ion-item-options>
        <ion-item color="danger" lines="none">
          <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/trash.svg"></ion-icon> 
          <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
          <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/arrow-forward.svg"></ion-icon>
        </ion-item>
        <ion-item-options side="end" (ionSwipe)="promptDelete(deleteItem)" lines="none">
          <ion-item-option color="danger" (click)="promptDelete(deleteItem)" lines="none">
            <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-no-padding">
    <div class="ion-padding">
      <div *ngIf="!editMode">
        <div>
          <ng-container *ngFor="let validation of validationMsg?.msgTitle">
            <div class="error-div error-message" *ngIf="form.get('msgTitle').hasError(validation.type) && validation.type !== 'required' && (form.get('msgTitle').dirty || form.get('msgTitle').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'LBL.title' | translate }}: {{ validation.msgTitle }}
            </div>
          </ng-container>
        </div>

        <div>
          <ion-text class="ion-text-wrap">
            <div class="error-div error-message" *ngIf="titleMaxLength && titleWordCount() > titleMaxLength">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'LBL.title' | translate }}: {{ 'VALIDATION.max_length' | translate: { number: titleMaxLength } }}
            </div>
          </ion-text>
        </div>

        <div>
          <ng-container *ngFor="let validation of validationMsg?.msg">
            <div class="error-div error-message" *ngIf="form.get('msg').hasError(validation.type) && validation.type !== 'required'">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'LBL.msg' | translate }}: {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </div>
      <ion-grid class="full-width" fixed>
        <ion-row *ngIf="editMode">
          <ion-col>
            <ion-button color="grey" expand="block" (click)="cancel()">{{ 'BTN.cancel' | translate }}</ion-button>
          </ion-col>
          <ion-col>
            <!-- [disabled]="!form?.valid || blastCount > 10 || (titleMaxLength && titleWordCount() > titleMaxLength)" -->
            <ion-button color="tertiary" expand="block" (click)="submit(false)" [disabled]="checkDisableSubmit()">{{ 'BTN.save' | translate }}</ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!editMode">
          <ion-col>
            <ion-button color="primary" expand="block" (click)="submit(true)" [disabled]="checkDisableSubmit()">{{ 'BTN.next' | translate }}</ion-button>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="blastType === 'sms'">
          <ion-row *ngIf="blastCount > 10">
            <ion-col>
              <div>
                <div class="error-message ion-text-center">
                  {{ 'BLAST.template.msg.max_sms' | translate }}
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="(form?.value?.url && blastCount > 3) || (!form?.value?.url && blastCount > 2)">
            <ion-col>
              <div class="font-12">
                <div class="error-message ion-text-center">
                  {{ 'BLAST.template.msg.recommend_sms' | translate: { count: form?.value?.url ? 3 : 2 } }}
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-grid>
    </div>
  </ion-toolbar>
</ion-footer>