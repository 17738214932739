import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Camera, CameraSource, CameraDirection, CameraResultType, Photo, CameraPermissionType, GalleryPhotos } from '@capacitor/camera';

import { FunctionService } from 'src/app/services/general/function.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { UserManageService } from 'src/app/services/user/user-manage.service';
import { UserService } from 'src/app/services/user/user.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { CropComponent } from 'src/app/components/general/crop/crop.component';
import { UserAvatarComponent } from 'src/app/components/user/user-avatar/user-avatar.component';
import { MediaType } from 'src/app/interfaces/general';
import { AttachmentType, PhotoType } from 'src/app/types/general';

import { VisitorSettingService } from '../visitor/visitor-setting.service';
import { VisitorManageService } from '../visitor/visitor-manage.service';

import { WebsiteAttachmentService } from '../website/website-attachment.service';
import { WebsiteContentMediaService } from '../website/website-content-media.service';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { WebsiteContactQrcode, WebsiteMedia, WebsiteMediaList } from 'src/app/interfaces/website';
import { HttpClient } from '@angular/common/http';

import { NgxImageCompressService } from 'ngx-image-compress';


@Injectable({
  providedIn: 'root'
})
export class PhotoService implements OnInit, OnDestroy {

  // attachmentList: WebsiteAttachmentPhotoList;
  supportAttachementList: string[];
  userAvatar: MediaType;
  accountGalleryList: string[];

  websiteHostAvatar: {
    [coupleId: string]: MediaType
  };

  websiteGalleryDbList: WebsiteMediaList;
  websiteGalleryList: WebsiteMediaList;

  websiteCover: MediaType;

  websiteContactQrcode: WebsiteContactQrcode;

  
  observableSupportAttachment: any;

  observableUserAvatar: any;

  observableAccountGallery: any;

  observableWebsiteHostAvatar: any;

  observableWebsiteGallery: any;

  observableWebsiteCover: any;

  observableWebsiteContactQrcode: any;
  /**
   * Constructor
   * @param actionSheetController Action controller
   * @param modalController modal controller
   * @param translate translate
   * @param afStorage angular firestorage
   * @param userService user service
   * @param userManageService user manage service
   * @param accountInfoService account info service
   * @param onlineService online service
   * @param popupService popup service
   * @param functionService function service
   * @param errorService error service
   */
  constructor(
    private http: HttpClient,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private afStorage: AngularFireStorage,
    private userService: UserService,
    private userManageService: UserManageService,
    private accountInfoService: AccountInfoService,
    private visitorSettingService: VisitorSettingService,
    private visitorManageService: VisitorManageService,
    private websiteAttachmentService: WebsiteAttachmentService,
    private websiteContentMediaService: WebsiteContentMediaService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
    private imageCompress: NgxImageCompressService,
  ) {
    this.observableSupportAttachment = new BehaviorSubject<string[]>(this.supportAttachementList);
    this.observableUserAvatar = new BehaviorSubject<MediaType>(this.userAvatar);
    this.observableAccountGallery = new BehaviorSubject<string[]>(this.accountGalleryList);
    this.observableWebsiteHostAvatar = new BehaviorSubject<{ [coupleId: string]: MediaType }>(this.websiteHostAvatar);
    this.observableWebsiteGallery = new BehaviorSubject<WebsiteMediaList>(this.websiteGalleryList);
    this.observableWebsiteCover = new BehaviorSubject<MediaType>(this.websiteCover);
    this.observableWebsiteContactQrcode = new BehaviorSubject<WebsiteContactQrcode>(this.websiteContactQrcode);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupWebsiteHostAvatar(coupleId: string, mediaType: MediaType) {
    if (!this.websiteHostAvatar) {
      this.websiteHostAvatar = {};
    }

    this.websiteHostAvatar[coupleId] = mediaType;
    this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
  }

  resetWebsiteGalleryList() {
    this.websiteGalleryList = {};
    this.websiteGalleryDbList = {};
    this.observableWebsiteGallery.next(this.websiteGalleryList);
  }

  getQuality(type: PhotoType | AttachmentType) {
    let quality = 80;
    if (type === 'website_cover' || type === 'website_gallery' || type === 'account_gallery' || type === 'website_content' || type === 'couple1_avatar' || type === 'couple2_avatar' || type === 'host_avatar' || type === 'user_avatar') {
      quality = 100;
    }
    return quality;
  }

  getWidth(type: PhotoType | AttachmentType) {
    let width = 1024;
    if (type === 'website_gallery' || type === 'website_cover') {
      width = 2048;
    } else if (type === 'account_gallery') {
      width = 1024;
    } else if (type === 'couple1_avatar' || type === 'couple2_avatar' || type === 'host_avatar' || type === 'user_avatar') {
      width = 400;
    } else if (type === 'website_content') {
      width = 1024;
    }
    return width;
  }

  /**
   * Select photo for upload
   * @param type Photo type
   */
  async selectPhoto(type: PhotoType | AttachmentType, save?: boolean, deleteBtn: boolean = false, header?: string, subHeader?: string) {
    if (type) {
      if (type === 'user_avatar') {
        header = this.translate.instant('USER.lbl.avatar');
      } else if (type === 'account_gallery') {
        header = this.translate.instant('ACCOUNT.lbl.gallery');
        if (!deleteBtn && this.accountInfoService.accountInfo?.gallery?.length) {
          deleteBtn = true;
        }
      } else if (type === 'background_img') {
        header = this.translate.instant('VISITOR.setting.background.title');
        if (!deleteBtn && this.visitorSettingService.pageSetting?.backgroundUrl) {
          deleteBtn = true;
        }
      } else if (type === 'support_attachment') {
        save = false;
      } else if (type === 'couple1_avatar' || type === 'couple2_avatar' || type === 'host_avatar') {
        if (!this.websiteHostAvatar) {
          this.websiteHostAvatar = {};  
        } 
      } else if (type === 'website_gallery') {
        header = this.translate.instant('WEBSITE.gallery.title');
        if (!this.websiteGalleryList) {
          this.websiteGalleryList = {};
        }
      } else if (type === 'website_cover') {
        if (!this.websiteCover) {
          this.websiteCover = {
            type: '',
            url: '',
          };
        }
        header = this.translate.instant('WEBSITE.theme.cover.title');
        if (this.websiteCover?.url) {
          deleteBtn = true;
        }
      }
      await this.presentGalleryActionSheet(type, header, deleteBtn, save, subHeader);
    }
  }

  /**
   * Present Avatar Actoin Sheet
   */
  async presentGalleryActionSheet(type: PhotoType | AttachmentType, header?: string, deleteBtn?: boolean, save?: boolean, subHeader?: string) {
    const buttons: any[] = [];
    if (type === 'user_avatar' || type === 'couple1_avatar' || type === 'couple2_avatar' || type === 'host_avatar') {
      buttons.push({
        text: this.translate.instant('USER.lbl.avatar_gallery'),
        handler: () => {
          this.openAvatarModal(type, save);
        }
      });
    }

    if (type === 'user_avatar') {
      buttons.push({
        text: this.translate.instant('PHOTO.camera'),
        handler: () => {
          this.captureImage(type, save);
        }
      });
    }
    buttons.push({
      text: this.translate.instant('PHOTO.library'),
      handler: () => {
        this.imageGallery(type, save);
      }
    });

    if (deleteBtn) {
      buttons.push({
        text: this.translate.instant('CRUD.delete'),
        handler: async () => {
          this.deletePhoto(type, save);
        }
      });
    }

    if (buttons?.length > 1) {
      buttons.push({
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel',
        handler: () => {
        }
      });
      const actionSheet = await this.actionSheetController.create({
        header,
        subHeader,
        buttons
      });
      actionSheet.present();
    } else if (buttons?.length === 1) {
      if (buttons?.[0]?.text === this.translate.instant('USER.lbl.avatar_gallery')) {
        this.openAvatarModal(type, save);
      } else if (buttons?.[0]?.text === this.translate.instant('PHOTO.camera')) {
        this.captureImage(type, save);
      } else if (buttons?.[0]?.text === this.translate.instant('PHOTO.library')) {
        this.imageGallery(type, save);
      }
    }
    
  }

  async checkPermission(permission: CameraPermissionType) {
    const permissionStatus = await Camera.checkPermissions();
    if (permission === 'camera' && permissionStatus?.camera) {
      return true;
    } else if (permission === 'photos' && permissionStatus?.photos) {
      return true;
    }
    return false;
  }

  async requestPermission(permission: CameraPermissionType) {
    await Camera.requestPermissions({ permissions: [ permission ] });
  }

  /**
   * Capture image
   */
  async captureImage(type: PhotoType | AttachmentType, save?: boolean) {
    try {
      const image = await Camera.getPhoto({
        quality: this.getQuality(type),
        allowEditing: false,
        width: this.getWidth(type),
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        direction: CameraDirection.Front
      });
      this.openCropModal(type, image, save);
    } catch (err: any) {
      this.errorService.logError(err);
    }
  }

  /**
   * Image gallery
   */
  async imageGallery(type: PhotoType | AttachmentType, save?: boolean, contentId?: string, mediaId?: string) {
    try {
      const image = await Camera.getPhoto({
        quality: this.getQuality(type),
        allowEditing: false,
        width: this.getWidth(type),
        resultType: CameraResultType.Base64,
        webUseInput: true,
        source: CameraSource.Photos
      });

      if (image?.base64String) {
        if (type === 'dressCode' || type === 'floorplan' || type === 'itinerary' || type === 'menu' || type === 'parking') {
          if (!this.websiteAttachmentService.attachment) {
            this.websiteAttachmentService.attachment = {};
          }
          if (!this.websiteAttachmentService.attachment?.[type]) {
            this.websiteAttachmentService.attachment[type] = this.websiteAttachmentService.getDefaultAttachment();
          }
          this.websiteAttachmentService.attachment[type].base64 = 'data:image/' + image.format + ';base64,' + image.base64String;
          this.websiteAttachmentService.attachment[type].mimeType = 'image/' + image.format;
        } else if (type === 'website_content') {
          // if (!this.websiteContentMediaService.photoList) {
          //   this.websiteContentMediaService.photoList = {};
          // }
          // if (!this.websiteContentMediaService.photoList?.[contentId]) {
          //   this.websiteContentMediaService.photoList[contentId] = {};
          // }
          // if (contentId) {
          //   this.websiteContentMediaService.photoList[contentId].base64 = 'data:image/' + image.format + ';base64,' + image.base64String;
          //   this.websiteContentMediaService.photoList[contentId].mimeType = 'image/' + image.format;
          // }
          this.websiteContentMediaService.setup(contentId, { type: 'base64', url: 'data:image/' + image.format + ';base64,' + image.base64String });
        } else if (type === 'website_gallery') {
          if (mediaId) {
            const mimeType = 'image/' + image.format;
            const mediaUrl: MediaType = { url: 'data:image/' + image.format + ';base64,' + image.base64String, type: 'base64', mimeType };
            // const mediaType = 'photo';

            if (!this.websiteGalleryList) {
              this.websiteGalleryList = {};
            }

            if (mediaId) {
              const thumbnail = await this.imageCompress.compressFile(mediaUrl.url, 0, 50, 50, 80, 80);
              const thumbnailUrl: MediaType = { url: thumbnail, type: 'base64' };
              if (this.websiteGalleryList?.[mediaId]) {
                this.websiteGalleryList[mediaId].mediaUrl = mediaUrl;
                this.websiteGalleryList[mediaId].thumbnailUrl = thumbnailUrl;
                this.websiteGalleryList[mediaId].mimeType = mimeType;
                // this.websiteGalleryList[mediaId].mediaType = mediaType;
              } else if (this.websiteGalleryDbList?.[mediaId]) {
                const gallery: WebsiteMedia = this.websiteGalleryDbList[mediaId];
                gallery.mediaUrl= mediaUrl;
                gallery.thumbnailUrl = thumbnailUrl;
                // gallery.mimeType = mimeType;
                // gallery.mediaType = mediaType;
                this.websiteGalleryList[mediaId] = gallery;
              }
              this.observableWebsiteGallery.next(this.websiteGalleryList);
            }
          }
          
          // const mediaId = this.functionService.randomId();
          // const gallery: WebsiteMedia = {
          //   mediaId,
          //   mediaUrl: { url: 'data:image/' + image.format + ';base64,' + image.base64String, type: 'base64' },
          //   mimeType: 'image/' + image.format,
          //   mediaType: 'photo',
          //   order: 0,
          // };
          // if (!this.websiteGalleryList?.[mediaId]) {
          //   gallery.order = Object.keys(this.websiteGalleryList)?.length + 1;

          //   this.websiteGalleryList[mediaId] = gallery;
          // }
          // this.observableWebsiteGallery.next(this.websiteGalleryList);
        } else if (type === 'website_cover') {
          if (!this.websiteCover) {
            this.websiteCover = {
              type: '',
              url: '',
            };
          }
          this.websiteCover.type = 'base64';
          this.websiteCover.url = 'data:image/' + image.format + ';base64,' + image.base64String;
          this.observableWebsiteCover.next(this.websiteCover);
        } else if (type === 'website_contact_qrcode') {
          if (!this.websiteContactQrcode) {
            this.websiteContactQrcode = {};
          }
          if (contentId) {
            this.websiteContactQrcode[contentId] = {
              type: 'base64',
              url: 'data:image/' + image.format + ';base64,' + image.base64String,
            };
            this.observableWebsiteContactQrcode.next(this.websiteContactQrcode);
          }
        } else {
          this.openCropModal(type, image, save);
        }
      }
    } catch (err: any) {
      this.errorService.logError(err);
    }
  }

  async pickImages(type: PhotoType | AttachmentType, save?: boolean) {
    await this.popupService.presentLoading();
    try {
      const galleryPhotos: GalleryPhotos = await Camera.pickImages({
        quality: this.getQuality(type),
        width: this.getWidth(type),
      });

      if (galleryPhotos?.photos?.length) {
        for (const photos of galleryPhotos.photos) {
        // galleryPhotos.photos.forEach(async (photos: GalleryPhoto) => {
          if (photos?.webPath) {
            if (type === 'dressCode' || type === 'floorplan' || type === 'itinerary' || type === 'menu' || type === 'parking') {

            } else if (type === 'website_content') {

            } else if (type === 'couple1_avatar') {

            } else if (type === 'couple2_avatar') {

            } else if (type === 'host_avatar') {

            } else if (type === 'website_gallery') {
  
              if (!this.websiteGalleryList) {
                this.websiteGalleryList = {};
              }
  
              const fileBlob = await this.http.get(photos?.webPath, { responseType: 'blob' }).toPromise();
              const url = await this.blobToDataURI(fileBlob);
  
              // const mediaUrl: MediaType = { url: photos?.webPath, type: 'blob' };
              const mimeType = 'image/' + photos?.format;
              const mediaUrl: MediaType = { url, type: 'base64', mimeType };
              
              // const mediaType = 'photo';
              
              const thumbnail = await this.imageCompress.compressFile(url, 0, 50, 50, 80, 80);
              const thumbnailUrl: MediaType = { url: thumbnail, type: 'base64' };
  
              const mediaId = this.functionService.randomId();
              const order = (this.websiteGalleryDbList ? Object.keys(this.websiteGalleryDbList)?.length : 0) + 1;
              const gallery: WebsiteMedia = {
                mediaId,
                mediaUrl,
                thumbnailUrl,
                order,
              };
              this.websiteGalleryList[mediaId] = gallery;
  
            }
          }
        }
  
        if (type === 'website_gallery') {
          if (this.websiteGalleryList) {
            this.observableWebsiteGallery.next(this.websiteGalleryList);
          }
        }
      }
    } catch (err: any) {

    }
    
    
    await this.popupService.dismissLoading();
  }

  /**
   * Open crop modal
   * @param base64 base64 image
   */
  async openCropModal(photoType: PhotoType | AttachmentType, image: Photo, save?: boolean) {
    if (image?.base64String && image?.format) {
      const modal = await this.modalController.create({
        component: CropComponent,
        cssClass: 'modal-full-screen',
        componentProps: {
          photoType,
          format: image.format,
          base64: 'data:image/' + image.format + ';base64,' + image.base64String
        }
      });
      modal.present();
      modal.onDidDismiss().then(result => {
        if (result?.data?.img) {
          const img = result.data.img.toString();
          if (img) {
            if (save) {
              this.uploadPhoto(photoType, img, save);
            } else {
              if (photoType === 'user_avatar') {
                this.userAvatar = {
                  url: img,
                  type: 'base64'
                };
                this.observableUserAvatar.next(this.userAvatar);
              } else if (photoType === 'support_attachment') {
                if (!this.supportAttachementList?.length) {
                  this.supportAttachementList = [];
                }
                this.supportAttachementList.push(img);
                this.observableSupportAttachment.next(this.supportAttachementList);
              } else if (photoType === 'account_gallery') {
                this.accountGalleryList = [];
                this.accountGalleryList.push(img);
                this.observableAccountGallery.next(this.accountGalleryList);
              } else if (photoType === 'couple1_avatar') {
                if (!this.websiteHostAvatar) {
                  this.websiteHostAvatar = {};  
                }
                this.websiteHostAvatar['1'] = {
                  url: 'data:image/' + image.format + ';base64,' + image.base64String,
                  type: 'base64'
                };
                this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
              } else if (photoType === 'couple2_avatar') {
                if (!this.websiteHostAvatar) {
                  this.websiteHostAvatar = {};  
                }
                this.websiteHostAvatar['2'] = {
                  url: 'data:image/' + image.format + ';base64,' + image.base64String,
                  type: 'base64'
                };
                this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
              } else if (photoType === 'host_avatar') {
                if (!this.websiteHostAvatar) {
                  this.websiteHostAvatar = {};  
                }
                this.websiteHostAvatar['0'] = {
                  url: 'data:image/' + image.format + ';base64,' + image.base64String,
                  type: 'base64'
                };
                this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
              }
            }
          }
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Open predefined avatar modal, save avatar url if different avatar detected.
   */
  async openAvatarModal(type: PhotoType | AttachmentType, save?: boolean) {
    const modal = await this.modalController.create({
      component: UserAvatarComponent,
      componentProps: { }
    });
    modal.present();
    modal.onDidDismiss().then((result: any) => {
      if (type === 'user_avatar') {
        if (result?.data?.img) {
          const avatar: MediaType = {
            url: result.data.img,
            type: 'local'
          };
          if (!this.functionService.isEqual(this.userService.user?.avatar, avatar)) {
            if (save) {
              this.saveUserAvatar(avatar);
            } else {
              this.userAvatar = avatar;
              this.observableUserAvatar.next(this.userAvatar);
            }
          } else {
            this.successMsg();
          }
        } else if (result?.data?.provider) {
          const avatar: MediaType = {
            url: result.data.provider,
            type: 'cloud'
          };
          if (!this.functionService.isEqual(this.userService.user?.avatar, avatar)) {
            if (save) {
              this.saveUserAvatar(avatar);
            } else {
              this.userAvatar = avatar;
              this.observableUserAvatar.next(this.userAvatar);
            }
          } else {
            this.successMsg();
          }
        }
      } else if (type === 'couple1_avatar') {
        if (!this.websiteHostAvatar) {
          this.websiteHostAvatar = {};  
        }
        if (result?.data?.img) {
          const avatar: MediaType = {
            url: result.data.img,
            type: 'local'
          };
          this.websiteHostAvatar['1'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        } else if (result?.data?.provider) {
          const avatar: MediaType = {
            url: result.data.provider,
            type: 'cloud'
          };
          this.websiteHostAvatar['1'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        }
      } else if (type === 'couple2_avatar') {
        if (!this.websiteHostAvatar) {
          this.websiteHostAvatar = {};  
        }
        if (result?.data?.img) {
          const avatar: MediaType = {
            url: result.data.img,
            type: 'local'
          };
          this.websiteHostAvatar['2'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        } else if (result?.data?.provider) {
          const avatar: MediaType = {
            url: result.data.provider,
            type: 'cloud'
          };
          this.websiteHostAvatar['2'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        }
      } else if (type === 'host_avatar') {
        if (!this.websiteHostAvatar) {
          this.websiteHostAvatar = {};  
        }
        if (result?.data?.img) {
          const avatar: MediaType = {
            url: result.data.img,
            type: 'local'
          };
          this.websiteHostAvatar['0'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        } else if (result?.data?.provider) {
          const avatar: MediaType = {
            url: result.data.provider,
            type: 'cloud'
          };
          this.websiteHostAvatar['0'] = avatar;
          this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
        }
      }
    });
  }

  async deletePhoto(type: PhotoType | AttachmentType, save?: boolean, contentId?: string) {
    await this.popupService.presentLoading();
    if (type === 'account_gallery') {
      if (save) {
        await this.deleteAccountGallery();
        await this.saveDB(type, '');
      } else {
        this.accountGalleryList = [];
        this.observableAccountGallery.next(this.accountGalleryList);
      }
    } else if (type === 'background_img') {
      if (save) {
        await this.deleteBackgroundImg();
        await this.saveDB(type, '');
      }
    } else if (type === 'dressCode' || type === 'floorplan' || type === 'itinerary' || type === 'menu' || type === 'parking') {
      if (!this.websiteAttachmentService.attachment) {
        this.websiteAttachmentService.attachment = {};
      }
      this.websiteAttachmentService.attachment[type] = this.websiteAttachmentService.getDefaultAttachment();
    } else if (type === 'website_content') {
      if (contentId) {
        this.websiteContentMediaService.setup(contentId, { url: '', type: '' });
        // if (!this.websiteContentMediaService.photoList) {
        //   this.websiteContentMediaService.photoList = {};
        // }
        // if (!this.websiteContentMediaService.photoList?.[contentId]) {
        //   this.websiteContentMediaService.photoList[contentId] = {};
        // }
        // if (contentId) {
        //   this.websiteContentMediaService.photoList[contentId].base64 = '';
        //   this.websiteContentMediaService.photoList[contentId].mimeType = '';
        //   this.websiteContentMediaService.photoList[contentId].mediaUrl = {
        //     url: '',
        //     type: '',
        //   };
        // }
        // if (save) {
          
        // }
      }
    } else if (type === 'couple1_avatar') {
      this.websiteHostAvatar['1'] = {
        url: '',
        type: '',
      };
      this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
    } else if (type === 'couple2_avatar') {
      this.websiteHostAvatar['2'] = {
        url: '',
        type: '',
      };
      this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
    } else if (type === 'host_avatar') {
      this.websiteHostAvatar['0'] = {
        url: '',
        type: '',
      };
      this.observableWebsiteHostAvatar.next(this.websiteHostAvatar);
    } else if (type === 'website_cover') {
      this.websiteCover = {
        type: '',
        url: '',
      };
      this.observableWebsiteCover.next(this.websiteCover);
    } else if (type === 'website_contact_qrcode') {
      if (contentId) {
        if (this.websiteContactQrcode?.[contentId]) {
          this.websiteContactQrcode[contentId] = {
            type: '',
            url: '',
          };
          this.observableWebsiteContactQrcode.next(this.websiteContactQrcode);
        }
      }
    }
    this.popupService.dismissLoading();
  }

  /**
   * Upload photo
   * @param type photo type
   * @param base64 base64 image
   */
  async uploadPhoto(type: PhotoType | AttachmentType, base64: string, save?: boolean) {
    if (base64 && type) {
      await this.popupService.presentLoading();
      if (type === 'user_avatar') {
        await this.uploadUserAvatar(type, base64);
      } else if (type === 'account_gallery') {
        await this.uploadAccountGallery(type, base64, save);
      } else if (type === 'background_img') {
        await this.uploadBackgroundImg(type, base64);
      } else if (type === 'website_content') {
        // await this.uploadWebsiteContentImg(type, base64, contentId);
      } else if (type === 'couple1_avatar') {

      } else if (type === 'couple2_avatar') {

      } else if (type === 'host_avatar') {

      }
    } else {
      await this.popupService.presentActionError();
    }
  }

  /**
   * Upload user avatar to firestorage
   * @param type photo type
   * @param base64 base64 image
   */
  async uploadUserAvatar(type: PhotoType, base64: string, hideSuccess?: boolean) {
    if (type && base64) {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('avatar').child(this.userService.uid).child(this.userService.uid);
      await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then(async (url) => {
          if (url) {
            const avatar: MediaType = {
              url,
              type: 'firebase'
            };
            await this.saveUserAvatar(avatar);
            if (!hideSuccess) {
              this.successMsg();
            }
          } else {
            if (!hideSuccess) {
              this.successMsg(true);
            }
          }
          return url;
        });
      }, (err: any) => {
        this.errorService.logError(err);
        this.successMsg(true);
      });
    } else {
      this.successMsg(true);
    }
  }

  async deleteUserAvatar() {
    try {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('avatar').child(this.userService.uid).child(this.userService.uid);
      await imageRef.delete();
    } catch (err) {
      this.errorService.logError(err);
    }
  }

  /**
   * Upload account gallery to firestorage
   * @param type photo type
   * @param base64 base64 image
   */
  async uploadAccountGallery(type: PhotoType, base64: string, save?: boolean) {
    if (type && base64) {
      const storageRef = this.afStorage.storage.ref();
      const folder = this.accountInfoService.accountId ? this.accountInfoService.accountId : this.userService.uid;
      // const file = this.accountInfoService.accountId ? this.accountInfoService.accountId : this.functionService.randomId();
      const imageRef = storageRef.child('accounts').child(folder).child('dashboard');
      return await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
        return await snapshot.ref.getDownloadURL().then(async (url: string) => {
          if (url) {
            if (save) { 
              await this.saveDB(type, url);
              await this.successMsg();
              return url;
            }
          } else {
            this.successMsg(true);
          }
          return url;
        });
      }, (err: any) => {
        this.errorService.logError(err);
        this.successMsg(true);
        return '';
      });
    } else {
      this.successMsg(true);
      return '';
    }
    
  }

  async deleteAccountGallery() {
    try {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('dashboard');
      await imageRef.delete();
    } catch (err) {
      this.errorService.logError(err);
    }
  }

  async uploadBackgroundImg(type: PhotoType, base64: string) {
    if (type && base64) {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('backgroundImg');
      return await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
        return await snapshot.ref.getDownloadURL().then(async (url: string) => {
          if (url) {
            await this.saveDB(type, url);
          }
          this.successMsg(url ? false : true);
          return url;
        });
      }, (err: any) => {
        this.errorService.logError(err);
        this.successMsg(true);
      });
    } else {
      this.successMsg(true);
      return '';
    }
  }

  async uploadWebsiteContentImg(type: PhotoType, base64: string, contentId: string): Promise<string>  {
    if (type && base64 && contentId) {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteContent').child(contentId);
      return await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
        return await snapshot.ref.getDownloadURL().then(async (url: string) => {
          if (url) {
            await this.saveDB(type, url);
          }
          this.successMsg(url ? false : true);
          return url;
        });
      }, (err: any) => {
        this.errorService.logError(err);
        this.successMsg(true);
      });
    } else {
      this.successMsg(true);
      return '';
    }
  }

  async deleteWebsiteContentImg(contentId: string) {
    if (contentId) {
      try {
        const storageRef = this.afStorage.storage.ref();
        const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteContent').child(contentId);
        await imageRef.delete();
      } catch (err) {
        this.errorService.logError(err);
      }
    }
  }

  async uploadWebsiteHostImg(type: PhotoType, base64: string): Promise<string> {
    if (type && base64) {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteHost').child(type);
      return await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
        return await snapshot.ref.getDownloadURL().then(async (url: string) => {
          if (url) {
            await this.saveDB(type, url);
          }
          this.successMsg(url ? false : true);
          return url;
        });
      }, (err: any) => {
        this.errorService.logError(err);
        this.successMsg(true);
      });
    } else {
      this.successMsg(true);
      return '';
    }
  }

  async uploadWebsiteGallery(save?: boolean) {
    if (this.websiteGalleryList) {
      // Using for...of loop instead of forEach to ensure proper async handling
      for (const mediaId of Object.keys(this.websiteGalleryList)) {
        if (this.websiteGalleryList?.[mediaId]?.mediaUrl?.url) {
          if (this.websiteGalleryList[mediaId].mediaUrl.type === 'blob') {
            const storageRef = this.afStorage.storage.ref();
            const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteGalleryList').child(mediaId);
            
            try {
              const fileBlob = await this.http.get(this.websiteGalleryList[mediaId].mediaUrl.url, { responseType: 'blob' }).toPromise();
              
              // Uploading file to Firebase Storage
              const snapshot = await imageRef.put(fileBlob);
              
              // Getting download URL and updating mediaUrl in websiteGalleryList
              const url = await snapshot.ref.getDownloadURL();
              this.websiteGalleryList[mediaId].mediaUrl.url = url;
              this.websiteGalleryList[mediaId].mediaUrl.type = 'firebase';
            } catch (error) {
  
            }
          } else if (this.websiteGalleryList[mediaId].mediaUrl.type === 'base64') {
            try {
              const storageRef = this.afStorage.storage.ref();
              const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteGalleryList').child(mediaId);
              const snapshot = await imageRef.putString(this.websiteGalleryList[mediaId].mediaUrl.url, 'data_url');
              const url = await snapshot.ref.getDownloadURL();
  
              this.websiteGalleryList[mediaId].mediaUrl.url = url;
              this.websiteGalleryList[mediaId].mediaUrl.type = 'firebase';

              const thumbRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteGalleryList').child(mediaId + '-thumb');
              const thumbSnapshot = await thumbRef.putString(this.websiteGalleryList[mediaId].mediaUrl.url, 'data_url');
              const thumbnailUrl = await thumbSnapshot.ref.getDownloadURL();
  
              this.websiteGalleryList[mediaId].thumbnailUrl.url = thumbnailUrl;
              this.websiteGalleryList[mediaId].thumbnailUrl.type = 'firebase';
            } catch (err) {

            }
          }
        }
      }
      this.observableWebsiteGallery.next(this.websiteGalleryList);
    }
    
    return this.websiteGalleryList;
  }

  async deleteWebsiteGallery(mediaIdList: string[], save?: boolean) {
    if (mediaIdList?.length) {
      for (const mediaId of mediaIdList) {
        try {
          const storageRef = this.afStorage.storage.ref();
          const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteGalleryList').child(mediaId);
          await imageRef.delete();
        } catch (err: any) {

        } 
      }
    }
    // if (this.websiteGalleryList) {
    //   Object.keys(this.websiteGalleryList).forEach(async (mediaId: string) => {
    //     if (!this.websiteGalleryList?.[mediaId]?.mediaUrl?.url && this.websiteGalleryList[mediaId].mediaUrl.type === 'firebase') {
    //       const storageRef = this.afStorage.storage.ref();
    //       const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteGalleryList').child(mediaId);
    //       await imageRef.delete();
    //     }
    //   });
    // }
    // return this.websiteGalleryList;
  }

  async deleteWebsiteHostImg(type: PhotoType) {
    if (type) {
      try {
        const storageRef = this.afStorage.storage.ref();
        const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('websiteHost').child(type);
        await imageRef.delete();
      } catch (err) {
        this.errorService.logError(err);
      }
    }
  }

  async uploadSupportTicketAttachment(ticketId: string, uid: string, base64List: string[]) {
    const urlList: string[] = [];
    if (ticketId && uid && base64List?.length) {
      const storageRef = this.afStorage.storage.ref();
      for (const base64 of base64List) {
        const imageRef = storageRef.child('support').child(uid).child(ticketId).child(this.functionService.randomId());
        await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url: string) => {
            urlList.push(url);
          });
        }, (err: any) => {
          this.errorService.logError(err);
          this.successMsg(true);
        });
      }
    }
    return urlList;
  }

  async deleteBackgroundImg() {
    try {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('accounts').child(this.accountInfoService.accountId).child('backgroundImg');
      await imageRef.delete();
    } catch (err) {
      this.errorService.logError(err);
    }
  }

  async uploadWebsiteContactQrcode(contactId: string, base64: string) {
    if (contactId && base64) {
      const storageRef = this.afStorage.storage.ref();
      const imageRef = storageRef.child('webiste').child(this.accountInfoService.accountId).child('contact').child(contactId);
      const url = await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
          return await snapshot.ref.getDownloadURL().then(async (url: string) => {
            return url;
          });
      }, (err: any) => {
          this.errorService.logError(err);
      });
      this.websiteContactQrcode[contactId] = {
        type: 'firebase',
        url,
      };
      this.observableWebsiteContactQrcode.next(this.websiteContactQrcode);
      return url;
    }
    return '';
  }

  async deleteWebsiteContactQrcode(contactId: string) {
    if (contactId) {
      try {
        const storageRef = this.afStorage.storage.ref();
        const imageRef = storageRef.child('webiste').child(this.accountInfoService.accountId).child('contact').child(contactId);
        await imageRef.delete();
      } catch (err) {
        this.errorService.logError(err);
      }
      
    }
  }

  // async uploadSupportAttachment(supportId: string, imageList: string[]) {
  //   if (supportId && imageList?.length) {
  //     imageList.forEach(async (img: string, index: number) => {
  //       const storageRef = this.afStorage.storage.ref();
  //       const imageRef = storageRef.child('support').child(supportId).child((index + 1).toString());
  //       await imageRef.putString(img, 'data_url').then(snapshot => {
  //         snapshot.ref.getDownloadURL().then(async (url: string) => {
  //         });
  //       }, (err: any) => {
  //         this.errorService.logError(err);
  //       });
  //     });
  //   }
  // }

  async saveUserAvatar(avatar: MediaType) {
    const data = { avatar };
    await this.userManageService.updateUser(data);
  }

  /**
   * Save photo url into database
   * @param data data
   */
  async saveDB(type: PhotoType, url: string) {
    if (type) {
      if (type === 'account_gallery') {
        const data = { gallery: [] };
        if (url) {
          data.gallery.push(url);
        }
        await this.accountInfoService.updateAccountInfo(data);
      } else if (type === 'background_img') {
        const setting = { ...this.visitorSettingService.getPageSetting() };
        setting.backgroundUrl = url;
        await this.visitorManageService.savePageSetting({ setting });
      }
    }
  }

  /**
   * Toast success message
   * @param success true if success
   */
  async successMsg(fail?: boolean) {
    this.popupService.dismissLoading();
    if (fail) {
      this.popupService.saveFailToast();
    } else {
      this.popupService.saveSuccessToast();
    }
  }

  async blobToDataURI(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURI = reader.result as string;
        resolve(dataURI);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }
}
