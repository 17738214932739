import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { FunctionService } from '../general/function.service';
import { WebsiteContact, WebsiteContactDetail } from 'src/app/interfaces/website';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteContactService implements OnInit, OnDestroy {

  accountId: string;

  websiteContact: WebsiteContact;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteContact>(this.websiteContact);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getWebsiteContact(): WebsiteContact {
    if (this.websiteContact) {
      return this.websiteContact;
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        remark: '',
        enable: true,
        contactsList: {},
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.contact.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.contact.btn');
  }

  getMsg() {
    return this.translate.instant('WEBSITE.contact.msg');
  }

  setup(websiteContact: WebsiteContact) {
    if (!this.functionService.isEqual(this.websiteContact, websiteContact)) {
      this.websiteContact = websiteContact;
      this.observable.next(this.websiteContact);
    }
  }

  async save(contact: WebsiteContact) {
    if (this.accountId && !this.functionService.isEqual(contact, this.websiteContact)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contact }, { merge: true });
    }
  }

  async saveDetail(contactDetail: WebsiteContactDetail) {
    if (this.accountId && contactDetail?.contactId && !this.functionService.isEqual(contactDetail, this.websiteContact?.contactsList[contactDetail.contactId])) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contact: { contactsList: { [contactDetail?.contactId]: contactDetail }} }, { merge: true });
    }
  }
}
