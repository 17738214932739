<div class="ion-page" [style.height]="showTab ? 'calc(100vh - 50px - ' + safeArea + 'px)' : 'calc(100vh - ' + 0 + 'px)'" *ngIf="pageMode">
  <ng-container *ngIf="true then main"></ng-container>
</div>

<ng-container *ngIf="!pageMode then main"></ng-container>
<ng-template #main>
  <ion-header #header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-menu-button *ngIf="step !== 2 && pageMode else menuBtnBlock">
          <ion-icon class="icon-menu" src="./assets/wedding/icon/general/menu.svg"></ion-icon>
          <app-inbox-count></app-inbox-count>
        </ion-menu-button>
        <ng-template #menuBtnBlock>
          <ion-button (click)="goBack()">
            <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
          </ion-button>
        </ng-template>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        <ng-container *ngIf="step === 1">
          {{ 'CRUD.add_field' | translate : { field: 'GUEST.lbl.guest' | translate } }}
        </ng-container>
        <ng-container *ngIf="step === 2">
          <ng-container *ngIf="detailForm.value.group">
            <ng-container *ngIf="group?.memberList?.length else groupTitleBlock">
              {{ 'CRUD.add_field' | translate : { field: 'GROUP.lbl.member' | translate } }}
            </ng-container>
            <ng-template #groupTitleBlock>
              {{ 'CRUD.add_field' | translate : { field: 'GROUP.lbl.guest_group' | translate } }}
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!detailForm.value.group">
            <ng-container *ngIf="detailForm.value.pax > 1 else newGuestBlock">
              {{ 'CRUD.add_field' | translate : { field: 'GUEST.lbl.guests' | translate } }}
            </ng-container>
            <ng-template #newGuestBlock>
              {{ 'CRUD.add_field' | translate : { field: 'GUEST.lbl.guest' | translate } }}
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="step === 3">
          {{ 'LBL.result' | translate }}
        </ng-container>
      </ion-title>
      <ion-buttons slot="end" *ngIf="module === 'guest' && step === 1">
        <ion-button (click)="presentGuestImportModal()">
          <ion-icon src="./assets/wedding/icon/general/excel.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="tertiary" *ngIf="step === 3">
      <div class="content-div">
        <ion-grid fixed>
          <ion-row class="ion-align-items-center" *ngIf="!editMode && !selectMode">
            <ion-col class="ion-align-self-center" *ngIf="module === 'guest'">
              <ion-card class="ion-no-margin ion-padding ion-text-center" (click)="sendQrcode()" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/qrcode/qrcode.svg"></ion-icon>
                <div>{{ 'MODULE.list.qrcode' | translate }}</div>
              </ion-card>
            </ion-col>
            <ion-col class="ion-align-self-center">
              <ion-card class="ion-no-margin ion-padding ion-text-center" (click)="assignSeating()" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>
                <div>{{ seatingTypeName }}</div>
              </ion-card>
            </ion-col>
            <ion-col class="ion-align-self-center">
              <ion-card class="ion-no-margin ion-padding ion-text-center" (click)="initialize(true)" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/general/add-circle-outline.svg"></ion-icon>
                <div>{{ 'CRUD.new' | translate }}</div>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center" *ngIf="editMode">
            <ion-col class="ion-align-self-center">
              <ion-text>{{ 'VALIDATION.select_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}</ion-text>
              <ion-grid class="ion-no-padding ion-margin-top">
                <ion-row class="ion-align-items-center" class="ion-no-padding">
                  <ion-col class="ion-align-self-center">
                    <ion-button expand="block" color="grey" fill="solid" type="button" (click)="goBack()">{{ 'BTN.cancel' | translate }}</ion-button>
                  </ion-col>
                  <ion-col class="ion-align-self-center">
                    <ion-button expand="block" color="primary" fill="solid" type="button" (click)="promptAssign()">{{ 'BTN.next' | translate }}</ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center" *ngIf="selectMode">
            <ion-col class="ion-align-self-center">
              <ion-text>{{ 'VALIDATION.select_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}</ion-text>
              <div class="ion-margin-top">
                <ion-button expand="block" color="grey" fill="solid" type="button" (click)="goBack()">{{ 'BTN.cancel' | translate }}</ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-toolbar>
    <ion-toolbar class="toolbar-select-all" *ngIf="step === 3">
      <div class="content-div">
        <app-select-all [total]="newGuestList.length" [selected]="selectedGuestList.length" [selectAll]="selectAll" [editMode]="editMode" (toggleSelect)="toggleSelectAll()" *ngIf="guestListPreview?.length"></app-select-all>
      </div>
    </ion-toolbar>
  </ion-header>
  
  <ion-content class="full-width" [color]="step === 3 ? 'light' : 'white'" #content>
    <div class="content-div" *ngIf="pageMode">
      <ng-container *ngIf="true then form"></ng-container>
    </div>
    <ng-container *ngIf="!pageMode then form"></ng-container>
  </ion-content>
  
  <ion-footer *ngIf="step === 1 || step === 2" #footer>
    <ion-toolbar>
      <div class="content-div">
        <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" *ngIf="step === 1" (click)="settingFormSubmit()" [disabled]="!settingForm.valid">
          {{ 'BTN.next' | translate }}
        </ion-button>
        <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" *ngIf="step === 2" (click)="detailFormSubmit()" [disabled]="!detailForm.valid || !validGuestName()">
          {{ 'BTN.confirm' | translate }}
        </ion-button>
      </div>
    </ion-toolbar>
  </ion-footer>
</ng-template>

<ng-template #form>
  <form class="ion-padding" [formGroup]="settingForm" *ngIf="step === 1 && settingForm">
    <ion-list class="background-transparent">
      <div *ngIf="invitedByList?.length > 1">
        <ion-label class="custom-label-stacked" position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }} <ion-text color="danger">*</ion-text></ion-label>
        <ion-segment scrollable formControlName="invitedBy" [value]="settingForm.value?.invitedBy" *ngIf="invitedByList">
          <ion-segment-button class="seg-btn-invitedBy" [value]="invitedBy.value" *ngFor="let invitedBy of invitedByList">
            <ion-text class="ion-text-wrap">
              <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
              <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
            </ion-text>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.invitedBy">
            <div class="error-message" *ngIf="settingForm.get('invitedBy').hasError(validation.type) && (settingForm.get('invitedBy').dirty || settingForm.get('invitedBy').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </div>
      <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('category', settingForm?.value?.category)">
        <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }} <ion-text color="danger">*</ion-text></ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin">
            <ng-container  *ngIf="selectedCategory else categoryBlock">{{ selectedCategory }}</ng-container>
            <ng-template #categoryBlock>{{ 'MSG.please_select' | translate }}</ng-template>
          </ion-label>
          <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.category">
            <div class="error-message" *ngIf="settingForm.get('category').hasError(validation.type) && (settingForm.get('category').dirty || settingForm.get('category').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>
      <div *ngIf="module === 'guest' || module === 'rsvp'">
        <ion-label class="custom-label-stacked" position="stacked" color="dark">{{ 'LBL.status' | translate }}</ion-label>
        <ion-segment class="seg-btn-status" formControlName="attendingStatus" scrollable>
          <ion-segment-button value="attending" layout="icon-top">
            <ion-icon class="icon-large" src="./assets/wedding/icon/guest/status/attending.svg"></ion-icon>
            <ion-label class="font-10">{{ 'LIST.attending_status.attending' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="pending" layout="icon-top">
            <ion-icon class="icon-large" src="./assets/wedding/icon/guest/status/pending.svg"></ion-icon>
            <ion-label class="font-10">{{ 'LIST.attending_status.pending' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.attendingStatus">
            <div class="error-message" *ngIf="settingForm.get('attendingStatus').hasError(validation.type) && (settingForm.get('attendingStatus').dirty || settingForm.get('attendingStatus').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </div>
      
      <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('dietary_req', settingForm?.value?.dietaryReq)">
        <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.dietary_req' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ dietaryReq }}</ion-label>
          <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.dietaryReq">
            <div class="error-message" *ngIf="settingForm.get('dietaryReq').hasError(validation.type) && (settingForm.get('dietaryReq').dirty || settingForm.get('dietaryReq').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('session', settingForm?.value?.session)" *ngIf="showSession">
        <ion-label position="stacked" color="dark">{{ 'LBL.session' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ session }}</ion-label>
          <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.session">
            <div class="error-message" *ngIf="settingForm.get('session').hasError(validation.type) && (settingForm.get('session').dirty || settingForm.get('session').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <div class="ion-padding-bottom" *ngIf="module === 'checkin'">
        <ion-card class="ion-padding ion-no-margin" color="light">
          <ion-item class="ion-item-no-padding" lines="none" color="light">
            <ion-toggle formControlName="checkinStatus" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-12 line-150 font-bold ">{{ 'CHECKIN.lbl.status' | translate }}</div>
                <div class="font-10">
                  <ng-container *ngIf="settingForm.value?.checkinStatus">{{ 'LIST.checkin.attended' | translate }}</ng-container>
                  <ng-container *ngIf="!settingForm.value?.checkinStatus">{{ 'LIST.checkin.not_attend' | translate }}</ng-container>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
        
        <ion-card class="ion-padding ion-no-margin ion-margin-vertical" *ngIf="checkinSetting?.giftRecord && !checkinSetting?.giftDetail" color="light">
          <ion-item class="ion-item-no-padding" lines="none" color="light">
            <ion-toggle formControlName="giftStatus" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-12 line-150 font-bold">{{ 'GIFT.lbl.status' | translate }}</div>
                <div class="font-10">
                  <ng-container *ngIf="settingForm.value?.giftStatus">{{ 'GIFT.lbl.received' | translate }}</ng-container>
                  <ng-container *ngIf="!settingForm.value?.giftStatus">{{ 'GIFT.lbl.not_receive' | translate }}</ng-container>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
      </div>
    </ion-list>
  </form>

  <ng-container *ngIf="step === 2">
    <ion-list class="full-width background-transparent" [style.height]="contentHeight" *ngIf="detailForm">
      <div #groupDiv>
        <form class="detail-form ion-padding" [formGroup]="detailForm">
          <ion-item class="ion-item-no-padding" color="white" lines="none">
            <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.pax' | translate }} <ion-text color="danger">*</ion-text></ion-label>
            <ion-item class="ion-item-no-padding full-width" lines="none">
              <ion-input class="prompt-input ion-text-center" formControlName="pax" min="1" type="number" inputmode="numeric" (ionChange)="setupGuestList()" tabindex="1">
                <ion-button class="qty-btn" slot="start" (click)="minusPax()" fill="clear">
                  <ion-icon src="./assets/wedding/icon/general/remove.svg"></ion-icon>
                </ion-button>
                <ion-button class="qty-btn" slot="end" (click)="addPax()" fill="clear">
                  <ion-icon src="./assets/wedding/icon/general/add.svg"></ion-icon>
                </ion-button>
              </ion-input>
            </ion-item>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.pax">
              <div class="error-message" *ngIf="detailForm.get('pax').hasError(validation.type) && (detailForm.get('pax').dirty || detailForm.get('pax').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </form>
  
        <div class="ion-padding" *ngIf="(detailForm.value.pax > 1 || group) && !defaultGroup?.groupName && detailForm.value.pax <= 100">
          <ion-card class="box-shadow group-card ion-no-margin ion-padding">
            <form [formGroup]="detailForm" *ngIf="!defaultGroup">
              <ion-item class="ion-item-no-padding" lines="none">
                <ion-toggle formControlName="group" color="success" (ionChange)="toggleGroup()">
                  <ion-text class="ion-text-wrap">
                    <div class="font-12 line-150 font-bold ">
                      {{ 'GROUP.setting.on.title' | translate }}
                    </div>
                    <div class="font-10">
                      <ng-container *ngIf="detailForm.value.group">{{ 'GROUP.setting.on.msg' | translate }}</ng-container>
                      <ng-container *ngIf="!detailForm.value.group">{{ 'GROUP.setting.off.msg' | translate }}</ng-container>
                    </div>
                  </ion-text>
                </ion-toggle>
              </ion-item>
            </form>
            <ng-container *ngIf="detailForm.value.group && group?.memberList && !defaultGroup?.groupName && detailForm.value.pax >= 1">
              <div class="lbl-div full-width">
                <ion-item class="label-item full-width background-transparent ion-item-no-padding" lines="none">
                  <ion-label class="font-10" color="dark" slot="start">{{ 'GROUP.lbl.name' | translate }} <ion-text color="danger">*</ion-text></ion-label>
                  <ion-note class="font-10 ion-no-margin" slot="end" (click)="readContactsForGroup()" *ngIf="isHybrid">
                    {{ 'DEVICE_CONTACTS.btn.import_contacts' | translate }}
                    <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/person-circle-outline.svg"></ion-icon>
                  </ion-note>
                </ion-item>
              </div>
              <ion-item class="groupname-item ion-item-no-padding ion-no-margin" color="white" lines="none">
                <!-- <ion-label position="stacked">{{ 'GROUP.lbl.name' | translate }} <ion-text color="danger">*</ion-text></ion-label> -->
                <ion-input [(ngModel)]="group.groupName" class="prompt-input" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="3" [clearInput]="isHybrid ? true : false"></ion-input>
              </ion-item>
              <ion-item class="ion-item-no-padding full-width error-item" color="white" lines="none">
                <ion-text class="error-div ion-text-wrap">
                  <div class="error-message" *ngIf="!group?.groupName || !group?.groupName?.toString().trim()">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'GROUP.lbl.name' | translate } }}
                  </div>
                </ion-text>
                <ion-note class="font-10 ion-text-right ion-no-margin" slot="end" (click)="triggerAdvanceGroup()" *ngIf="!group?.mobile?.no && !group.email">
                  {{ 'BTN.advance' | translate }}
                  <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/chevron-forward.svg"></ion-icon>
                </ion-note>
              </ion-item>
              <ion-item class="diff-item full-width" color="tertiary" lines="none" *ngIf="group?.mobile?.no || group?.email" detail>
                <ion-card class="background-transparent ion-no-margin full-width" (click)="triggerAdvanceGroup()">
                  <ion-label class="font-12">
                    <div *ngIf="group.mobile?.no">
                      <ion-text class="font-bold">{{ 'MOBILE.lbl.no' | translate }}: </ion-text>
                      <ion-text>{{ group.mobile.no }}</ion-text>
                    </div>
                    <div *ngIf="group.email">
                      <ion-text class="font-bold">{{ 'LBL.email' | translate }}: </ion-text>
                      <ion-text>{{ group.email }}</ion-text>
                    </div>
                  </ion-label>
                </ion-card>
              </ion-item>
            </ng-container>
          </ion-card>
          <div class="error-div"></div>
        </div>
      </div>

      <ng-container *ngIf="detailForm.value.pax <= 100">
        <div class="full-width" [style.height]="guestListHeight" *ngIf="newGuestList?.length">
          <cdk-virtual-scroll-viewport [itemSize]="90">
            <div class="full-width-height viewport-div ion-padding-horizontal ion-padding-bottom">
              <div class="member-div" *cdkVirtualFor="let newGuest of newGuestList; let i = index;">
                <div class="lbl-div full-width">
                  <ion-item class="label-item full-width background-transparent ion-item-no-padding" lines="none">
                    <ion-label class="font-10" color="dark" slot="start">
                      {{ 'GUEST.lbl.guest' | translate }} {{ i + (group?.memberList?.length ? group.memberList.length : 0) + 1 }}
                      <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-note class="font-10 ion-no-margin" slot="end" (click)="readContactsForGuest(i)" *ngIf="isHybrid">
                      {{ 'DEVICE_CONTACTS.btn.import_contacts' | translate }}
                      <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/person-circle-outline.svg"></ion-icon>
                    </ion-note>
                  </ion-item>
                </div>
                <ion-item class="ion-item-no-padding full-height" color="white" lines="none">
                  <ng-container>
                    <ng-container *ngIf="!group && !detailForm.value.group else elseBlock">
                      <ion-input [id]="'input-' + i" [(ngModel)]="newGuest.name" class="prompt-input name-input" type="text" inputmode="text" autocapitalize="words" autocomplete="on" [tabindex]="i + 4" [clearInput]="isHybrid ? true : false"></ion-input>
                    </ng-container>
                    <ng-template #elseBlock>
                      <ion-input [id]="'input-' + i" [(ngModel)]="newGuest.name" class="prompt-input name-input" [placeholder]="group?.groupName" type="text" inputmode="text" autocapitalize="words" autocomplete="on" [tabindex]="i + 4" [clearInput]="isHybrid ? true : false"></ion-input>
                    </ng-template>
                  </ng-container>
                </ion-item>
                <ion-item class="diff-item full-width" color="tertiary" lines="none" *ngIf="guestDiffList?.[newGuest?.guestId] as diff" detail>
                  <ion-card class="background-transparent ion-no-margin ion-padding-vertical full-width" (click)="triggerAdvanceGuest(i)">
                    <ion-label class="font-12">
                      <div *ngIf="diff.attendingStatus">
                        <ion-text class="font-bold">{{ 'LBL.status' | translate }}: </ion-text>
                        <ion-text>{{ diff.attendingStatus }}</ion-text>
                      </div>
                      <div *ngIf="diff.checkinStatus">
                        <ion-text class="font-bold">{{ 'CHECKIN.lbl.status' | translate }}: </ion-text>
                        <ion-text>{{ diff.checkinStatus }}</ion-text>
                      </div>
                      <div *ngIf="diff.giftStatus">
                        <ion-text class="font-bold">{{ 'GIFT.lbl.status' | translate }}: </ion-text>
                        <ion-text>{{ diff.giftStatus }}</ion-text>
                      </div>
                      <div *ngIf="diff.nickname">
                        <ion-text class="font-bold">{{ 'LBL.nickname' | translate }}: </ion-text>
                        <ion-text>{{ diff.nickname }}</ion-text>
                      </div>
                      <div *ngIf="diff.invitedBy">
                        <ion-text class="font-bold">{{ 'GUEST.lbl.invited_by' | translate }}: </ion-text>
                        <ion-text>{{ getSettingField('invited_by', diff.invitedBy) }}</ion-text>
                      </div>
                      <div *ngIf="diff.category">
                        <ion-text class="font-bold">{{ 'LBL.category' | translate }}: </ion-text>
                        <ion-text>{{ getSettingField('category', diff.category) }}</ion-text>
                      </div>
                      <div *ngIf="diff.mobile?.no">
                        <ion-text class="font-bold">{{ 'MOBILE.lbl.no' | translate }}: </ion-text>
                        <ion-text>{{ diff.mobile.no }}</ion-text>
                      </div>
                      <div *ngIf="diff.email">
                        <ion-text class="font-bold">{{ 'LBL.email' | translate }}: </ion-text>
                        <ion-text>{{ diff.email }}</ion-text>
                      </div>
                      <div *ngIf="diff?.dietaryReq">
                        <ion-text class="font-bold">{{ 'GUEST.lbl.dietary_req' | translate }}: </ion-text>
                        <ion-text>{{ getSettingField('dietary_req', diff.dietaryReq) }}</ion-text>
                      </div>
                      <div *ngIf="diff.specialReq">
                        <ion-text class="font-bold">{{ 'GUEST.lbl.special_req' | translate }}: </ion-text>
                        <ion-text>{{ getSettingField('special_req', diff.specialReq) }}</ion-text>
                      </div>
                      <div *ngIf="diff.session">
                        <ion-text class="font-bold">{{ 'LBL.session' | translate }}: </ion-text>
                        <ion-text>{{ getSettingField('session', diff.session) }}</ion-text>
                      </div>
                      <div *ngIf="newGuest.remark">
                        <ion-text class="font-bold">{{ 'GUEST.lbl.remark' | translate }}: </ion-text>
                        <ion-text>{{ newGuest.remark }}</ion-text>
                      </div>
                    </ion-label>
                  </ion-card>
                </ion-item>
                <div class="lbl-div full-width">
                  <ion-item class="ion-item-no-padding full-width error-item" color="white" lines="none">
                    <ion-text class="error-div ion-text-wrap">
                      <div class="error-message" *ngIf="!group && showNameError && !newGuest.name && (!detailForm.value.group || (detailForm.value.group && i === 0 ))">
                        <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'GUEST.lbl.guest_name' | translate } }}
                      </div>
                    </ion-text>
                    <ion-note class="font-10 ion-text-right ion-no-margin" slot="end" (click)="triggerAdvanceGuest(i)" *ngIf="!guestDiffList?.[newGuest?.guestId]">
                      {{ 'BTN.advance' | translate }}
                      <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/chevron-forward.svg"></ion-icon>
                    </ion-note>
                  </ion-item>
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </ng-container>
    </ion-list>
    
  </ng-container>
  <ng-container *ngIf="step === 3">
    <div class="full-width-height ion-padding-horizontal viewport-div" *ngIf="guestListPreview?.length">
      <cdk-virtual-scroll-viewport [itemSize]="110" [minBufferPx]="200" [maxBufferPx]="200">
        <ion-list class="full-width-height background-transparent" lines="none" #list>
          <app-guest-card *cdkVirtualFor="let guest of guestListPreview; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [editMode]="editMode" [mode]="selectMode ? 'qrcode' : 'attending'" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
</ng-template>