<ion-item class="full-width ion-item-no-padding" color="light" lines="none">
  <ion-item class="full-width ion-margin-vertical" color="light" lines="none">
    <ion-img class="margin-left" src="./assets/wedding/img/news/chrome.svg" slot="start"></ion-img>
    <ion-label class="ion-no-margin ion-text-wrap">
      <div class="line-100"><ion-text class="font-14 font-bold" [innerHtml]="'NEWS_CARD.chrome.title' | translate"></ion-text></div>
      <div class="line-100"><ion-text class="font-10" [innerHtml]="'NEWS_CARD.chrome.msg' | translate"></ion-text></div>
    </ion-label>
    <ion-button class="btn" slot="end" (click)="downloadApp()" *ngIf="!facebookApp">{{ 'APP_DOWNLOAD.popover.install' | translate}}</ion-button>
  </ion-item>
</ion-item>
