import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { UrlService } from 'src/app/services/general/url.service';
import { UserService } from 'src/app/services/user/user.service';

import { Privilege } from 'src/app/interfaces/privilege';
import { RootMenuList, AccountMenuList } from 'src/app/commons/menu';
import { Menu } from 'src/app/interfaces/menu';
import { MenuType } from 'src/app/types/account';

import { ShowMenuUrl } from 'src/app/commons/url';

import { FunctionService } from './function.service';
import { AccountInfoService } from '../account/account-info.service';

/**
 * Menu service.
 * Show menu based on user privilege and state
 */
@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnInit, OnDestroy {

  /**
   * Menu Type
   */
  menuType: MenuType;
  /**
   * Current menu
   */
  currentMenu: Menu[];
  /**
   * Current Privilege
   */
  currentPrivilege: Privilege;
  /**
   * Observable menu
   */
  observableMenu: any;
  /**
   * User subscription
   */
  private userSubscription: Subscription;

  private urlSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param router router
   * @param menuCtrl menu Controller
   * @param moduleService module service
   * @param privilegeService privilege service
   */
  constructor(
    private platform: Platform,
    private menuCtrl: MenuController,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private userService: UserService,
    private accountInfoService: AccountInfoService,
    private urlService: UrlService,
    private functionService: FunctionService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUrl();
    this.unwatchPrivilege();
    this.unwatchUser();
  }

  /**
   * Initialize
   */
  async initialize() {
    this.observableMenu = new BehaviorSubject<Menu[]>(this.currentMenu);
    this.setupMenuCtrl();
    await this.platform.ready();

    this.setupMenu();
    this.watchUrl();
    this.watchUser();
    await this.functionService.delay(200);
    this.watchPrivilege();
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
        this.setupMenuCtrl(url);
        this.setupModule(url);
      });
    }
    
  }

  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Watch user privilege
   */
  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe((currentPrivilege: Privilege) => {
        this.currentPrivilege = currentPrivilege;
        if (this.currentPrivilege) {
          this.setupMenu('main');
        }
      });
    }
    
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  /**
   * Watch user
   */
  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe(() => {
        this.setupMenuCtrl(this.urlService.url);
      });
    }
    
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  /**
   * Setup menu controller based on current url
   * @param url url
   */
  setupMenuCtrl(url?: string) {
    if (url && (this.checkShowMenuUrl(url)) && this.userService?.user) {
      this.menuCtrl.enable(true);
    } else {
      this.menuCtrl.enable(false);
      this.menuCtrl.close();
    }
  }

  /**
   * Check show menu url
   * @param url current url
   * @returns true if show menu for current url
   */
  checkShowMenuUrl(url: string): boolean {
    if (url && this.urlService.checkUrl(ShowMenuUrl, url)) {
      return true;
    }
    return false;
  }

  /**
   * Setup module based on current url path
   * @param url url
   */
  setupModule(url: string) {
    if (url) {
      if (url?.indexOf('/tabs-accounts/') !== -1) {
        this.moduleService.setCurrentModule('accounts');
      } else if (url?.indexOf('/tabs-checkin/') !== -1) {
        this.moduleService.setCurrentModule('checkin');
      } else if (url?.indexOf('/tabs-guest/') !== -1) {
        this.moduleService.setCurrentModule('guest');
      } else if (url?.indexOf('/tabs-gift/') !== -1) {
        this.moduleService.setCurrentModule('gift');
      } else if (url?.indexOf('/tabs-trash/') !== -1) {
        this.moduleService.setCurrentModule('trash');
      } else if (url?.indexOf('/account/') !== -1) {
        this.moduleService.setCurrentModule('account');
      } else if (url?.indexOf('/sms/') !== -1 || url?.indexOf('/blast/') !== -1) {
        this.moduleService.setCurrentModule('blast');
      } else if (url?.indexOf('/website-setting/') !== -1) {
        this.moduleService.setCurrentModule('rsvp');
      }
    }
  }

  /**
   * Setup menu based on user state.
   * @param menuType Menu type
   */
  setupMenu(menuType?: MenuType) {
    if (!menuType) {
      menuType = 'root';
    }

    this.menuType = menuType;

    if (this.menuType === 'main' || this.accountInfoService.accountId) {
      this.currentMenu = [ ...AccountMenuList ];
    } else {
      this.currentMenu = [ ...RootMenuList ];
    }
    this.checkPrivilege();
  }

  /**
   * Check user privilege for menu.
   * Disable & hide menu for menu which user don't have access.
   */
  checkPrivilege() {
    if (this.currentPrivilege && this.menuType === 'main') {
      const moduleList = [];
      Object.keys(this.currentPrivilege)?.forEach((module: string) => {
        if (this.currentPrivilege?.[module]?.length) {
          moduleList.push(module);
        }
      });
      this.currentMenu?.forEach((menu: Menu) => {
        if (menu?.module && menu.module !== 'user' && menu.module !== 'accounts') {
          if (moduleList?.indexOf(menu.module) !== -1) {
            menu.disable = false;
          } else {
            menu.disable = true;
          }

          if (menu.title === 'setting') {
            if (this.currentPrivilege?.account?.indexOf('setting') === -1) {
              menu.disable = true;
            }
          }
        }
      });
    }
    this.observableMenu.next(this.currentMenu);
  }

}
