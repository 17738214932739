import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { LoginService } from 'src/app/services/general/login.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { LinkService } from 'src/app/services/general/link.service';
import { ConfigService } from 'src/app/services/general/config.service';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { DatetimeComponent } from 'src/app/components/general/datetime/datetime.component';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, ActionSheetController, PickerController, Platform, PickerColumnOption } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
// import { set, formatISO } from 'date-fns';

import { UserService } from 'src/app/services/user/user.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';

import { AccountRoleComponent } from 'src/app/components/account/account-role/account-role.component';
import { LocationComponent } from 'src/app/components/general/location/location.component';

import { AccountInfo, AccountUser, AccountCouple, UserRole, CoupleList, Role } from 'src/app/interfaces/account';
import { UpdateBy } from 'src/app/interfaces/user';
import { Timezone, Country, SettingField } from 'src/app/interfaces/database';
import { LocationDetail } from 'src/app/interfaces/general';

import { AccountsSetupStep } from 'src/app/types/accounts';
import { StdInvitedByList } from 'src/app/commons/invitedBy';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldType } from 'src/app/types/general';
import { CategoryService } from 'src/app/services/setting/category.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { PhotoService } from 'src/app/services/general/photo.service';
import { SeatingNewComponent } from '../../seating/seating-new/seating-new.component';
import { Seating } from 'src/app/interfaces/seating';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SearchSelectListComponent } from '../../general/search-select-list/search-select-list.component';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { set } from 'date-fns';
import { UserManageService } from 'src/app/services/user/user-manage.service';

@Component({
  selector: 'app-accounts-setup-component',
  templateUrl: './accounts-setup.component.html',
  styleUrls: ['./accounts-setup.component.scss'],
})
export class AccountsSetupComponent implements OnInit, OnDestroy {

  /**
   * page mode
   */
  @Input() pageMode: boolean;
  /**
   * Reset state
   */
  @Input() set reset(flag: boolean) {
    if (flag) {
      this.checkUserLogin();
    }
  }

  stdInvitedByList: SettingField[] = [];
  stdCategoryList: SettingField[] = [];
  stdDietaryReqList: SettingField[] = [];
  stdSpecialReqList: SettingField[] = [];

  invitedByList: SettingField[] = [];
  categoryList: SettingField[] = [];
  dietaryReqList: SettingField[] = [];
  specialReqList: SettingField[] = [];
  
  eventMode: boolean;
  migrate: boolean;
  /**
   * Accounts setup type
   */
  type: AccountsSetupStep;
  /**
   * Page location
   */
  pageLocation: LocationDetail;
  /**
   * Title form
   */
  coupleForm: FormGroup;
  /**
   * Account form
   */
  accountForm: FormGroup;

  /**
   * Validation message
   */
  validationMsg: any;
  /**
   * Premium
   */
  premium: boolean;
  /**
   * Selected Couple ID
   */
  coupleId: number;
  /**
   * Role
   */
  role: UserRole;
  roleList: Role[];
  /**
   * Couple type
   */
  accountCoupleList: AccountCouple[];
  /**
   * Timezone
   */
  timezone: Timezone;
  /**
   * Country list
   */
  countryList: Country[] = this.localityService.getCountryList();
  /**
   * Timezone list
   */
  timezoneList: Timezone[] = [];
  /**
   * Accounts setup step
   */
  step: AccountsSetupStep;

  accountInfo: AccountInfo;

  seatingType: SettingField;

  seatingTypeOther: string;

  guestPerSeating: number;

  seatingList: Seating[];

  progress: number;

  countryLbl: string;

  seatingTypeName: string;

  invitedByTitle: string;
  categoryTitle: string;
  dietaryReqTitle: string;
  specialReqTitle: string;

  private gift: boolean;
  /**
   * Credit id
   */
  private creditId: string;
  /**
   * Account time
   */
  // private time: any;
  /**
   * Account title
   */
  private accountTitle: SettingField;
  /**
   * Couple list
   */
  private coupleList: CoupleList[];

  /**
   * Constructor
   * @param router Router
   * @param formBuilder Form builder
   * @param fns Angularfire Cloud Function
   * @param modalController Modal Controller
   * @param actionSheetController Action Sheet Controller
   * @param pickerController Picker Controller
   * @param translate Translate
   * @param accountService Account Service
   * @param accountInfoService account info service
   * @param accountCoupleService Couple Service
   * @param accountUserService account user service
   * @param currencyService currency service
   * @param userService User Service
   * @param updateByService update by service
   * @param languageService language service
   * @param localityService locality service
   * @param timezoneService Timezone Service
   * @param popupService Popup Service
   * @param errorService Error Service
   * @param functionService Function service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private pickerController: PickerController,
    private translate: TranslateService,
    private accountService: AccountService,
    private accountEventModeService: AccountEventModeService,
    private accountInfoService: AccountInfoService,
    private accountCoupleService: AccountCoupleService,
    private accountUserService: AccountUserService,
    private accountsListService: AccountsListService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private settingFieldService: SettingFieldService,
    private currencyService: CurrencyService,
    private photoService: PhotoService,
    private authService: AuthService,
    private userService: UserService,
    private userManageService: UserManageService,
    private loginService: LoginService,
    private updateByService: UpdateByService,
    private dateTimeService: DateTimeService,
    private languageService: LanguageService,
    private localityService: LocalityService,
    private timezoneService: TimezoneService,
    private popupService: PopupService,
    private configService: ConfigService,
    private onlineService: OnlineService,
    private linkService: LinkService,
    private functionService: FunctionService,
    private analyticsService: AnalyticsService,
    private errorService: ErrorService,
  ) { }

  /**
   * Ng on init
   */
  ngOnInit() {
  }

  ngOnDestroy() {

  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    await this.popupService.presentLoading();
    await this.initialize();
    this.popupService.dismissLoading();
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();
    this.seatingTypeName = this.getSeatingTypeName();
    if (!this.type && (await this.accountService.readAccountId())) {
      await this.accountService.logoutAccount();
    }

    if (this.accountInfoService?.accountInfo?.eventMode) {
      this.eventMode = true;
    } else if (!this.eventMode) {
      this.eventMode = false;
    }

    if (this.accountCoupleService.accountCoupleList?.length) {
      this.accountCoupleList = [ ...this.accountCoupleService.accountCoupleList ];
    } else {
      this.accountCoupleList = [ ...this.accountCoupleService.getStdCoupleType() ];
    }

    this.invitedByTitle = this.getSettingFieldTitle('invited_by');
    this.categoryTitle = this.getSettingFieldTitle('category');
    this.dietaryReqTitle = this.getSettingFieldTitle('dietary_req');
    this.specialReqTitle = this.getSettingFieldTitle('special_req');

    this.countryLbl = this.getCountryName();

    this.setupForm();
    this.setupAccountUser();
    this.setupAccountLocation();
    this.setupAccountTime();

    if (this.type && this.accountInfoService?.accountInfo?.location?.locality?.country) {
      const countryIndex = this.countryList?.findIndex((country: Country) =>
        country.code === this.accountInfoService.accountInfo.location.locality.country);
      this.accountForm.controls.country.setValue(this.countryList[countryIndex]);
      this.setupCountry(this.countryList[countryIndex]?.code)
    } else {
      if (this.userService.user?.locality?.country) {
        this.setupCountry(this.userService.user.locality.country);
      } else {
        const country = this.localityService.getAccountCountry();
        if (country?.code) {
          this.setupCountry(country.code);
        }
      }
    }

    if (this.type) {
      this.step = this.type;
    } else {
      this.step = 'couple';
    }
  }

  /**
   * Check user login
   */
   async checkUserLogin() {
    if (this.authService.currentUser) {
      if (this.userService.user?.name) {
        this.checkAnonymousLogin();
      } else {
        await this.popupService.dismissLoading();
        const modal = await this.loginService.presentUserSetupModal();
        modal.onDidDismiss().then(() => {
          this.checkAnonymousLogin();
          this.loginService.userSetupModal = null;
        });
      }
    } else {
      this.presentLoginModal(false, false);
    }
  }

  /**
   * Check anonymous login
   */
  async checkAnonymousLogin() {
    if (this.userService?.user?.isAnonymous) {
      this.presentLoginModal(false, false, true);
    } else {
      this.checkIsTrialAvailable();
    }
  }

  /**
   * Present login modal
   * @param loginRequired login required
   * @param showAnonymousLogin show anonymous login
   * @param linkProvider link provider
   */
  async presentLoginModal(loginRequired?: boolean, showAnonymousLogin?: boolean, linkProvider?: boolean) {
    await this.popupService.dismissLoading();
    const modal = await this.loginService.presentLoginModal(loginRequired, showAnonymousLogin, linkProvider);
    modal.onDidDismiss().then(async () => {
      if (this.userService.user && (showAnonymousLogin || !this.userService?.user?.isAnonymous)) {
        this.checkIsTrialAvailable();
      } else {
        this.goMainPage();
      }
    });
  }

  checkIsTrialAvailable() {
    if (!this.accountsListService.isNewTrialAvailable()) {
      this.promptTrialLimit();
    } else {
      this.initialize();
    }
  }

  getTrialLimit() {
    return this.configService?.trial?.trial_new ? this.configService.trial.trial_new : 1;
  }

  async promptTrialLimit() {
    const alert = await this.popupService.presentAlert(
      this.translate.instant('ACCOUNTS.trial.msg.trial_limit', { count: this.getTrialLimit() }));
    alert.onWillDismiss().then(() => {
      this.goMainPage();
    });
  }

  /**
   * Setup account user
   */
  setupAccountUser() {
    if (this.accountService?.accountId) {
      const accountUser: AccountUser = this.accountUserService.getUserByUid(this.userService.uid);
      if (accountUser?.role?.coupleId) {
        this.coupleId = accountUser.role.coupleId;
      } else {
        this.role = accountUser?.role;
      }
    }
  }

  /**
   * Setup account location
   */
  setupAccountLocation() {
    this.pageLocation = this.accountInfoService?.accountInfo?.location;
    if (!this.pageLocation) {
      this.pageLocation = {
        locality: {
          country: '',
          state: '',
          city: '',
          town: ''
        },
        name: '',
        address: '',
        placeId: ''
      };
    }
    if (!this.pageLocation?.locality?.country && this.localityService.getUserCountry()?.code) {
      this.pageLocation.locality.country = this.localityService.getUserCountry().code;
    }
    if (this.accountInfoService?.accountInfo?.timezone) {
      this.timezone = this.accountInfoService.accountInfo.timezone;
    }
  }

  /**
   * Get account time
   */
  setupAccountTime() {
    if (this.accountInfoService?.accountInfo?.time?.seconds) {
      const newDate = set(new Date(this.accountInfoService.accountInfo.time.seconds * 1000), { seconds: 0, milliseconds: 0 });
      const dateISO = this.dateTimeService.formatISO(newDate);
      this.accountForm.controls.date.setValue(dateISO);
      this.accountForm.controls.time.setValue(dateISO);
    }

    if (this.accountInfoService?.accountInfo?.endTime?.seconds) {
      const newDate = set(new Date(this.accountInfoService.accountInfo.endTime.seconds * 1000), { seconds: 0, milliseconds: 0 });
      const dateISO = this.dateTimeService.formatISO(newDate);
      this.accountForm.controls.endTime.setValue(dateISO);
    }
  }

  /**
   * Setup form
   */
  setupForm() {
    this.coupleForm = this.formBuilder.group({
      title: new FormControl('', [ Validators.required ]),
      couple1: new FormControl('', [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      couple2: new FormControl('', [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      organizer: new FormControl('', [ this.eventMode ? Validators.required : Validators.nullValidator ]),
    });

    this.accountForm = this.formBuilder.group({
      country: new FormControl('', [ Validators.required ]),
      date: new FormControl('', [ Validators.required ]),
      time: new FormControl('', [ Validators.required ]),
      endTime: new FormControl(''),
    });

    if (this.accountInfoService?.accountInfo?.title?.value) {
      this.accountTitle = this.accountInfoService?.accountInfo?.title;
      this.coupleForm.controls.title.setValue(this.accountTitle.value);
    }

    if (this.accountInfoService?.accountInfo?.coupleList?.length) {
      this.accountInfoService?.accountInfo?.coupleList.forEach((couple: CoupleList) => {
        if (couple?.name) {
          const coupleId = couple?.accountCouple?.coupleId;
          if (coupleId === 1) {
            this.coupleForm.controls.couple1.setValue(couple.name);
          } else if (coupleId === 2) {
            this.coupleForm.controls.couple2.setValue(couple.name);
          }
        }
      });
    }

    if (this.accountInfoService?.accountInfo?.organizer) {
      this.coupleForm.controls.organizer.setValue(this.accountInfoService.accountInfo.organizer);
    }

    this.validationMsg = {
      couple1: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('LIST.wedding_couple_type.' + this.getCoupleType(1))
      })}],
      couple2: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('LIST.wedding_couple_type.' + this.getCoupleType(2))
      })}],
      title: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('ACCOUNT.lbl.wedding_title') }) }],
      organizer: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('FOR_EVENT.lbl.organizer') }) }],
      date: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_date') }) }],        
      time: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_time') }) }],
      country: [{ type: 'required', msg: this.translate.instant( 'VALIDATION.required', {
        field: this.translate.instant('LOCATION.lbl.wedding_country') }) }]
    };
  }

  /**
   * Setup couple type
   * @param accountCouple account couple
   */
  setupCoupleType(accountCoupleList: AccountCouple[]) {
    this.accountCoupleList = accountCoupleList;
  }

  setupProgress() {
    this.progress = this.getProgress();
  }

  /**
   * Country change event
   * @param event Event
   */
  countryChange(country: Country) {
    if (country?.code) {
      this.setupCountry(country.code);
    }
  }

  /**
   * Setup account title
   */
  setupAccountTitle() {
    if (this.coupleForm?.value?.couple1 && this.coupleForm?.value?.couple2) {
      if (!this.accountTitle) {
        this.accountTitle = { value: '', custom: false };
      }
      if (!this.accountTitle?.custom) {
        this.accountTitle = {
          value: this.translate.instant('ACCOUNT.auto_title', {
            couple1: this.coupleForm.value.couple1, couple2: this.coupleForm.value.couple2
          }),
          custom: false
        };
        this.coupleForm.controls.title.setValue(this.accountTitle.value);
      }
    }
    this.setupCustomAccountTitle();
  }

  /**
   * Setup custom account title
   */
  setupCustomAccountTitle() {
    if (this.coupleForm.value.title) {
      if (!this.eventMode) {
        if (this.coupleForm.value.couple1 && this.coupleForm.value.couple2) {
          const autoTitle = this.translate.instant('ACCOUNT.auto_title', {
            couple1: this.coupleForm.value.couple1, couple2: this.coupleForm.value.couple2 });
          if (this.coupleForm.value.title !== autoTitle) {
            this.accountTitle = {
              value: this.coupleForm.value.title,
              custom: true,
            }
          } else {
            this.accountTitle = {
              value: autoTitle,
              custom: false,
            }
          }
        }
      }  else {
        this.accountTitle = {
          value: this.coupleForm.value.title,
          custom: true,
        }
      }
    }
  }

  /**
   * Setup country and setup timezone list based on country
   * @param countryCode Country Code
   */
  setupCountry(countryCode: string) {
    if (countryCode) {
      const countryIndex = this.countryList?.findIndex((x: Country) => x?.code === countryCode);
      if (countryIndex !== -1) {
        this.accountForm.controls.country.setValue(this.countryList[countryIndex]);
        this.setupTimezoneList(this.countryList[countryIndex].timezones);
      }
    }
  }

  /**
   * Setup timezone list
   * @param timezones Timezone
   */
  setupTimezoneList(timezones: string[]) {
    this.timezoneList = this.timezoneService.getTimezoneList(timezones);
    if (this.timezoneList?.[0]?.name) {
      const index = this.timezoneList?.findIndex((x: Timezone) => this.functionService.isEqual(x, this.timezoneService.getUserTimezone()));
      if (index !== -1) {
        this.timezone = this.timezoneList[index];
      } else {
        this.timezone = this.timezoneList[0];
      }
      // this.setupIsoTime();
    } else {
      this.timezone = null;
    }
  }

  setupEventMode(eventMode: boolean) {
    this.eventMode = eventMode;
  }

  setupInvitedBy() {
    const stdList = this.invitedByService.getStdInvitedByList(this.eventMode);
    if (this.invitedByService.invitedByList?.length) {
      this.invitedByList = [ ...this.invitedByService.getInvitedByList(this.eventMode) ];
    } else if (!this.invitedByList?.length) {
      this.invitedByList = [ ...this.invitedByService.getDefaultInvitedByList(this.eventMode) ];
    }
    this.stdInvitedByList = stdList.concat(this.invitedByList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupCategory() {
    const stdList = this.categoryService.getStdCategoryList(this.eventMode);
    if (this.categoryService.categoryList?.length) {
      this.categoryList = [ ...this.categoryService.getCategoryList(this.eventMode) ];
    } else if (!this.categoryList?.length) {
      this.categoryList = [ ...this.categoryService.getDefaultCategoryList(this.eventMode) ];
    }
    this.stdCategoryList = stdList.concat(this.categoryList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupDietaryReq() {
    const stdList = this.dietaryReqService.getStdDietaryReqList();
    if (this.dietaryReqService.dietaryReqList?.length) {
      this.dietaryReqList = [ ...this.dietaryReqService.getDietaryReqList() ];
    } else if (!this.dietaryReqList?.length) {
      this.dietaryReqList = [ ...this.dietaryReqService.getDefaultDietaryReqList() ];
    }
    this.stdDietaryReqList = stdList.concat(this.dietaryReqList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupSpecialReq() {
    const stdList = this.specialReqService.getStdSpecialReqList(this.eventMode);
    if (this.specialReqService.specialReqList?.length) {
      this.specialReqList = [ ...this.specialReqService.getSpecialReqList(this.eventMode) ];
    } else if (!this.specialReqList?.length) {
      this.specialReqList = [ ...this.specialReqService.getDefaultSpecialReqList(this.eventMode) ];
    }
    this.stdSpecialReqList = stdList.concat(this.specialReqList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  /**
   * Setup timezone
   * @param timezone Timezone
   */
  setupTimezone(timezone: any) {
    const timezoneIndex = this.timezoneList?.findIndex((x: Timezone) => x?.name === timezone);
    if (timezoneIndex !== -1) {
      this.timezone = this.timezoneList[timezoneIndex];
    } else {
      this.timezone = null;
    }
  }

  setupSeatingSetting() {
    if (!this.guestPerSeating) {
      this.guestPerSeating = (this.seatingSettingService.getGuestPerSeating(this.accountForm?.value?.country));
    }
    if (!this.seatingType?.value) {
      this.seatingType = (this.seatingSettingService.getSeatingType());
    }
    if (this.seatingType?.value === 'others' && !this.seatingTypeOther && this.seatingSettingService.seatingTypeOther) {
      this.seatingTypeOther = (this.seatingSettingService.seatingTypeOther);
    }
    this.setupSeatingList();
  }

  setupSeatingList(seatingList?: Seating[]) {
    if (!seatingList?.length) {
      seatingList = [];
    }
    if (this.seatingService.seatingList?.length) {
      this.seatingList = seatingList.concat([ ...this.seatingService.seatingList ]).filter((item, index, array) => {
        return array.findIndex(i => i.name === item.name) === index;
      });
    } else {
      this.seatingList = seatingList;
    }
  }

  format(timestamp: number | Date, dateTimeFormat: string, timeZone?: string, language?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, language, timeZone);
  }

  formatNewDate(date: string) {
    return new Date(date);
  }

  generateTimeStamp() {
    let date = this.accountForm?.value?.date;
    let time = this.accountForm?.value?.time;

    if (date && time && this.timezone.utc) {
      date = this.format(new Date(date), 'yyyy-MM-dd', null, 'en');
      time = this.format(new Date(time), 'HH:mm', null, 'en');

      const dateTime = date + 'T' + time + ':00.000' + this.timezone.utc.replace('UTC', '');
      const timestamp = this.functionService.generateTimestamp(dateTime);

      return timestamp;
    }
    return '';
  }

  generateEndTimeStamp() {
    let date = this.accountForm?.value?.date;
    let time = this.accountForm?.value?.endTime;

    if (date && time && this.timezone.utc) {
      date = this.format(new Date(date), 'yyyy-MM-dd', null, 'en');
      time = this.format(new Date(time), 'HH:mm', null, 'en');

      const dateTime = date + 'T' + time + ':00.000' + this.timezone.utc.replace('UTC', '');
      const timestamp = this.functionService.generateTimestamp(dateTime);

      return timestamp;
    }
    return '';
  }

  /**
   * Prepare for timezone picker value
   */
  async openTimezone() {
    if (!this.timezoneList?.length) {
      if (this.accountForm?.value?.country?.code && this.accountForm?.value?.country?.timezones?.length) {
        this.setupTimezoneList(this.accountForm.value.country.timezones);
      }
    }
    if (this.timezoneList?.length) {
      let selectedIndex = 0;
      const options: PickerColumnOption[] = [];
      this.timezoneList?.forEach((value: Timezone, index: number) => {
        if (value === this.timezone) {
          selectedIndex = index;
        }
        options.push({
          text: value.label,
          value
        });
      });
      this.presentTimezonePicker(options, selectedIndex);
    }
  }

  /**
   * Dismiss modal
   * @param dismiss dismiss flag
   */
   async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ dismiss }); }
    }
  }

  /**
   * Present timezone picker
   * @param options Options
   * @param selectedIndex Selected Index
   */
  async presentTimezonePicker(options: any, selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'timezone',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (result?.timezone?.value?.name) {
            this.setupTimezone(result.timezone.value.name);
          }
        }
      }]
    });
    picker.present();
  }

  /**
   * Present role modal
   * @param selected Selected
   */
  async presentRoleModal(selected: UserRole) {
    const modal = await this.modalController.create({
      component: AccountRoleComponent,
      componentProps: {
        selected,
        newAccount: true,
        eventMode: this.eventMode,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        this.coupleId = 0;
        this.role = result.data.selected;
      }
      this.roleList = result?.data?.roleList ? result.data.roleList : [];
    });
  }

  /**
   * Present location modal for country / timezone / venue selection
   */
  async presentLocationModal() {
    if (this.accountForm?.value?.country) {
      const modal = await this.modalController.create({
        component: LocationComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          country: this.accountForm?.value?.country,
          timezone: this.timezone,
          location: this.pageLocation,
          disableCountry: true,
          disableWatchAccountInfo: true,
          eventMode: this.eventMode,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.location) {
          this.pageLocation = result.data.location;
          // if (this.pageLocation.locality.country !== this.accountForm.value.country.code) {
          //   this.setupCountry(this.pageLocation.locality.country);
          // }
        } else {
          this.pageLocation = null;
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present date time picker modal
   */
  async presentDatetimeModal(presentation: string, type: string, value: string) {
    let time: string = this.dateTimeService.formatIsoByTimestamp(new Date(value).getTime() / 1000);
    // if (presentation === 'date') {
    //   time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.date).getTime() / 1000);
    // } else {
    //   if (type === 'end') {
    //     time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.time).getTime() / 1000);
    //   } else {
    //     time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.endTime).getTime() / 1000);
    //   }
    // }
    const modal = await this.modalController.create({
      component: DatetimeComponent,
      cssClass: 'modal-transparent',
      backdropDismiss: true,
      componentProps: {
        time,
        min: type === 'end' && this.accountForm?.value?.time ? this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.time).getTime() / 1000) : '',
        clearBtn: type === 'end' && this.accountForm?.value?.endTime ? true : false,
        timezone: this.timezone,
        presentation
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.dateTime) {
        if (presentation === 'date') {
          this.accountForm.controls.date.setValue(result.data.dateTime);
        } else if (presentation === 'time') {
          if (type === 'start') {
            this.accountForm.controls.time.setValue(result.data.dateTime);
            if (this.accountForm?.value?.time && this.accountForm?.value?.endTime) {
              if (new Date(this.accountForm?.value?.time).getTime() > new Date(this.accountForm?.value?.endTime).getTime()) {
                this.accountForm.controls.endTime.setValue('');
              }
            }
          } else if (type === 'end') {
            this.accountForm.controls.endTime.setValue(result.data.dateTime);
            if (new Date(this.accountForm?.value?.time).getTime() > new Date(this.accountForm?.value?.endTime).getTime()) {
              this.accountForm.controls.endTime.setValue('');
            }
          }
        }
      }
    });
  }
  

  async presentSeatingNewModal() {
    const modal = await this.modalController.create({
      component: SeatingNewComponent,
      cssClass: '',
      componentProps: {
        newMode: true,
        guestPerSeating: this.guestPerSeating,
        seatingType: this.seatingType,
        seatingTypeOther: this.seatingTypeOther,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.seatingList?.length) {
        this.setupSeatingList(result.data.seatingList);
      }
      if (result?.data?.guestPerSeating && result.data.guestPerSeating !== this.guestPerSeating) {
        this.guestPerSeating = result.data.guestPerSeating;
      }
    });
  }

  async openCountryModal() {
    const selected = this.accountForm?.value?.country;
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.localityService.getCountryList(),
        selected,
        title: this.getCountryName(),
        itemTextField: 'name',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item) {
        this.countryChange(result.data.item);
      }
    });
  }

  selectPhoto(deleteBtn?: boolean) {
    this.photoService.selectPhoto('account_gallery', false, deleteBtn);
  }

  getCountryName() {
    if (this.eventMode) {
      return this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_country'));
    } else {
      return this.translate.instant('LOCATION.lbl.wedding_country');
    }
  }

  getCountryByCode(countryCode: string) {
    return this.localityService.getCountryByCode(countryCode);
  }

  getAccountGalleryList() {
    if (this.accountInfoService.accountInfo?.gallery?.length) {
      return this.accountInfoService.accountInfo.gallery;
    }
    return this.photoService?.accountGalleryList ? this.photoService.accountGalleryList : [];
  }

  /**
   * Get account couple type
   * @param coupleId couple id
   * @returns account couple type
   */
  getCoupleType(coupleId: number): string {
    if (this.accountCoupleList?.length) {
      const index = this.accountCoupleList.findIndex((accountCouple: AccountCouple) => accountCouple?.coupleId === coupleId);
      if (index !== -1) {
        return this.accountCoupleList[index].coupleType;
      }
    }
    return '';
  }

  /**
   * Check if same couple type applied
   * @returns True if same couple type
   */
  checkSameCoupleType(): boolean {
    if (this.getCoupleType(1) === this.getCoupleType(2)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Select couple
   * @param coupleId Couple ID
   */
  selectCouple(coupleId: number) {
    this.coupleId = coupleId;
    this.role = null;
  }

  setOtherRole() {
    this.coupleId = 0;
    if (!this.role || this.role.coupleId) {
      this.role = {
        custom: false,
        type: '',
        coupleId: 0,
      };
    }
  }

  getValue(settingFieldType: SettingFieldType, settingField: SettingField): string {
    if (settingField?.value) {
      if (settingField?.custom) {
        return settingField.value;
      } else if (settingFieldType) {
        return this.translate.instant('LIST.' + settingFieldType + '.' + settingField.value);
      }
    }
    return '';
  }

  checkSelectedSettingField(settingFieldType: SettingFieldType, settingField: SettingField): boolean {
    if (settingFieldType && settingField) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'category') {
        const index = this.categoryList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'dietary_req') {
        const index = this.dietaryReqList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'special_req') {
        const index = this.specialReqList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  getSettingFieldTitle(settingFieldType: SettingFieldType) {
    if (settingFieldType === 'invited_by') {
      return this.translate.instant('GUEST.lbl.invited_by');
    } else if (settingFieldType === 'category') {
      return this.translate.instant('LBL.category');
    } else if (settingFieldType === 'dietary_req') {
      return this.translate.instant('GUEST.lbl.dietary_req');
    } else if (settingFieldType === 'special_req') {
      return this.translate.instant('GUEST.lbl.special_req');
    }
    return '';
  }

  clickSettingField(settingFieldType: SettingFieldType, settingField: SettingField) {
    if (settingFieldType && settingField) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          this.invitedByList.splice(index, 1);
        } else {
          this.invitedByList.push(settingField);
        }
      } else if (settingFieldType === 'category') {
        if (!settingField?.custom && settingField.value === 'others') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.categoryList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.categoryList.splice(index, 1);
          } else {
            this.categoryList.push(settingField);
          }
        }
      } else if (settingFieldType === 'dietary_req') {
        if (!settingField?.custom && settingField.value === 'none') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.dietaryReqList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.dietaryReqList.splice(index, 1);
          } else {
            this.dietaryReqList.push(settingField);
          }
        }
      } else if (settingFieldType === 'special_req') {
        if (!settingField?.custom && settingField.value === 'none') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.specialReqList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.specialReqList.splice(index, 1);
          } else {
            this.specialReqList.push(settingField);
          }
        }
      }
    }
  }

  resetSettingField(settingFieldType: SettingFieldType) {
    if (settingFieldType === 'invited_by') {
      this.invitedByList = [ ...this.invitedByService.getDefaultInvitedByList(this.eventMode) ];
    } else if (settingFieldType === 'category') {
      this.categoryList = [ ...this.categoryService.getDefaultCategoryList(this.eventMode) ];
    } else if (settingFieldType === 'dietary_req') {
      this.dietaryReqList = [ ...this.dietaryReqService.getDefaultDietaryReqList() ];
    } else if (settingFieldType === 'special_req') {
      this.specialReqList = [ ...this.specialReqService.getDefaultSpecialReqList(this.eventMode) ];
    }
  }

  checkDuplicateSettingField(settingFieldType: SettingFieldType, input: string) {
    if (settingFieldType && input) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdInvitedByList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      } else if (settingFieldType === 'category') {
        const index = this.categoryList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdCategoryList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      }  else if (settingFieldType === 'dietary_req') {
        const index = this.dietaryReqList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdDietaryReqList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      } else if (settingFieldType === 'special_req') {
        const index = this.specialReqList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdSpecialReqList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async newSettingField(settingFieldType: SettingFieldType) {
    let field = this.getSettingFieldTitle(settingFieldType);
    if (field) {
      const input = await this.popupService.presentInput(this.translate.instant('CRUD.new_field', { field }));
      input.onWillDismiss().then((result: any) => {
        const value = result?.data?.input;
        if (value) {
          if (this.checkDuplicateSettingField(settingFieldType, value)) {
            this.popupService.presentAlert(this.translate.instant('VALIDATION.duplicate_field', { field }));
          } else {
            const settingField: SettingField = {
              custom: true,
              value,
            };
            if (settingFieldType === 'invited_by') {
              this.stdInvitedByList.push(settingField);
              this.invitedByList.push(settingField);
            } else if (settingFieldType === 'category') {
              this.stdCategoryList.push(settingField);
              this.categoryList.push(settingField);
            } else if (settingFieldType === 'dietary_req') {
              this.stdDietaryReqList.push(settingField);
              this.dietaryReqList.push(settingField);
            } else if (settingFieldType === 'special_req') {
              this.stdSpecialReqList.push(settingField);
              this.specialReqList.push(settingField);
            }
          }
        }
      });
    }
  }

  /**
   * Check if show back button
   * @returns true if can show back button
   */
  showBackBtn(): boolean {
    if (this.type === this.step) {
      return false;
    } else {
      if (this.step === 'couple' && this.premium) {
        if (!this.type) {
          return true;
        }
        return false;
      }
      return true;
    }
  }

  /**
   * Check and prompt for discard account setup if input detected
   */
  async discard() {
    if (this.role || this.coupleId || this.coupleForm?.value?.title || this.accountForm?.value?.time || (this.eventMode && this.coupleForm?.value?.organizer) || this.eventMode) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.goBack();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else if (!this.premium) {
      this.goBack();
    } else {
      this.goBack();
    }
  }

  /**
   * Skip setup prompt
   */
  async skipSetup() {
    const modal = await this.popupService.presentConfirm(this.translate.instant('ACCOUNTS.msg.setup_later'));
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        if (this.creditId) {
          this.goUserCreditPage();
        } else {
          this.goAccountsListPage();
        }
        if (!this.pageMode) {
          this.dismissModal();
        }
      }
    });
  }

  /**
   * Title form submit
   */
  coupleFormSubmit() {
    if (this.eventMode) {
      this.coupleForm.controls.couple1.setValidators([]);
      this.coupleForm.controls.couple2.setValidators([]);
      this.coupleForm.controls.organizer.setValidators([ Validators.required ]);
    } else {
      this.coupleForm.controls.couple1.setValidators([ Validators.required ]);
      this.coupleForm.controls.couple2.setValidators([ Validators.required ]);
      this.coupleForm.controls.organizer.setValidators([ ]);
    }
    this.coupleForm.controls.couple1.updateValueAndValidity();
    this.coupleForm.controls.couple2.updateValueAndValidity();
    this.coupleForm.controls.organizer.updateValueAndValidity();

    this.coupleForm.markAllAsTouched();
    if (this.coupleForm?.valid) {
      this.coupleList = [];
      if (!this.eventMode) {
        this.accountCoupleList?.forEach((accountCouple: AccountCouple) => {
          const coupleName: CoupleList = {
            name: this.coupleForm.value['couple' + accountCouple.coupleId],
            accountCouple
          };
          this.coupleList.push(coupleName);
        });
      }
      this.setupAccountTitle();
      if (this.eventMode) {
        this.coupleId = 0;
      } else if (this.role?.type && !this.role?.coupleId) {
        this.coupleId = 0;
      }
      this.step = 'role';
      this.setupProgress();
    }
  }

  /**
   * Account form submit
   */
  accountFormSubmit() {
    this.accountForm.markAllAsTouched();
    if (this.accountForm.valid && (!this.timezoneList?.length || !this.functionService.isEmpty(this.timezone))) {
      this.setupWeddingAccountCall();
    }
  }

  back() {
    if (this.step === 'couple') {
      if (this.premium && !this.type) {
        this.skipSetup();
      } else {
        this.discard();
      }
    } else if (this.step === 'role') {
      this.step = 'couple';
    } else if (this.step === 'photo') {
      this.step = 'role';
    } else if (this.step === 'account') {
      this.step = 'photo';
    } else if (this.step === 'venue') {
      this.step = 'account';
    } else if (this.step === 'category') {
      this.step = 'venue';
    } else if (this.step === 'dietary') {
      this.step = 'category';
    } else if (this.step === 'seating') {
      this.step = 'dietary';
    } else if (this.step === 'completed') {
      this.step = 'seating';
    }
    this.setupProgress();
  }

  async submit() {
    if (!this.checkDisabledSubmit()) {
      if (this.step === 'couple') {
        this.coupleFormSubmit();
      } else if (this.step === 'role') {
        this.step = 'photo';
      } else if (this.step === 'photo') {
        this.step = 'account';
      } else if (this.step === 'account') {
        this.step = 'venue';
      } else if (this.step === 'venue') {
        this.setupInvitedBy();
        this.setupCategory();
        this.step = 'category';
      } else if (this.step === 'category') {
        this.setupDietaryReq();
        this.setupSpecialReq();
        this.step = 'dietary';
      } else if (this.step === 'dietary') {
        this.setupSeatingSetting();
        this.step = 'seating';
      } else if (this.step === 'seating') {
        this.setupSeating();
      } else if (this.step === 'completed') {
        this.setupWeddingAccountCall();
      }
      this.setupProgress();
    }
  }

  async setupSeating() {
    if (this.guestPerSeating !== this.seatingSettingService.getGuestPerSeating()) {
      if (this.seatingList?.length) {
        this.seatingList = this.seatingList.map((seating: Seating) => {
          if (seating.count > 1) {
            seating.maxGuest = this.guestPerSeating * seating.count;
          } else {
            seating.maxGuest = this.guestPerSeating;
          }
          return seating;
        });
      } else if (this.seatingService.seatingList?.length) {
        this.seatingList =  this.functionService.cloneDeep(this.seatingService.seatingList).map((seating: Seating) => {
          if (seating.count > 1) {
            seating.maxGuest = this.guestPerSeating * seating.count;
          } else {
            seating.maxGuest = this.guestPerSeating;
          }
          return seating;
        });
      }
    }
    this.step = 'completed';
    this.setupProgress();
  }

  // checkShowSkip(): boolean {
  //   if (this.step === 'photo') {
  //     return true;
  //   }
  //   return false;
  // }

  checkDisabledSubmit() {
    if (this.step === 'couple') {
      if (!this.coupleForm?.value?.title) {
        return true;
      }
      if (!this.eventMode) {
        if (!this.coupleForm?.value?.couple1 || !this.coupleForm?.value?.couple2) {
          return true;
        }
      } else {
        if (!this.coupleForm?.value?.organizer) {
          return true;
        }
      }
    } else if (this.step === 'role') {
      if (!this.coupleId && !this.role?.type) {
        return true;
      }
    } else if (this.step === 'photo') {
      
    } else if (this.step === 'account') {
      if (!this.accountForm?.value?.country || !this.timezone?.label || !this.accountForm?.value?.time) {
        return true;
      }
    } else if (this.step === 'venue') {

    } else if (this.step === 'category') {
      if (!this.invitedByList?.length || !this.categoryList?.length) {
        return true;
      }
    } else if (this.step === 'dietary') {
      if (!this.dietaryReqList?.length || !this.specialReqList?.length) {
        return true;
      }
    } else if (this.step === 'seating') {
      if (!this.seatingType?.value || (this.seatingType?.value === 'others' && !this.seatingTypeOther) || !this.guestPerSeating) {
        return true;
      }
    }
    return false;
  }

  /**
   * Generate account info data
   * @returns account info
   */
  generateAccountInfo(): AccountInfo {
    const updateBy: UpdateBy = this.updateByService.getUpdateBy();
    let accountInfo: AccountInfo = this.accountInfoService.accountInfo;

    if (accountInfo) {
      accountInfo.enable = true;
      accountInfo.location = this.pageLocation;
      accountInfo.timezone = this.timezone;
      accountInfo.eventMode = this.eventMode;
      accountInfo.time = this.generateTimeStamp();
      accountInfo.endTime = this.generateEndTimeStamp();
      accountInfo.updateBy = updateBy;

      if (!accountInfo.accountStatus) {
        accountInfo.accountStatus = {};
      }
      if (this.premium) {
        accountInfo.accountStatus = {
          premium: this.premium
        };
      } else {
        accountInfo.accountStatus = {
          trial: !this.premium
        };
      }

      if (this.accountTitle?.value && this.accountTitle.value !== accountInfo?.title?.value) {
        accountInfo.title = this.accountTitle;
      }
      
      if (this.eventMode && this.coupleForm?.value?.organizer) {
        accountInfo.organizer = this.coupleForm.value.organizer;
      }

      if (!accountInfo?.language) {
        accountInfo.language = this.languageService.getLanguageCode(this.languageService.getUserLanguage());
      }

      if (!this.type || !accountInfo?.createBy) {
        accountInfo.createBy = accountInfo.updateBy;
      }

      if (this.coupleList?.length && !this.functionService.isEqual(accountInfo.coupleList, this.coupleList)) {
        accountInfo.coupleList = this.coupleList;
      }

      if (!accountInfo.location) {
        accountInfo.location = {};
      }
      if (!accountInfo?.location?.locality) {
        if (!accountInfo?.location) {
          accountInfo.location = {};
        }
        accountInfo.location.locality = {
          country: ''
        };
      }
      if (!accountInfo?.location?.locality?.country && this.accountForm.value?.country?.code) {
        accountInfo.location.locality.country = this.accountForm.value.country.code;
      }
    } else {
      accountInfo = {
        accountId: '',
        weddingId: '',
        enable: true,
        location: this.pageLocation,
        timezone: this.timezone,
        title: this.accountTitle,
        coupleList: this.coupleList?.length ? this.coupleList : [],
        time: this.generateTimeStamp(),
        endTime: this.generateEndTimeStamp(),
        accountStatus: {},
        eventMode: this.eventMode,
        language: this.languageService.getLanguageCode(this.languageService.getUserLanguage()),
        updateBy,
        createBy: updateBy,
      };
      if (!accountInfo?.location?.locality) {
        if (!accountInfo?.location) {
          accountInfo.location = {};
        }
        accountInfo.location.locality = {
          country: ''
        };
      }
      if (this.accountForm.value?.country?.code) {
        accountInfo.location.locality.country = this.accountForm.value.country.code;
      }
      if (this.premium) {
        accountInfo.accountStatus = {
          premium: this.premium
        };
      } else {
        accountInfo.accountStatus = {
          trial: !this.premium
        };
      }
      if (this.eventMode && this.coupleForm?.value?.organizer) {
        accountInfo.organizer = this.coupleForm.value.organizer;
      }
    }
    return accountInfo;
  }

  /**
   * Generate user role data
   * @returns User role
   */
  generateRole(): UserRole {
    let role: UserRole = {
      coupleId: 0,
      type: '',
      custom: false
    };
    if (!this.eventMode && this.coupleId !== -1 && this.accountCoupleList?.length) {
      const coupleIndex = this.accountCoupleList?.findIndex((x: AccountCouple) => x?.coupleId === this.coupleId);
      if (coupleIndex !== -1) {
        role.coupleId = this.coupleId;
        role.type = this.accountCoupleList[coupleIndex].coupleType;
      }
    }
    if (!role.type && this.role?.type) {
      role = this.role;
    }
    return role;
  }

  setGuestPerSeating(guestPerSeating: number) {
    this.guestPerSeating = guestPerSeating;
  }

  setSeatingType(seatingType: SettingField) {
    this.seatingType = seatingType;
  }

  setSeatingTypeOther(seatingTypeOther: string) {
    this.seatingTypeOther = seatingTypeOther;
  }

  getSeatingTypeName() {
    return this.seatingSettingService.getSeatingTypeName('', this.seatingType, this.seatingTypeOther);
  }

  getProgress(): number {
    if (this.step === 'couple') {
      return 0;
    } else if (this.step === 'role') {
      return 0.125;
    } else if (this.step === 'photo') {
      return 0.25;
    } else if (this.step === 'account') {
      return 0.375;
    } else if (this.step === 'venue') {
      return 0.5;
    } else if (this.step === 'category') {
      return 0.625;
    } else if (this.step === 'dietary') {
      return 0.75;
    } else if (this.step === 'seating') {
      return 0.875;
    } else if (this.step === 'completed') {
      return 1;
    }
    return 0;
  }

  setupAnalytics(accountId: string) {
    if (this.premium) {
      if (this.migrate) {
        this.analyticsService.migrateAccountSetup(accountId);
      } else if (this.gift) {
        this.analyticsService.giftAccountSetup(accountId);
      } else {
        this.analyticsService.premiumAccountSetup(accountId);
      }
    } else {
      this.analyticsService.trialAccountSetup(accountId);
    }
  }

  /**
   * Setup account fns call
   */
  async setupWeddingAccountCall() {
    if (this.onlineService.isOnline()) {
      await this.popupService.presentLoading(this.translate.instant('LOADING.setup'));

      try {
        const currency = this.currencyService.getUserCurrency()?.code;
        const param: any = {
          accountInfo: this.generateAccountInfo(),
          uid: this.userService.uid,
          creditId: this.premium && this.creditId ? this.creditId : '',
          eventMode: this.eventMode,
          currency,
        };

        if (!this.type || !this.functionService.isEqual(this.invitedByList, this.invitedByService.getInvitedByList(this.eventMode))) {
          param.invitedByList = this.settingFieldService.sortList('invited_by', this.invitedByList);
        }
        if (!this.type || !this.functionService.isEqual(this.categoryList, this.categoryService.getCategoryList(this.eventMode))) {
          param.categoryList = this.settingFieldService.sortList('category', this.categoryList);
        }
        if (!this.type || !this.functionService.isEqual(this.dietaryReqList, this.dietaryReqService.getDietaryReqList())) {
          param.dietaryReqList = this.settingFieldService.sortList('dietary_req', this.dietaryReqList);
        }
        if (!this.type || !this.functionService.isEqual(this.specialReqList, this.specialReqService.getSpecialReqList(this.eventMode))) {
          param.specialReqList = this.settingFieldService.sortList('special_req', this.specialReqList);
        }
        if (!this.type || !this.functionService.isEqual(this.seatingList, this.seatingService.seatingList)) {
          param.seatingList = this.seatingList;
        }
        if (!this.functionService.isEqual(this.seatingType, this.seatingSettingService.getSeatingType())) {
          param.seatingType = this.seatingType;
        }
        if (this.seatingType?.value === 'others' && this.seatingTypeOther !== this.seatingSettingService.seatingTypeOther) {
          param.seatingTypeOther = this.seatingTypeOther;
        }
        if (this.guestPerSeating && this.guestPerSeating !== this.seatingSettingService.getGuestPerSeating(this.accountForm.value?.country?.code)) {
          param.guestPerSeating = this.guestPerSeating;
        }
  
        if (this.roleList?.length) {
          param.roleList = this.roleList;
        }
        if (this.type !== 'account') {
          param.role = this.generateRole();
        }
        if (!this.eventMode) {
          param.accountCoupleList = this.accountCoupleList;
        }
        
        const galleryList = this.photoService?.accountGalleryList;
        if (galleryList?.length) {
          const url = await this.photoService.uploadAccountGallery('account_gallery', galleryList[0], false);
          param.accountInfo.gallery = [ url ];
        }

        if (!this.userService?.user?.locality?.country && param?.accountInfo?.location?.locality?.country) {
          this.userManageService.updateUser({ locality: { country: param.accountInfo.location.locality.country }});
        }
        
        await this.fns.httpsCallable('setupWeddingAccountCall')(param).toPromise().then(async (accountId) => {
          if (accountId) {
            this.photoService.accountGalleryList = [];
            this.setupAnalytics(accountId);
            this.analyticsService.setAccountType(this.eventMode);
            await this.popupService.dismissLoading();
            if (!this.pageMode) {
              this.dismissModal(true);
            }
            this.goAccountLoginPage(accountId);
          } else {
            await this.popupService.dismissLoading();
            this.popupService.presentActionError();
          }
        }).catch(async (err: any) => {
          await this.popupService.dismissLoading();
          this.popupService.presentActionError();
          this.errorService.logError(err);
        });
      } catch(err) {
        await this.popupService.dismissLoading();
        this.popupService.presentActionError();
        this.errorService.logError(err);
      }
      
    }
  }

  /**
   * Go back to account add page.
   */
  goBack() {
    if (this.pageMode) {
      this.router.navigate(['/tabs-accounts/list/true']);
    } else {
      this.dismissModal();
    }
  }

  /**
   * Go user credit page
   */
  goUserCreditPage() {
    this.router.navigate(['/user/credit']);
  }

  /**
   * Go account list page
   */
  goAccountsListPage() {
    this.router.navigate(['/tabs-accounts/list']);
  }

  /**
   * Go account login page
   * @param accountId Account ID
   */
  goAccountLoginPage(accountId: string) {
    if (accountId) {
      const navigationExtras: NavigationExtras = {
        state: { accountId, new: true }
      };
      this.router.navigate(['/accounts/login'], navigationExtras);
    }
  }

  goMainPage() {
    if (!this.pageMode) {
      this.dismissModal();
    }
    this.linkService.goMainPage();
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text, this.eventMode);
  }

}
