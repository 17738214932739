import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';
import { WebsiteLink } from 'src/app/interfaces/website';
import { ErrorService } from '../general/error.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OnlineService } from '../general/online.service';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteLinksService implements OnInit, OnDestroy {

  websiteLinks: WebsiteLink[];
  
  observable: any;

  accountId: string;
  private websiteLinkSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private onlineService: OnlineService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.websiteLinks = [];
    this.observable = new BehaviorSubject<WebsiteLink[]>(this.websiteLinks);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
    this.unwatchWebsiteLinks();
    this.websiteLinks = [];
  }

  async setup(accountId: string) {
    this.accountId = accountId;
    
    if (accountId) {
      this.watchWebsiteLinks();
    } else {
      await this.unwatchWebsiteLinks();
      this.websiteLinks = [];
    }
  }

  async watchWebsiteLinks() {
    if (this.accountId) {
      if (!this.websiteLinkSubscription) {
        this.websiteLinkSubscription = this.afs.collection(`accounts/${ this.accountId }/accountSetting/website/links/`)
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data: WebsiteLink = a.payload.doc.data() as WebsiteLink;
          if (!data.linkId) { data.linkId = a.payload.doc.id; }
          return data;
        }))).subscribe({
          next: (websiteLinks: WebsiteLink[]) => {
            this.websiteLinks = websiteLinks;
            this.observable.next(this.websiteLinks);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchWebsiteLinks();
      }, 500);
    }
  }

  /**
   * Unwatch page setting
   */
  async unwatchWebsiteLinks() {
    if (this.websiteLinkSubscription) {
      this.websiteLinkSubscription.unsubscribe();
      this.websiteLinkSubscription = null;
    }
  }

  get linkId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/accountSetting/website/links/`).ref.doc().id : '';
  }

  getWebsiteLink(linkId: string) {
    const index = this.websiteLinks.findIndex((websiteLink: WebsiteLink) => {
      return websiteLink.linkId === linkId;
    });
    return index !== -1 ? this.websiteLinks[index] : null;
  }

  async saveWebsiteLink(websiteLink: WebsiteLink) {
    if (this.accountId && websiteLink) {
      if (!websiteLink?.link?.url || !websiteLink?.linkId) {
        return await this.generateWebsiteLinkCall(websiteLink);
      } else if (!this.functionService.isEqual(websiteLink, this.getWebsiteLink(websiteLink.linkId))) {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website/links/${ websiteLink.linkId}`);
        ref.set(websiteLink, { merge: true });
      }
    }
    return websiteLink;
  }

  async generateWebsiteLinkCall(websiteLink: WebsiteLink) {
    if (this.onlineService.online) {
      if (websiteLink) {
        // await this.popupService.presentLoading();
        return await this.fns.httpsCallable('generateWebsiteLinkCall')({ websiteLink })
        .toPromise().then(async (result: any) => {
          return result;
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.dismissLoading();
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }
}
