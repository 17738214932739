import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Platform } from '@ionic/angular';

import { environment } from 'src/environments/environment';

// import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular-ivy";
// import * as Sentry from "@sentry/angular-ivy";

const refreshMsgList = [
  'refresh the page to try again',
  'ChunkLoadError',
  'Loading chunk',
  'Failed to load resource:',
  'Could not load content for',
  'TypeError: Cannot read properties of undefined (reading \'isProxied\')',
  'TypeError: Cannot read property \'isProxied\' of undefined',
  'undefined is not an object (evaluating \'et.isProxied\')',
  // 'InvalidStateError: Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing.',
  'UnknownError: Database deleted by request of the user',
  'Http failure response for ./assets/wedding/i18n/',
];


// import { Plugins } from '@capacitor/core';
// const { FirebaseCrashlytics } = Plugins;


// Sentry.init({
//   dsn: "https://a034ee75a04a4d76b35c7e79175ad93d@o1074366.ingest.sentry.io/6074090" ,
//   integrations: [
//     // Registers and configures the Tracing integration,
//     // which automatically instruments your application to monitor its
//     // performance, including custom Angular routing instrumentation
//     new BrowserTracing({
//       tracingOrigins: ["localhost", "capacitor", "wedding.thebigdays.com"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


/**
 * Error handler service.
 * Log down the error message into server for further tracking.
 */
@Injectable({
  providedIn: 'root'
})

export class ErrorService implements OnInit, OnDestroy {
  /**
   * UID
   */
  uid: string;
  /**
   * Account ID
   */
  accountId: string;

  appVersion: string;

  /**
   * Constructor
   * @param injector Injector
   * @param platform Platform
   */
  constructor(
    private injector: Injector,
    private platform: Platform
  ) {    
    this.init();
    // Sentry.init({
    //   release: "wedding.thebigdays.com",
    //   dist: "0.10.22",
    //   dsn: "https://a034ee75a04a4d76b35c7e79175ad93d@o1074366.ingest.sentry.io/6074090",
    //   integrations: [
    //     // Registers and configures the Tracing integration,
    //     // which automatically instruments your application to monitor its
    //     // performance, including custom Angular routing instrumentation
    //     new Sentry.BrowserTracing({
    //       routingInstrumentation: Sentry.routingInstrumentation,
    //     }),
    //     // Registers the Replay integration,
    //     // which automatically captures Session Replays
    //     new Sentry.Replay(),
    //   ],
    
    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: 1.0,
    
    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", "capacitor", "wedding.thebigdays.com", "vip.thebigday.my", "thebigday-wedding.web.app", "thebigday-wedding.firebaseapp.com"],
    
    //   // Capture Replay for 10% of all sessions,
    //   // plus for 100% of sessions with an error
    //   replaysSessionSampleRate: 0.1,
    //   replaysOnErrorSampleRate: 1.0,
    // });
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }
  
  init() {
    Sentry.init({
      release: "wedding.thebigdays.com",
      dist: "0.12.16",
      dsn: 'https://a034ee75a04a4d76b35c7e79175ad93d@o1074366.ingest.sentry.io/6074090',
      enableOutOfMemoryTracking: true,
      tracesSampleRate: 1.0,
      enableNative: true,
      // integrations: [
      //   // Registers and configures the Tracing integration,
      //   // which automatically instruments your application to monitor its
      //   // performance, including custom Angular routing instrumentation
      //   new SentryAngular.BrowserTracing({
      //     tracePropagationTargets: ["localhost", "capacitor", "wedding.thebigdays.com", "vip.thebigday.my", "thebigday-wedding.web.app", "thebigday-wedding.firebaseapp.com"],
      //     routingInstrumentation: SentryAngular.routingInstrumentation,
      //   }),
      // ],
      ignoreErrors: ["isProxied", "ChunkLoadError", /Loading chunk [\d]+ failed/],
    }, SentryAngular.init);
  }

  /**
   * Log Error
   * @param error Error
   */
  async logError(error: any) {
    await this.platform.ready();
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path().toString() : '';
    const message: string = this.extractError(error)?.toString() || 'Handled unknown error';

    try {
      if (this.platform.is('hybrid')) {
        // if (this.uid) {
        //   FirebaseCrashlytics.setUserId({ userId: this.uid });
        // }
        // if (this.accountId) {
        //   FirebaseCrashlytics.setContext({
        //     key: 'account_id',
        //     value: this.accountId,
        //     type: 'string'
        //   });
        // }
        // if (location) {
        //   FirebaseCrashlytics.setContext({
        //     key: 'location',
        //     value: location.toString(),
        //     type: 'string'
        //   });
        // }
        // if (url) {
        //   FirebaseCrashlytics.setContext({
        //     key: 'url',
        //     value: url,
        //     type: 'string'
        //   });
        // }
        // FirebaseCrashlytics.addLogMessage({ message });
        // FirebaseCrashlytics.recordException({ message });
      } else {
        // Capture handled exception and send it to Sentry.
        // const accountId = Sentry.captureException(message);

        // Sentry.captureException(message);
        // When in development mode, log the error to console for immediate feedback.
        
        // console.log(message);

        // Optionally show user dialog to provide details on what happened.
        // Sentry.showReportDialog({ accountId });
      }
      if (Sentry) {
        if (this.appVersion) {
          Sentry.setTag('app_version', this.appVersion);
        }
        if (this.accountId) {
          Sentry.setTag('account_id', this.accountId);
        }
        if (this.uid) {
          Sentry.setUser({ id: this.uid });
        }
        if (url) {
          Sentry.setTag('url', url);
        }
        // console.log(error);
        // console.log(message);
        // if (message.toString().indexOf('[object String]') !== -1) {
        //   Sentry.captureException(error);
        // }
        Sentry.setExtras(error);
        Sentry.captureException(message);
      }
      if (!environment.production) {
        console.error(error);
        console.error(message);
      }
    } catch (err) {
      console.error(err);
    }
    this.refreshPage(message);
  }

  /**
   * Extract error to message
   * @param error Error
   */
  extractError(error: any) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body '${error.error}'`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  refreshPage(msg: string, timeout?: number) {
    if (msg && this.checkRefreshMsg(msg.toString().toLowerCase())) {
      setTimeout(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a new timestamp
        const timestamp = new Date().getTime();

        // Check if the 'timestamp' parameter already exists in the URL
        if (currentUrl.includes('?timestamp=')) {
          // If it exists, replace its value with the new timestamp
          const updatedUrl = currentUrl.replace(/(\?|&)timestamp=\d+/, `$1timestamp=${timestamp}`);
          window.location.href = updatedUrl;
        } else {
          // If it doesn't exist, add the 'timestamp' parameter to the URL
          const separator = currentUrl.includes('?') ? '&' : '?';
          const updatedUrl = currentUrl + separator + 'timestamp=' + timestamp;
          window.location.href = updatedUrl;
        }
        window.location.reload();
      }, timeout ? timeout : 1000);
    }
  }

  checkRefreshMsg(msg: string): boolean {
    let flag = false;
    refreshMsgList?.forEach((x: string) => {
      if (!flag && msg && msg.indexOf(x?.toLowerCase()) !== -1) {
        flag = true;
      }
    });
    return flag;
  }

}
