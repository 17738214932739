import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteCheckin } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteCheckinService implements OnInit, OnDestroy {

  accountId: string;

  websiteCheckin: WebsiteCheckin;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteCheckin>(this.websiteCheckin);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteCheckin: WebsiteCheckin) {
    if (!this.functionService.isEqual(this.websiteCheckin, websiteCheckin)) {
      this.websiteCheckin = websiteCheckin;
      this.observable.next(this.websiteCheckin);
    }
  }

  getWebsiteCheckin(): WebsiteCheckin {
    if (this.websiteCheckin) {
      return this.websiteCheckin;
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        remark: '',
        enable: true,
        start: {
          enable: true,
          msg: this.getStartMsg(),
          mins: -120,
        },
        end: {
          enable: true,
          msg: this.getEndMsg(),
          mins: 120,
        },
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.checkin.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.checkin.btn');
  }

  getMsg() {
    return this.translate.instant('WEBSITE.checkin.msg');
  }

  getStartMsg() {
    return this.translate.instant('WEBSITE.checkin.start.msg.msg');
  }

  getEndMsg() {
    return this.translate.instant('WEBSITE.checkin.end.msg.msg');
  }

  async save(checkin: WebsiteCheckin) {
    if (this.accountId && !this.functionService.isEqual(checkin, this.websiteCheckin)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ checkin }, { merge: true });
    }
  }
  
}
