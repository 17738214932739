import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DefaultSeatingType, SeatingTypeList } from 'src/app/commons/seating';
import { SettingField } from 'src/app/interfaces/database';
import { LocalityService } from '../location/locality.service';
import { LanguageService } from '../general/language.service';

@Injectable({
  providedIn: 'root'
})
export class SeatingSettingService implements OnInit, OnDestroy {

  guestPerSeating: number;
  seatingType: SettingField;
  seatingTypeOther: string;

  skip4: boolean;
  replace4: boolean;
  alphabet: boolean;

  observableGuestPerSeating: any;
  observableSeatingType: any;
  observableSeatingTypeOther: any;

  constructor(
    private translate: TranslateService,
    private localityService: LocalityService,
    private languageService: LanguageService,
  ) {
    this.observableGuestPerSeating = new BehaviorSubject<number>(this.guestPerSeating);
    this.observableSeatingType = new BehaviorSubject<SettingField>(this.seatingType);
    this.observableSeatingTypeOther = new BehaviorSubject<string>(this.seatingTypeOther);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupGuestPerSeating(guestPerSeating?: number) {
    if (guestPerSeating) {
      this.guestPerSeating = guestPerSeating;
    } else {
      this.guestPerSeating = this.getDefaultGuestPerSeating();
    }
    this.observableGuestPerSeating.next(this.guestPerSeating);
  }

  setupSeatingType(seatingType?: SettingField) {
    if (seatingType) {
      this.seatingType = seatingType;
    } else {
      this.seatingType = this.getDefaultSeatingType();
    }
    this.observableSeatingType.next(this.seatingType);
  }

  setupSeatingTypeOther(seatingTypeOther?: string) {
    if (!seatingTypeOther) {
      seatingTypeOther = '';
    }
    this.seatingTypeOther = seatingTypeOther;
    this.observableSeatingTypeOther.next(this.seatingTypeOther);
  }

  setupSkip4(flag: boolean) {
    this.skip4 = flag;
  }

  setupReplace4(flag: boolean) {
    this.replace4 = flag;
  }

  setupAlphabet(flag: boolean) {
    this.alphabet = flag;
  }

  getDefaultGuestPerSeating(countryCode?: string): number {
    if (!countryCode) {
      countryCode = this.localityService.getAccountCountry()?.code;
    }
    if (countryCode === 'HK') {
      return 12;
    } else {
      return 10;
    }
  }

  getDefaultSeatingType(): SettingField {
    return DefaultSeatingType;
  }

  getGuestPerSeating(countryCode?: string): number {
    return this.guestPerSeating ? this.guestPerSeating : this.getDefaultGuestPerSeating(countryCode);
  }

  getSeatingType(): SettingField {
    return this.seatingType?.value ? this.seatingType : this.getDefaultSeatingType();
  }

  getSeatingTypeName(langCode?: string, seatingType?: SettingField, seatingTypeOther?: string): string {
    if (!seatingType?.value) {
      seatingType = this.getSeatingType();
    }
    if (seatingType?.value) {
      if (seatingType?.custom) {
        return seatingType.value;
      }
      if (seatingType.value === 'others') {
        if (seatingTypeOther) {
          return seatingTypeOther;
        } else if (this.seatingTypeOther) {
          return this.seatingTypeOther;
        }
      } else {
        if (langCode) {
          const res = this.languageService.getTranslation(langCode);
          if (res?.[langCode]?.LIST?.seating_type?.[seatingType.value]) {
            return res[langCode].LIST.seating_type[seatingType.value];
          }
        }
        return this.translate.instant('LIST.seating_type.' + seatingType.value);
      }
    }
    return this.translate.instant('MODULE.action.guest.seating');
  }

  getSeatingTypeList(): SettingField[] {
    const seatingType = this.getSeatingType();
    const seatingTypeList = SeatingTypeList;
    const index = seatingTypeList.findIndex((x: SettingField) => {
      return x.value === seatingType.value && x.custom === seatingType.custom;
    });
    if (index === -1) {
      seatingTypeList.push(seatingType);
    }
    return seatingTypeList;
  }

  calculateGridLimit(width: number, height: number) {
    const seatingCard = 110;
    const cardPerRow = Math.floor(width / seatingCard);
    const rowLimit = Math.floor(height / seatingCard) + 1;
    const limit = Math.floor(cardPerRow * rowLimit);
    return limit ? limit : this.gridLimit;
  }

  calculateListLimit(height: number) {
    const limit = Math.floor(height / 140);
    return limit ? limit + 1 : this.listLimit;
  }

  /**
   * Get grid limit for infinite load
   * @returns number of grid load limit
   */
  get gridLimit(): number {
    return 15;
  }

  /**
   * Get list limit for infinite load
   * @returns number of list load limit
   */
  get listLimit(): number {
    return 5;
  }

  
}
