<ion-card class="ion-no-margin {{ cardType }}-card" (click)="presentModal()" [color]="whiteBackground ? 'white' : ''" style="--background: linear-gradient(90deg, var(--ion-color-tertiary) 0%, var(--ion-color-tertiary) {{ percent }}%, white {{ percent }}%, white 100%)" [button]="statisticType === 'received' ? false : true">
  <ng-container *ngIf="cardType === 'square'">
    <ion-item [color]="whiteBackground ? 'white' : ''" class="background-transparent font-black" [style.margin]="margin" lines="none">
      <ion-label class="ion-no-margin font-normal">
        <div style="margin-top: 10px;"><ng-container *ngIf="all then iconTemplate"></ng-container></div>
        <div class="font-24"><ng-container *ngIf="true then valueTemplate"></ng-container></div>
      </ion-label>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'rect'">
    <ion-item class="background-transparent font-black" [ngClass]="statisticType === 'received' || statisticType === 'recorded' ? 'ion-item-no-padding' : ''" [color]="color ? color : ''" lines="none">
      <ion-text slot="start" *ngIf="all"><ng-container  *ngIf="all then iconTemplate"></ng-container></ion-text>
      <ion-label class="ion-text-right">
        <div class="font-20"><ng-container *ngIf="true then valueTemplate"></ng-container></div>
      </ion-label>
    </ion-item>
    <ion-item class="full-width" style="--min-height: 36px;" lines="none">
      <ng-container *ngIf="!statisticType">
        <ion-icon class="vertical-middle icon-small" style="margin-right: 5px;" *ngIf="giftType === 'other'" src="./assets/wedding/icon/gift/gift-outline.svg"></ion-icon>
        <ion-icon class="vertical-middle icon-small" style="margin-right: 5px;" *ngIf="giftType === 'cash'" src="./assets/wedding/icon/gift/cash.svg"></ion-icon>
      </ng-container>
      <ion-label class="font-12 line-110 font-med ion-text-wrap ion-no-margin" color="dark">{{ name }}</ion-label>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'block'">
    <ion-item class="background-transparent font-black" [ngClass]="all && (statisticType === 'received' || statisticType === 'recorded') ? 'ion-item-no-padding padding-right-6' : ''" [style.margin]="margin" [color]="whiteBackground ? 'white' : ''" lines="none">
      <ion-text class="ion-no-margin" style="margin-right: 5px;" slot="start" *ngIf="all"><ng-container  *ngIf="all then iconTemplate"></ng-container></ion-text>
      <ion-text class="ion-no-margin line-110 ion-text-wrap" style="margin-right: 10px;" slot="start">
        {{ name }}<span class="font-10" *ngIf="giftType === 'cash' && currency && all">&nbsp;({{ currency }})</span>
      </ion-text>
      <ng-container *ngIf="true then fullValueTemplate"></ng-container>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'dashboard'">
    <ion-item class="dashboard-item ion-item-no-padding background-transparent font-black" [color]="whiteBackground ? 'white' : ''" [style.margin]="margin" lines="none">
      <ion-label class="ion-no-margin ion-text-center">
        <div>
          <ion-icon *ngIf="statisticType === 'recorded'" class="icon-small" color="primary" src="./assets/wedding/icon/gift/gift-outline.svg"></ion-icon>
        </div>
        <div>
          <ion-label class="font-10 line-110 font-med ion-text-wrap ion-no-margin" color="dark">{{ name }}</ion-label>
        </div>
        <div class="font-20"><ng-container *ngIf="true then valueTemplate"></ng-container></div>
      </ion-label>
    </ion-item>
  </ng-container>
</ion-card>

<ng-template #iconTemplate>
  <ion-img class="vertical-middle" *ngIf="statisticType === 'received'" src="./assets/wedding/img/gift/received.svg"></ion-img>
  <ion-img class="vertical-middle" *ngIf="statisticType === 'recorded'" src="./assets/wedding/img/gift/recorded.svg"></ion-img>
  <ng-container *ngIf="cardType === 'rect'">
    <ion-img class="img-icon vertical-middle" *ngIf="!statisticType && giftType === 'other'" src="./assets/wedding/img/gift/other.svg"></ion-img>
    <ion-img class="img-icon vertical-middle" *ngIf="!statisticType && giftType === 'cash'" src="./assets/wedding/img/gift/cash.svg"></ion-img>
  </ng-container>
  <ng-container *ngIf="cardType !== 'rect'">
    <ion-icon class="vertical-middle" *ngIf="!statisticType && giftType === 'other'" src="./assets/wedding/icon/gift/gift.svg"></ion-icon>
    <ion-icon class="vertical-middle" *ngIf="!statisticType && giftType === 'cash'" src="./assets/wedding/icon/gift/cash.svg"></ion-icon>
  </ng-container>
  
</ng-template>

<ng-template #valueTemplate>
  <ion-text class="ion-no-margin">
    <ng-container *ngIf="showNumber">
      <div class="font-bold">
        <ion-text class="font-bold">
          <ng-container *ngIf="giftType === 'cash' && currency then amountTemplate"></ng-container>
          <ng-container *ngIf="giftType !== 'cash' || (giftType === 'cash' && !currency) then countTemplate"></ng-container>
        </ion-text>
      </div>
    </ng-container>
    <ng-container *ngIf="showTotal then totalTemplate"></ng-container>
    <ng-container *ngIf="showPercent then percentBlock"></ng-container>
  </ion-text>
</ng-template>

<ng-template #fullValueTemplate>
  <ion-text slot="end" class="ion-text-right ion-no-margin">
    <ng-container *ngIf="showNumber">
      <div class="font-bold">
        <ion-text class="font-bold">
          <ng-container *ngIf="giftType === 'cash' then amountTemplate"></ng-container>
          <ng-container *ngIf="giftType !== 'cash' then countTemplate"></ng-container>
        </ion-text>
      </div>
    </ng-container>
    <ng-container *ngIf="showTotal then totalTemplate"></ng-container>
    <ng-container *ngIf="showPercent then percentBlock"></ng-container>
    <ng-container *ngIf="giftType === 'cash'">
      <div class="font-12"><ng-container *ngIf="true then countTemplate"></ng-container></div>
    </ng-container>
  </ion-text>
</ng-template>

<ng-template #percentBlock>
  <div><ion-text class="font-bold">{{ percent ? percent : 0 }}%</ion-text></div>
</ng-template>

<ng-template #totalTemplate>
  <div class="font-normal font-10 ion-text-right" *ngIf="all">{{ 'LBL.of_total' | translate: { total: total ? total : 0 } }}</div>
</ng-template>

<ng-template #countTemplate>
  {{ count }} <ion-text class="font-10 font-normal">{{ 'LBL.pcs' | translate | lowercase }}</ion-text>
</ng-template>

<ng-template #amountTemplate>
  <ng-container *ngIf="giftType === 'cash'">
    <ng-container *ngIf="currency">{{ amount | currency: currency: 'symbol' }}</ng-container>
    <ng-container *ngIf="!currency">{{ amount }}</ng-container>
  </ng-container>
</ng-template>

