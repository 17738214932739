import { SessionService } from 'src/app/services/setting/session.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { AccountTaskService } from 'src/app/services/account/account-task.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountRoleService } from 'src/app/services/account/account-role.service';
import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { AccountPackageService } from 'src/app/services/account/account-package.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { VisitorService } from 'src/app/services/visitor/visitor.service';
import { GuestSetupService } from 'src/app/services/guest/guest-setup.service';
import { SeatingSetupService } from 'src/app/services/seating/seating-setup.service';
import { BlastSetupService } from '../blast/blast-setup.service';
import { AccountEventModeService } from './account-event-mode.service';
import { FunctionService } from '../general/function.service';
import { WebsiteDetailService } from '../website/website-detail.service';
import { AccountLoadingService } from './account-loading.service';
import { WebsiteSetupService } from '../website/website-setup.service';
import { FirestoreService } from '../firebase/firestore.service';

/**
 * Account setup service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountSetupService implements OnInit, OnDestroy {

  // private accountId: string;
  // private reloadCount: number;
  /**
   * Constructor
   */
  constructor(
    private accountEventModeService: AccountEventModeService,
    private accountInfoService: AccountInfoService,
    private accountCoupleService: AccountCoupleService,
    private packageService: AccountPackageService,
    private accountRoleService: AccountRoleService,
    private accountUserService: AccountUserService,
    private accountTaskService: AccountTaskService,
    private accountLoadingService: AccountLoadingService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private sessionService: SessionService,
    private visitorService: VisitorService,
    private guestSetupService: GuestSetupService,
    // private guestService: GuestService,
    private seatingSetupService: SeatingSetupService,
    // private smsSetupService: SmsSetupService,
    private blastSetupService: BlastSetupService,

    private websiteSetupService: WebsiteSetupService,
    // private firestoreService :FirestoreService,
  ) {
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Setup setting
   */
  async setupSetting(accountId?: string) {
    this.accountEventModeService.eventMode = false;

    await this.guestSetupService.setupAccountId(accountId);
    // await this.functionService.delay(200);

    await this.accountInfoService.setupAccountId(accountId);
    // await this.functionService.delay(200);
    
    await this.accountCoupleService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.accountRoleService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.accountTaskService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.packageService.setupAccountId(accountId);
    // await this.functionService.delay(200);
    await this.accountUserService.setupAccountId(accountId);
    
    // await this.functionService.delay(200);
    await this.invitedByService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.categoryService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.dietaryReqService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.specialReqService.setupAccountId(accountId);
    // // await this.functionService.delay(200);
    await this.sessionService.setupAccountId(accountId);
    // await this.functionService.delay(200);
    await this.seatingSetupService.setupAccountId(accountId);
    
    // // await this.functionService.delay(200);
    await this.visitorService.setupAccountId(accountId);
    // await this.functionService.delay(200);

    // await this.smsSetupService.setup(accountId);
    // // await this.functionService.delay(200);
    await this.blastSetupService.setup(accountId);
    // // await this.functionService.delay(200);

    // await this.websiteSetupService.setup(accountId);
    
    if (accountId) {
      // await this.functionService.delay(500);
      await this.accountLoadingService.startLoading();
    }
    
    // try {
    //   if (accountId) {
    //     this.firestoreService.watchPendingWrites(`accounts/${ accountId }/guests/`);
    //   } else {
    //     this.firestoreService.unwatch();
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
    
    // // await this.functionService.delay(200);

    // if (accountId && !this.reloadCount && (this.guestService?.toast && !this.guestService.guestList?.length)) {
    //   // await this.functionService.delay(2000);
    //   if (this.guestService?.toast && !this.guestService.guestList?.length) {
    //     await this.guestSetupService.setupAccountId('');
    //     await this.accountInfoService.setupAccountId('');
    //     await this.accountCoupleService.setupAccountId('');
    //     await this.accountRoleService.setupAccountId('');
    //     await this.accountTaskService.setupAccountId('');
    //     await this.packageService.setupAccountId('');
    //     await this.accountUserService.setupAccountId('');
    //     await this.invitedByService.setupAccountId('');
    //     await this.categoryService.setupAccountId('');
    //     await this.dietaryReqService.setupAccountId('');
    //     await this.specialReqService.setupAccountId('');
    //     await this.sessionService.setupAccountId('');
    //     await this.visitorService.setupAccountId('');
    //     await this.seatingSetupService.setupAccountId('');
    //     await this.smsSetupService.setup('');
    //     await this.blastSetupService.setup('');
    //     await this.websiteDetailService.setup('');
    //     this.reloadCount = 1;
    //     // await this.functionService.delay(500);
    //     await this.setupSetting(accountId);
    //   }
    // } 

  }
}
