import { SessionService } from 'src/app/services/setting/session.service';
import { QrcodeService } from 'src/app/services/qrcode/qrcode.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { LinkService } from 'src/app/services/general/link.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import writeXlsxFile from 'write-excel-file';

import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';

import { Guest } from 'src/app/interfaces/guest';
import { SettingField } from 'src/app/interfaces/database';
import { ExportType, SettingFieldType } from 'src/app/types/general';
import { Seating } from 'src/app/interfaces/seating';
import { DynamicUrl } from 'src/app/commons/url';

import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { Group } from 'src/app/interfaces/group';
import { Gift } from 'src/app/interfaces/gift';
import { GiftService } from 'src/app/services/gift/gift.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';

import { DateTimeService } from 'src/app/services/general/date-time.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';

@Component({
  selector: 'app-guest-export',
  templateUrl: './guest-export.component.html',
  styleUrls: ['./guest-export.component.scss'],
})
export class GuestExportComponent implements OnInit, OnDestroy {

  exportType: ExportType;
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Seating list
   */
  seatingList: Seating[];
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Seating list subscription
   */
  private seatingListSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param modalController modal controller
   * @param translate translate service
   * @param guestService guest service
   * @param groupService group service
   * @param seatingService seating service
   * @param settingFieldService setting field service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private groupService: GroupService,
    private giftService: GiftService,
    private seatingService: SeatingService,
    private sessionService: SessionService,
    private accountInfoService: AccountInfoService,
    private accountUserService: AccountUserService,
    private checkinSettingService: CheckinSettingService,
    private qrcodeService: QrcodeService,
    private dateTimeService: DateTimeService,
    private settingFieldService: SettingFieldService,
    private linkService: LinkService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.watch();
    if (!this.exportType) {
      this.exportType = 'guestList';
    }
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchGuestList();
    this.watchSeatingList();
  }

  unwatch() {
    this.unwatchGuestList();
    this.unwatchSeatingList();
  }

  /**
   * Watch gift list changes
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe((guestList: Guest[]) => {
        this.guestList = guestList;
      });
    }
    
  }

  /**
   * Unwatch gift list changes
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch seating changes
   */
  async watchSeatingList() {
    if (!this.seatingListSubscription) {
      this.seatingListSubscription = this.seatingService.observableSeatingList.subscribe((seatingList: Seating[]) => {
        this.seatingList = seatingList;
      });
    }
    
  }

  /**
   * Unwatch seating list
   */
  async unwatchSeatingList() {
    if (this.seatingListSubscription) {
      this.seatingListSubscription.unsubscribe();
      this.seatingListSubscription = null;
    }
  }

  /**
   * Dismiss couple modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

  async promptOpenWeb() {
    const confirm = await this.popupService.presentConfirm(
      this.translate.instant('EXPORT.msg.web')
    );
    confirm.onWillDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        this.linkService.openUrl(DynamicUrl.long.home, true);
      } else {

      }
    });
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[], showCount?: boolean): string {
    return settingFieldType && settingFieldList?.length ?
    this.settingFieldService.joinSettingField(settingFieldType, settingFieldList, false, showCount) : '';
  }

  /**
   * Get group name by group id
   * @param groupId group id
   * @returns group name
   */
  getGroupName(groupId: string): string {
    return groupId ? this.groupService.getGroupName(groupId) : '';
  }

  format(timestamp: number, dateTimeFormat: string, timezone?: string) {
    if (!timezone && this.accountInfoService.accountInfo?.timezone?.name) {
      timezone = this.accountInfoService.accountInfo.timezone.name;
    }
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  async generateCheckinList() {
    await this.popupService.presentLoading();
    const guestList = [ ...this.guestListService.generateGroupList(this.guestList) ];

    await writeXlsxFile([ this.generateCheckinListData(guestList) ], {
      fileName: this.translate.instant('EXPORT.checkin.file', {
        title: this.accountInfoService?.accountTitle ? this.accountInfoService.accountTitle : ''
      }),
      stickyRowsCount: 1,
      stickyColumnsCount: 3,
      columns: [ this.generateCheckinListColumn() ],
      sheets: [ this.translate.instant('EXPORT.checkin.sheet.list') ],
      headerStyle: {
        backgroundColor: '#eeeeee',
        wrap: true,
        fontWeight: 'bold'
      },
      orientation: 'landscape'
    });
    await this.popupService.dismissLoading();
    this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
  }

  generateCheckinListData(guestList: Guest[]): any[] {
    const data: any[] = [];
    data.push(this.generateCheckinListHeader());
    if (guestList?.length) {
      let counter = 0;
      guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId && guest?.group?.memberList?.length) {
          const row = this.generateCheckinListGroupRow(guest.group);
          data.push(row);
          guest.group.memberList.forEach((memberId: string) => {
            const member: Guest = this.guestService.getGuest(memberId);
            if (member) {
              counter++;
              const memberRow = this.generateCheckinListGuestRow(member, counter, true);
              data.push(memberRow);
            }
          });
        } else {
          counter++;
          const row = this.generateCheckinListGuestRow(guest, counter);
          data.push(row);
        }
      });
    }
    return data;
  }

  generateCheckinListGroupRow(group: Group) {
    const row = [
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.groupName,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.memberList?.length ? group.memberList?.length : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.status?.checkin ? this.translate.instant('BTN.yes') : this.translate.instant('BTN.no'),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.status?.checkin && group?.checkinBy?.uid ? this.getUserNameByUid(group.checkinBy.uid) : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.status?.checkin && group?.checkinBy?.time?.seconds ? this.format(group.checkinBy.time.seconds * 1000, 'yyyy-MM-do HH:mm:ss') : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];

    if (this.checkinSettingService.checkinSetting?.giftRecord) {
      row.push({
        value: group?.status?.gift ? this.translate.instant('BTN.yes') : this.translate.instant('BTN.no'),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.status?.gift && group?.giftBy?.uid ? this.getUserNameByUid(group.giftBy.uid) : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.status?.gift && group?.giftBy?.time?.seconds ? this.format(group.giftBy.time.seconds * 1000, 'yyyy-MM-do HH:mm:ss') : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      })
    }
    return row;
  }

  generateCheckinListGuestRow(guest: Guest, counter: number, member?: boolean) {  
    const row = [
      {
        value: counter,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getGroupName(guest?.groupId),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.name,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.nickname,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: member ? '' : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('invited_by', guest?.invitedBy),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('category', guest?.category),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.seating,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('dietary_req', guest?.dietaryReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('special_req', guest?.specialReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.remark,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.status?.checkin ? this.translate.instant('BTN.yes') : this.translate.instant('BTN.no'),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.status?.checkin && guest?.checkinBy?.uid ? this.getUserNameByUid(guest.checkinBy.uid) : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.status?.checkin && guest?.checkinBy?.time?.seconds ? this.format(guest.checkinBy.time.seconds * 1000, 'yyyy-MM-do HH:mm:ss') : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];

    if (this.checkinSettingService.checkinSetting?.giftRecord) {
      row.push({
        value: guest?.status?.gift ? this.translate.instant('BTN.yes') : this.translate.instant('BTN.no'),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.status?.gift && guest?.giftBy?.uid ? this.getUserNameByUid(guest.giftBy.uid) : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.status?.gift && guest?.giftBy?.time?.seconds ? this.format(guest.giftBy.time.seconds * 1000, 'yyyy-MM-do HH:mm:ss') : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      });
    }
    return row;
  }

  /**
   * Generate excel
   */
  async generateGuestList() {
    await this.popupService.presentLoading();
    const guestList = [ ...this.guestListService.generateGroupList(this.guestList) ];
    const giftList = [ ...this.giftService.getGiftList() ];

    await writeXlsxFile([ this.generateGuestListData(guestList), this.generateSeatingViewData(guestList), this.generateGiftListData(giftList) ], {
      fileName: this.translate.instant('EXPORT.guestList.file', {
        title: this.accountInfoService?.accountTitle ? this.accountInfoService.accountTitle : ''
      }),
      stickyRowsCount: 1,
      stickyColumnsCount: 3,
      columns: [ this.generateGuestListColumn(), this.generateSeatingViewColumn(), this.generateGiftColumn() ],
      sheets: [ this.translate.instant('EXPORT.guestList.sheet.list'), this.translate.instant('EXPORT.guestList.sheet.seating'), this.translate.instant('MODULE.list.gift') ],
      headerStyle: {
        backgroundColor: '#eeeeee',
        wrap: true,
        fontWeight: 'bold'
      },
      orientation: 'landscape'
    });
    await this.popupService.dismissLoading();
    this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
  }

  /**
   * Generate guest list column setting
   * @returns guest list column
   */
  generateGuestListColumn(): any[] {
    const columns = [
      { width: 4, wrap: true },
      { width: 18, wrap: true },
      { width: 18, wrap: true },
      { width: 8, wrap: true },
      { width: 7, wrap: true },
      { width: 8, wrap: true },
      { width: 18, wrap: true },
      { width: 7, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 15, wrap: true },
      { width: 7, wrap: true }
    ];
    return columns;
  }

  generateCheckinListColumn(): any[] {
    const columns = [
      { width: 4, wrap: true },
      { width: 18, wrap: true },
      { width: 18, wrap: true },
      { width: 8, wrap: true },
      { width: 7, wrap: true },
      { width: 8, wrap: true },
      { width: 18, wrap: true },
      { width: 7, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 15, wrap: true },
      { width: 7, wrap: true },
      { width: 7, wrap: true },
      { width: 15, wrap: true },
      { width: 15, wrap: true },
    ];
    if (this.checkinSettingService.checkinSetting?.giftRecord) {
      columns.push(
        { width: 7, wrap: true },
        { width: 15, wrap: true },
        { width: 15, wrap: true }
      );
    }
    return columns;
  }

  generateCheckinListHeader(): any[] {
    const header = [
      {
        value: '',
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GROUP.lbl.name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_nickname'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.pax'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.invited_by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.category'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('TEMPLATE.variable.guest_seating'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.dietary_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.special_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('MOBILE.lbl.no'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.remark'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('CHECKIN.lbl.status'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('CHECKIN.lbl.by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('CHECKIN.lbl.time'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];

    if (this.checkinSettingService.checkinSetting?.giftRecord) {
      header.push({
        value: this.translate.instant('CHECKIN.lbl.status'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('CHECKIN.lbl.by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('CHECKIN.lbl.time'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      });
    }
    return header;
  }

  /**
   * Generate guest list header
   * @returns Guest list header
   */
  generateGuestListHeader(): any[] {
    const header = [
      {
        value: '',
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GROUP.lbl.name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_nickname'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.pax'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.invited_by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.category'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('TEMPLATE.variable.guest_seating'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.dietary_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.special_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('MOBILE.lbl.no'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.remark'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return header;
  }

  /**
   * Generate guest list data
   * @param guestList guest list
   * @returns guest list data
   */
  generateGuestListData(guestList: Guest[]): any[] {
    const data: any[] = [];
    data.push(this.generateGuestListHeader());
    if (guestList?.length) {
      let counter = 0;
      guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId && guest?.group?.memberList?.length) {
          const row = this.generateGuestListGroupRow(guest.group);
          data.push(row);
          guest.group.memberList.forEach((memberId: string) => {
            const member: Guest = this.guestService.getGuest(memberId);
            if (member) {
              counter++;
              const memberRow = this.generateGuestListGuestRow(member, counter, true);
              data.push(memberRow);
            }
          });
        } else {
          counter++;
          const row = this.generateGuestListGuestRow(guest, counter);
          data.push(row);
        }
      });
    }
    return data;
  }

  generateGuestListGroupRow(group: Group) {
    const row = [
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.groupName,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.memberList?.length ? group.memberList?.length : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }

  generateGuestListGuestRow(guest: Guest, counter: number, member?: boolean) {
    const row = [
      {
        value: counter,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getGroupName(guest?.groupId),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.name,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.nickname,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: member ? '' : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('invited_by', guest?.invitedBy),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('category', guest?.category),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.seating,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('dietary_req', guest?.dietaryReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('special_req', guest?.specialReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.remark,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }

  /**
   * Generate seating guest header
   * @returns seating guest header
   */
  generateSeatingGuestHeader(): any[] {
    const header = [
      {
        value: '',
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GROUP.lbl.name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_nickname'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.pax'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.invited_by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.category'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.dietary_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.special_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('MOBILE.lbl.no'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.remark'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return header;
  }

  /**
   * Generate seating header
   * @param value seating name
   * @returns Seating header
   */
  generateSeatingHeader(value: string): any[] {
    const seatingHeader = [
      {
        value: value ? this.translate.instant('SEATING.name') + ': ' + value : this.translate.instant('SEATING.lbl.not_yet_assigend_guest'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        span: 9,
      }
    ];
    return seatingHeader;
  }

  /**
   * Generate guest based on seating
   * @param guestList Guest list
   * @returns Generete seating guest data
   */
  generateSeatingGuestData(guestList: Guest[]): any[] {
    const data: any[] = [];
    if (guestList?.length) {
      let counter = 0;

      guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId && guest?.group?.memberList?.length) {
          const row = this.generateSeatingGroupRow(guest.group);
          data.push(row);
          guest.group.memberList.forEach((memberId: string) => {
            const member: Guest = this.guestService.getGuest(memberId);
            if (member) {
              counter++;
              const memberRow = this.generateSeatingGuestRow(member, counter, true);
              data.push(memberRow);
            }
          });
        } else {
          counter++;
          const row = this.generateSeatingGuestRow(guest, counter);
          data.push(row);
        }
      });
    }
    return data;
  }

  generateSeatingGroupRow(group: Group) {
    const row = [
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.groupName,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.memberList?.length ? group.memberList?.length : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];

    return row;
  }

  generateSeatingGuestRow(guest: Guest, counter: number, member?: boolean) {
    const row = [
      {
        value: counter,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getGroupName(guest?.groupId),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.name,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.nickname,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: member ? '' : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('invited_by', guest?.invitedBy),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('category', guest?.category),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('dietary_req', guest?.dietaryReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('special_req', guest?.specialReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.remark,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }

  /**
   * Generate seating view data
   * @param guestList Guest list
   * @returns Seating view data
   */
  generateSeatingViewData(guestList: Guest[]): any[] {
    let data: any[] = [];
    const seatingList: Seating[] = [ ...this.seatingList ].filter((seating: Seating) => {
      return seating?.name ? true : false;
    });
    if (seatingList?.length) {
      seatingList?.forEach((seating: Seating, index: number) => {
        if (seating?.seatingId && seating?.name) {      
          const filterGuest = this.functionService.cloneDeep(guestList).filter((guest: Guest) => {
            if (guest?.group?.groupId && guest?.group?.memberList?.length) {
              guest.group.memberList = guest.group.memberList.filter((memberId: string) => {
                const member: Guest = this.guestService.getGuest(memberId);
                return member.seating === seating.name;
              });
              return guest.group.memberList.length ? true : false;
            } else {
              return guest.seating === seating.name;
            }
          });

          data.push(this.generateSeatingHeader(seating.name));
          data.push(this.generateSeatingGuestHeader());
          if (filterGuest?.length) {
            data = data.concat(this.generateSeatingGuestData(filterGuest));
          }
          if (seatingList.length > index + 1) {
            data.push([{ value: '', span: 9, borderStyle: 'none' }]);
          }
        }
      });
    }

    const unassignGuestList = guestList.filter((guest: Guest) => {
      return guest.seating === '';
    });
    if (unassignGuestList?.length) {
      data.push([{ value: '', span: 9, borderStyle: 'none' }]);
      data.push(this.generateSeatingHeader(''));
      data.push(this.generateSeatingGuestHeader());
      data = data.concat(this.generateSeatingGuestData(unassignGuestList));
    }
    return data;
  }

  /**
   * Generate seating view column
   * @returns seating view column
   */
  generateSeatingViewColumn() {
    const columns = [
      { width: 4, wrap: true },
      { width: 18, wrap: true },
      { width: 18, wrap: true },
      { width: 8, wrap: true },
      { width: 7, wrap: true },
      { width: 8, wrap: true },
      { width: 10, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 15, wrap: true },
      { width: 8, wrap: true }
    ];
    return columns;
  }

  generateGiftColumn() {
    const columns = [
      { width: 4, wrap: true },
      { width: 9, wrap: true },
      { width: 20, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 7, wrap: true },
      { width: 20, wrap: true },
      { width: 20, wrap: true },
      { width: 16, wrap: true },
      { width: 10, wrap: true },
      { width: 10, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 8, wrap: true },
      { width: 15, wrap: true },
      { width: 8, wrap: true }
    ];
    return columns;
  }

  generateGiftListData(giftList: Gift[]): any[] {
    const data: any[] = [];
    data.push(this.generateGiftListHeader());
    if (giftList?.length) {
      let counter = 0;
      giftList?.forEach((gift: Gift) => {
        if (gift?.giftId) {
          counter++;
          data.push(this.generateGiftListGiftRow(gift, counter));
          if (gift?.guestIdList?.length) {
            const guestList = this.guestListService.generateGroupList(this.guestService.getGuestListById(gift.guestIdList));
            guestList?.forEach((guest: Guest) => {
              if (guest?.group?.groupId && guest?.group?.memberList?.length) {
                // const row = this.generateGiftListGroupRow(guest.group);
                // data.push(row);
                guest.group.memberList.forEach((memberId: string) => {
                  const member: Guest = this.guestService.getGuest(memberId);
                  if (member) {
                    const memberRow = this.generateGiftListGuestRow(member, counter, true);
                    data.push(memberRow);
                  }
                });
              } else {
                const row = this.generateGiftListGuestRow(guest, counter);
                data.push(row);
              }
            });
          }
          if (gift?.groupIdList?.length) {
            gift.groupIdList.forEach((groupId: string) => {
              const group = this.groupService.getGroup(groupId);
              // console.log(group);
              // const row = this.generateGiftListGroupRow(group);
              // data.push(row);
              if (group?.memberList?.length) {
                const memberList = this.guestService.getGuestListById(group?.memberList);
                memberList.forEach((guest: Guest) => {
                  const memberRow = this.generateGiftListGuestRow(guest, counter, true);
                  data.push(memberRow);
                });
              }
            });
          }
        }
        // if (guest?.group?.groupId && guest?.group?.memberList?.length) {
        //   const row = this.generateGuestListGroupRow(guest.group);
        //   data.push(row);
        //   guest.group.memberList.forEach((memberId: string) => {
        //     const member: Guest = this.guestService.getGuest(memberId);
        //     if (member) {
        //       counter++;
        //       const memberRow = this.generateGuestListGuestRow(member, counter, true);
        //       data.push(memberRow);
        //     }
        //   });
        // } else {
        //   counter++;
        //   const row = this.generateGuestListGuestRow(guest, counter);
        //   data.push(row);
        // }
      });
    }
    return data;
  }

  generateGiftListHeader(): any[] {
    const header = [
      {
        value: '',
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GIFT.lbl.type'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GIFT.lbl.name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.currency'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.amount'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GIFT.lbl.qty'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GROUP.lbl.name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_name'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.guest_nickname'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.invited_by'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LBL.category'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('TEMPLATE.variable.guest_seating'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.dietary_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.special_req'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('MOBILE.lbl.no'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('GUEST.lbl.remark'),
        fontWeight: 'bold',
        wrap: true,
        align: 'center',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return header;
  }

  generateGiftListGiftRow(gift: Gift, counter: number) {
    const row = [
      {
        value: counter,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.translate.instant('LIST.gift.' + gift.giftType),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: gift.name,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: gift?.giftType === 'cash' ? gift.currency : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: gift?.giftType === 'cash' ? gift.amount : '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: gift?.qty ? gift.qty : 1,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }

  generateGiftListGuestRow(guest: Guest, counter: number, member?: boolean) {
    const row = [
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getGroupName(guest?.groupId),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.name,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest.nickname,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('invited_by', guest?.invitedBy),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('category', guest?.category),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.seating,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('dietary_req', guest?.dietaryReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: this.getSettingField('special_req', guest?.specialReq),
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.mobile?.no,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: guest?.remark,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }

  generateGiftListGroupRow(group: Group) {
    const row = [
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: group?.groupName,
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      },
      {
        value: '',
        wrap: true,
        align: 'left',
        alignVertical: 'center',
        borderStyle: 'thin',
      }
    ];
    return row;
  }





  async generateQrcodeZip() {
    await this.popupService.presentLoading();
    const zip = new JSZip();
    const guestList = [ ...this.guestListService.generateGroupList(this.guestList) ];
    const groupNameList: string[] = [];
    const sessionEnable = this.checkSessionEnable();
    for (const guest of guestList) {
      if (guest?.group?.groupId) {
        const sessionFolderList: string[] = [];
        const group = guest.group;
        let groupName = group.groupName;
        if (groupNameList.indexOf(groupName) === -1){
          groupNameList.push(groupName);
        } else {
          groupNameList.push(groupName);
          const count = groupNameList.filter((x: string) => {
            return x === groupName;
          }).length;
          groupName = groupName + ' - ' + count;
        }
        let invitedByFolder = '';
        let categoryFolder = '';
        let duplicateNameCount = 0;

        const memberList = this.guestService.getGuestListById(group?.memberList);
        for (const member of memberList) {
          invitedByFolder = this.getInvitedByFolder(member?.invitedBy?.[0]);
          categoryFolder = this.getCategoryFolder(member?.category?.[0]);

          let name = member.name;
          const index = memberList.findIndex((x: Guest) => {
            return x?.guestId !== member?.guestId && x.name === member.name;
          });
          if (name === group.groupName || index !== -1) {
            duplicateNameCount++;
            name = name + ' - ' + duplicateNameCount;
          }
          const blob = await this.createQrCodeStyling(this.guestService.getGuestUrl(member?.guestId)) as Blob;
          if (sessionEnable && member?.session?.length) {
            const sessionFolder = this.getSessionFolder(member.session[0]);
            if (sessionFolderList.indexOf(sessionFolder) === -1) {
              sessionFolderList.push(sessionFolder);
            }
            zip.folder(sessionFolder).folder(invitedByFolder).folder(categoryFolder).folder(groupName)
            .folder(this.translate.instant('QRCODE.lbl.guest_qrcode')).file(name + '.png', blob, { base64: false });
          } else {
            zip.folder(invitedByFolder).folder(categoryFolder).folder(groupName)
            .folder(this.translate.instant('QRCODE.lbl.guest_qrcode')).file(name + '.png', blob, { base64: false });
          }
        }
        if (group?.groupId && group?.groupName && invitedByFolder && categoryFolder) {
          const blob = await this.createQrCodeStyling(this.guestService.getGuestUrl(guest?.guestId)) as Blob;
          if (sessionFolderList?.length) {
            for (const sessionFolder of sessionFolderList) {
              zip.folder(sessionFolder).folder(invitedByFolder).folder(categoryFolder).folder(groupName)
            .folder(this.translate.instant('QRCODE.lbl.group_qrcode')).file(group.groupName + '.png', blob, { base64: false });
            }
          } else {
            zip.folder(invitedByFolder).folder(categoryFolder).folder(groupName)
            .folder(this.translate.instant('QRCODE.lbl.group_qrcode')).file(group.groupName + '.png', blob, { base64: false });
          }
        }
      } else {
        const invitedByFolder = this.getInvitedByFolder(guest?.invitedBy?.[0]);
        const categoryFolder = this.getCategoryFolder(guest?.category?.[0]);
        const blob = await this.createQrCodeStyling(this.guestService.getGuestUrl(guest?.guestId)) as Blob;
        if (sessionEnable && guest?.session?.length) {
          const sessionFolder = this.getSessionFolder(guest.session[0]);
          zip.folder(sessionFolder).folder(invitedByFolder).folder(categoryFolder).file(guest.name + '.png', blob, { base64: false });
        } else {
          zip.folder(invitedByFolder).folder(categoryFolder).file(guest.name + '.png', blob, { base64: false });
        }
      }
    }


    const content = await zip.generateAsync({type: 'blob'});
    // see FileSaver.js
    const fileName = this.translate.instant('MODULE.list.qrcode') + ' - ' + this.accountInfoService.accountTitle;
    FileSaver.saveAs(content, fileName + '.zip');

    await this.popupService.dismissLoading();
    this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
  }

  async createQrCodeStyling(data: string) {
    return await this.qrcodeService.generateQRcode(data, 512, 512, 25, 'png', false, true);
  }

  getInvitedByFolder(invitedBy: SettingField) {
    const invitedByFolder = invitedBy?.custom ? invitedBy.value : this.translate.instant('LIST.invited_by.' + invitedBy.value);
    return invitedByFolder;
  }

  getCategoryFolder(category: SettingField) {
    const categoryFolder = category?.custom ? category.value : this.translate.instant('LIST.category.' + category.value);
    return categoryFolder;
  }

  getSessionFolder(session: SettingField) {
    const sessionFolder = session?.custom ? session.value : this.translate.instant('LIST.session.' + session.value);
    return sessionFolder;
  }

  getUserNameByUid(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  checkSessionEnable() {
    const sessionList = this.sessionService.list.filter((x: SettingField) => {
      return x?.value && x.value !== 'none' && !x?.custom;
    });
    return sessionList?.length ? true : false;
  }

  download() {
    if (this.exportType === 'qrcode') {
      this.generateQrcodeZip();
    } else if (this.exportType === 'guestList') {
      this.generateGuestList();
    } else if (this.exportType === 'checkin') {
      this.generateCheckinList();
    }
    // if (!this.platform.is('hybrid')) {
    //   if (this.exportType === 'qrcode') {
    //     this.generateQrcodeZip();
    //   } else if (this.exportType === 'guestList') {
    //     this.generateGuestList();
    //   } else if (this.exportType === 'checkin') {
    //     this.generateCheckinList();
    //   }
    // } else {
    //   this.promptOpenWeb();
    // }
  }

}
